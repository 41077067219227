import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BuyerService } from 'src/app/services/buyer/buyer.service';
import { RiskAssessmentInfo } from '../../models/riskAssessment';
import VMController from '../../models/vm';
import { ButtonPropsType, DynamicFormRaComponent, CommonService, cloneDeep, trim, ComponentConfig, DynamicRaContext, DictionaryRaService, ButtonCommands, DialogService, DialogType, HttpService } from '@lsl16/sustainability-shared-components';
import { environment } from 'src/environments/environment';
import { RiskassessmentService } from 'src/app/services/riskassessment/riskassessment.service';
import { InherentQuestionnaireService } from 'src/app/services/inherentRiskQuestionnaire/inherentQuestionnaire.service'
import { AuthService } from 'src/app/services/auth/auth.service';
import { PopUpBibdaScoreComponent } from '../pop-up-bibda-score/pop-up-bibda-score.component';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { PopUpIrqBibdaScoreComponent } from '../pop-up-irq-bibda-score/pop-up-irq-bibda-score.component';
@Component({
  selector: 'tsm-risk-assessment-questionnaire',
  templateUrl: './risk-assessment-questionnaire.component.html',
  styleUrls: ['./risk-assessment-questionnaire.component.sass']
})
export class RiskAssessmentQuestionnaireComponent {

  showAlertPanelO = false;
  showAlertPanel1 = false;
  isPopUp: boolean = false;
  isDraft = true;
  env = environment.env;
  activeDes = "";
  getConfigsInfo: ComponentConfig[] = [];
  raFormMapping = [];
  context: DynamicRaContext;
  metaDataValues = {};
  irqMetadata ={};
  shortDesc = "";
  questionsLoaded = false;
  mode = "";
  irqFlagService: boolean;
  title: string = 'Risk Assessment';
  subtitle: string = "";
  questionnaireType = '';
  popupTooltip: string ="";
  deleteQuestionsList = [];
  @ViewChild("dynamicForm") dynamicForm: DynamicFormRaComponent;


  get riskAssessmentInfo(): RiskAssessmentInfo {
    if (this.irqFlagService) {
      return this.irqService.raModel.RequestForm.RiskAssessmentInfo;
    }else{
      return this.raService.raModel.RequestForm.RiskAssessmentInfo;
    }
  }

  get supplierCode(): string {
    if (this.irqFlagService) {
      return this.irqService.raCode;
    }else{
      return this.raService.raCode;
    }
   
  }
  togglePopup(show: boolean): void {
    this.isPopUp = show;
  }

  handleKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.isPopUp = !this.isPopUp;
    }
  }

  vm: VMController<RiskAssessmentInfo> = new VMController();
  isResubmitDisabled = true;
  isInformationSecurityShow = false;
  cid = "";
  scid = "";
  ic = "";

  sharedButtonProps: ButtonPropsType = {
    label: "",
    fontSize: 16,
    height: 48,
    padding: "10px",
    margin: "0 0 10px 0"
  };
  saveDraftRaProps: ButtonPropsType;
  submitRaProps: ButtonPropsType;
  reviewbibda: ButtonPropsType;
  raId = 0;
  constructor(
    private router: Router,
    private commonService: CommonService,
    private dialogService: DialogService,
    private raService: RiskassessmentService,
    private irqService: InherentQuestionnaireService,
    private route: ActivatedRoute,
    private HttpService: HttpService,
    private authService: AuthService,
    public ngbModalService: NgbModal,
    config: NgbModalConfig,
  ) {
    config.backdrop = "static";
  }

  CancelRaProps: ButtonPropsType = {
    label: "Cancel",
    backgroundColor: "white",
    color: "purple",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    borderRadius: 25,
    borderColor: 'gray',
    padding: "14px 5px",
    margin: "0 0 0 2px",
    onClick: async () => {
      await this.cancel();
    }
  };

  async ngOnInit() {
    window.scrollTo(0, 0);
    this.buttonType();
    this.cid = this.route.snapshot.params.cid;
    this.scid = this.route.snapshot.params.scid;
    this.ic = this.route.snapshot.params.ic;
    this.raId = Number(this.route.snapshot.params.code);
    this.irqFlagService = this.irqService.globalIRQFlag;
    this.irqService.questionnaireType='';
    this.raService.questionnaireType='';
    if(this.irqFlagService){
      this.metaDataValues = this.irqService.irqMetadata;
    }else{
      this.metaDataValues = this.raService.raMetadata;
    }
    this.deleteQuestionsList=["InformationSecurity_2","InformationSecurity_2a","InformationSecurity_3","InformationSecurity_3a"];
    console.log("irqFlagService", this.irqFlagService);

    // workflow load current login user's tasks

    this.context = new DynamicRaContext();
    if(this.irqFlagService){
      this.title = "Inherent Risk Questionnaire";
      this.subtitle = "Please provide short description of the service/s associated with this Inherent Risk Questionnaire.";
      this.popupTooltip = "Please provide short description of this inherent risk questionnaire which would help users to identify the assessment and match it to a particular engagement with this supplier"
    }else {
      this.title = "Risk Assesment";
      this.subtitle ="Short description of this risk assessment "
      this.popupTooltip ="Please provide short description of this risk assessment which would help users to identify the assessment and match it to a particular engagement with this supplier"
    }
    if(this.irqFlagService){
      this.context.mode = this.irqService.mode;
      console.log("this.irqService.raModel", this.irqService.raModel);
    this.context.set(
      "parentModel",
      cloneDeep(this.irqService.raModel)
    );
    }else{
      this.context.mode = this.raService.mode;
      this.context.set(
        "parentModel",
        cloneDeep(this.raService.raModel)
      );
    }
    
    this.context.set('RelationshipIDExists', false)
    this.context.set('InvalidInput', false)


    // adding this timeout to make the bi-bda popup appear
    await new Promise(f => setTimeout(f, 1000));

    if (this.irqFlagService) {
      if (this.irqService.mode === "view" || this.irqService.mode === "edit") {
        this.shortDesc = this.irqService.shortDescription
      }
      this.questionnaireType = 'IRQ';
      this.raFormMapping = this.irqService.irqQuestion.data;
    }else {
      if (this.raService.mode === "view" || this.raService.mode === "edit") {
        this.shortDesc = this.raService.shortDescription
        this.cid = this.raService.cid
        this.scid = this.raService.scid
        this.ic = this.raService.ic
      }
      this.questionnaireType = 'RA';
      this.raFormMapping = this.raService.raQuestion.data;
    }
    if (this.raFormMapping) {
      if (this.irqFlagService) {
        this.raFormMapping.forEach(question => {
         if(question.id === "InformationSecurity"){
            question.label = "Information Security & Data Protection";
          }
          // question.fieldset.forEach(field => {
          //   if (field.trackingID) {
          //     field.label = `${field.trackingID} ${field.label}`;
          //   }
          // });
        });
      }
      this.questionsLoaded = true
    } else {
      this.questionsLoaded = false
    }
      for (let i = 0; i < this.raFormMapping.length; i++) {
        if (this.raFormMapping[i].hide && this.raFormMapping[i].id != "InformationSecurity") {
          for (let j = 0; j < this.raFormMapping[i].fieldset.length; j++) {
            this.raFormMapping[i].fieldset[j].hide = true;
          }
        }
        if (this.raFormMapping[i].id == 'InformationSecurity') {
          let hideInfoSec = this.raFormMapping[i].hide;
          if(this.irqFlagService && this.context.mode !=='view'){
            hideInfoSec = false;
            for (let j = 0; j < this.raFormMapping[i].fieldset.length; j++) {
              if(this.deleteQuestionsList.includes(this.raFormMapping[i].fieldset[j].name)){
                this.raFormMapping[i].fieldset.splice(j, 1);j--;
            }
          }
        }else{
        if ((this.scid == '5002' || this.scid == '5021') && this.ic == 'Yes') {
              hideInfoSec = true;
            } else if (
              (this.scid == '5002' || this.scid == '5021') &&
              this.ic == 'No'
            ) {
              hideInfoSec = false;
            }
          }
          this.isInformationSecurityShow = !hideInfoSec;
          this.raFormMapping[i].hide = hideInfoSec;
          if (hideInfoSec) {
            for (let j = 0; j < this.raFormMapping[i].fieldset.length; j++) {
              this.raFormMapping[i].fieldset[j].hide = true;
            }
          }

          // add dynamic value in valodators
          for (let j = 0; j < this.raFormMapping[i].fieldset.length; j++) {           
            if (
              this.raFormMapping[i].fieldset[j].name ===
              'InformationSecurity_1Yes'
            ) {
              this.raFormMapping[i].fieldset[j].templateOptions.maxLength = 10;
              this.raFormMapping[i].fieldset[j].errorMessage = { RelationshipIDExists: "Record does not exist" }
              this.raFormMapping[i].fieldset[j].validateOn = 'change';
              this.raFormMapping[i].fieldset[j].validators = [
                {
                  name: 'RelationshipIDExists',
                },
              ];
            }
          }
        }
        for (let j = 0; j < this.raFormMapping[i].fieldset.length; j++) {
          if (this.raFormMapping[i].fieldset[j].name === 'AntiCorruption_2') {
            this.raFormMapping[i].fieldset[j].templateOptions.maxLength = 6;
        
            this.raFormMapping[i].fieldset[j].errorMessage = { 
              InvalidInput: "Please enter a valid BI/BDA case ID consisting of exactly 5 or 6 digits." 
            };
        
            this.raFormMapping[i].fieldset[j].validateOn = 'change';
        
            this.raFormMapping[i].fieldset[j].validators = [
              {
                name: 'InvalidInput',    
              }
            ];
          }
        }
      }
      this.getConfigsInfo = this.raFormMapping;
      if(this.context.mode !=='view'){
      this.dynamicForm.subscribeToModelChange("BI_BDA_1", async (value) => {
        if (value === "11" || value === "12") {
          await this.dialogService.dialog(
            `Business Sponsor must complete the BI questionaire within the BI Portal. <a href="https://ethicscompliancehub-bip.accenture.com/" target="_blank" style="color: #006caf; font-weight: 600; text-decoration: underline">Click</a> 
            for access to the BI Portal and for more information on the BI vetting process`,
            ButtonCommands.Close,
            DialogType.warning,
            "Please Note"
          );
        }
      });
      this.dynamicForm.subscribeToModelChange("AntiCorruption_3", async (value) => {
        if (value === "13") {
          await this.dialogService.dialog(
            `Please note that because your selected supplier is designated as a BI or BDA you must complete the required steps in the BI Portal to complete supplier onboarding. You may do so at your earliest convenience.`,
            ButtonCommands.Close,
            DialogType.warning,
            "Please Note"
          );
        }
      });
      this.dynamicForm.subscribeToModelChange("ConflictOfInterest_1", async (value) => {
        if (value === "11") {
          await this.dialogService.dialog(
            `Please ensure that you comply with <a href="https://policies.accenture.com/policy/1004" target="_blank" style="color: #006caf; font-weight: 600; text-decoration: underline">Policy 1004 Addressing Personal Conflicts of Interest</a> and that you enter a new case in the <a href="https://ts.accenture.com/sites/ECO/EthicsGroup/SitePages/Personal%20Conflicts%20of%20Interest%20Tool.aspx" target="_blank" style="color: #006caf; font-weight: 600; text-decoration: underline">Personal Conflicts of Interest Tool</a>`,
            ButtonCommands.Close,
            DialogType.warning,
            "Please Note"
          );
        }
      });
      this.dynamicForm.subscribeToModelChange(
        "InformationSecurity_1f",
        async (value) => {
          if (value) {
            let tmpMessage;
            if (value === "11") {
              tmpMessage = `By selecting ‘Yes’ to this question you are indicating that as a requirement of the services,
                        data can and will be moved between physical locations which could include geographical boundaries.
                        This requirement is independent of additional controls that may exist to prevent the unauthorized movement of data outside of Accenture or Client applications,
                        environments or infrastructure.`;
            } else if (value === "10") {
              tmpMessage = `By selecting ‘No’ to this question you are indicating the supplier is working within an Accenture
                        or client environment where the scope of services does not require data movement and controls ARE
                        implemented to prevent unauthorized data movement outside of the applications, environment or infrastructure.`;
            }
            await this.dialogService.dialog(
              tmpMessage,
              ButtonCommands.Close,
              DialogType.warning,
              "Please Note"
            );
          }
        }
      );
      this.dynamicForm.subscribeToModelChange("dpis_purchase_ai", async (value) => {
        if (value === "11") {
          await this.dialogService.dialog(
            `Please be aware that you may be contacted to complete extra AI questionnaires, and suppliers will have extra due diligence to complete to ensure we comply with regulation`,
            ButtonCommands.Close,
            DialogType.warning,
            "Please Note"
          );
        }
      });
    }

      this.commonService.ExitEvent.subscribe((e) => {
        this.vm.setOriginal(this.dynamicForm.originalValues());
        this.vm.setCurrent(this.dynamicForm.values());
        if (this.vm.isDirty()) {
          e.preventDefault();
          // Chrome requires returnValue to be set
          e.returnValue = "";
        }
      });
    
  }

  //To check the logged in user has BI role
  getBIRole = () => {
    if (this.authService.isBI()) {
      return true
    }
    return false
  }

  //To make the BI/BDA review button enable only for applicable RA's
  getBiBdaStatus = () => {
    let bibdaReviewStatus;
    let IRQbibdaReviewStatus;
    this.route.queryParams.subscribe((params: Params) => {
      bibdaReviewStatus = params["TBR"];
      IRQbibdaReviewStatus = params["IRQ"];
    });
    return bibdaReviewStatus == "1" || IRQbibdaReviewStatus == "1";
  }

  buttonType() {
    this.saveDraftRaProps = {
      ...this.sharedButtonProps,
      width: 230,
      color: "white",
      backgroundColor: "blue",
      borderColor: "blue",
      label: "Save as draft",
      onClick: () => {
        this.isDraft = true;
        this.questionsLoaded = false;
        this.getRiskAssessmentPayload(this.dynamicForm);
      },
    };
    this.submitRaProps = {
      ...this.sharedButtonProps,
      width: 230,
      color: "white",
      backgroundColor: "blue",
      borderColor: "blue",
      label: "Submit",
      onClick: () => {
        this.isDraft = false;
        this.getRiskAssessmentPayload(this.dynamicForm);
      },
    };
    this.reviewbibda = {
      ...this.sharedButtonProps,
      width: 230,
      color: "white",
      backgroundColor: "blue",
      borderColor: "blue",
      label: "Review BI/BDA",
      onClick: () => {
        this.evaluateBibda()
      },
    };
  }

  get ShowResubmitButton() {
    return false;
  }

  navigate(item) {
    const element = document.getElementById(item);
    window.scroll(0, element.parentElement.offsetTop);
  }

  validate() {
    return this.dynamicForm.valide();
  }

  async getRiskAssessmentPayload(form) {
    if (this.isDraft == false) {
      let InformationSecurity_1Yes = form.formGroup.value.InformationSecurity_1Yes
      let InformationSecurity_1 = form.formGroup.value.InformationSecurity_1
      let AntiCorruption_1 = form.formGroup.value.AntiCorruption_1
      let AntiCorruption_2 = form.formGroup.value.AntiCorruption_2
    
      if (InformationSecurity_1 === '11' && InformationSecurity_1Yes !== '') {
        InformationSecurity_1Yes = InformationSecurity_1Yes.toUpperCase()
        await this.checkRiskAssessment(InformationSecurity_1Yes).then((res: any) => {
          if (res.data === undefined) {
            let currentError = { 'RelationshipIDExists': true }
            this.dynamicForm.formGroup.controls['InformationSecurity_1Yes'].setErrors(currentError);
          }
        })
      }
      if(AntiCorruption_1 === '10' || AntiCorruption_1 === '11' && AntiCorruption_2 != ''){
        if (this.validateAntiCorruptionLength(AntiCorruption_2)) {
          let currentError = { 'InvalidInput': true };
    
          this.dynamicForm.formGroup.controls['AntiCorruption_2'].setErrors(currentError);
        }
      }

      this.context.changeSubmitState();
      this.dynamicForm.removeFormTouch();
    }

   
    
    if (this.isDraft == false && !this.validate()) {
      return;
    }
    const riskAssessmentPayload = []
    let selectedValues = form.formGroup.value;
    const dataPrivacyForm = await this.getDataPrivacyDetails(selectedValues);
    const biBdaForm = await this.getBiBdaDetails(selectedValues);
    const infoSecForm = await this.getInfoSecDetails(selectedValues);
    const brForm = await this.getBusinessResilienceDetails(selectedValues);
    const bcForm = await this.getBusinessCapability(selectedValues);
    const asForm = await this.getAssetSafety(selectedValues);
    const aiForm = await this.getArtificialIntelligence(selectedValues);
    const ipForm = await this.getIntellectualPropertyDetails(selectedValues);
    const coiForm = await this.getConflictOfInterestDetails(selectedValues);
    const mlForm = await this.getMoneyLaunderingDetails(selectedValues);
    const frForm = await this.getFinancialReportingDetails(selectedValues);
    const fpForm = await this.getFourthPartyDetails(selectedValues);
    const reputationalForm = await this.getReputationalDetails(selectedValues);
    const dataAndAiForm = await this.getDataAndAiDetails(selectedValues);
    const geopoliticalForm = await this.getGeopoliticalDetails(selectedValues);
    const generalForm = await this.getGeneralDetails(selectedValues);
    const csForm = await this.getCyberSecurityDetails(selectedValues);
    const irqBrForm = await this.getIrqBusinessResiliencyDetails(selectedValues); 
    const irqDpForm = await this.getIrqDataPrivacyDetails(selectedValues);
    const acForm = await this.getAntiCorruptionDetails(selectedValues);
    const irqAsForm = await this.getIrqAssetSafetyDetails(selectedValues);
    const metatdataInfo = await this.getMetadataInfo();
    riskAssessmentPayload.push(dataPrivacyForm, biBdaForm, infoSecForm, aiForm, brForm, bcForm, asForm, csForm, irqDpForm, dataAndAiForm, irqBrForm, irqAsForm, acForm, ipForm, mlForm, coiForm, frForm, fpForm, reputationalForm, geopoliticalForm, generalForm);
    const finalPayload = {
      "ra_id": this.raId,
      "metatdataInfo": metatdataInfo,
      "riskAssessmentAnswers": riskAssessmentPayload
    }
    this.submitRiskAssessment(finalPayload);
  }

  async submitRiskAssessment(payload): Promise<void> {
    this.questionsLoaded=false;
    const triggerRA = `${environment.tsmBackendServiceURL}/riskAssessmentData/riskAssessmentSubmit`;
    await this.HttpService.PostPromise(triggerRA, payload).then(async (res: any) => {
      if (res.msg == "Success") {
        if (res.supplierDetails && res.irqStatus) {
          for (let data of res.supplierDetails) {
            const url = `${environment.tsmBackendServiceURL}/riskAssessmentData/updateIRQStatus`;
            const params = {
              supplierCode: data.supplierCode,
              status: res.irqStatus[0].status,
              bibda: res.irqStatus[0].bibda,
              onboardingRequestor: data.onboardingRequestor
            }
            try{
              await this.HttpService.PostPromise(url, params);
              console.log("IRQ status updated successfully.");
            }
            catch(error){
              console.error("Error updating IRQ status:", error);
            }
          }
        }
        this.questionsLoaded = true;
        this.router.navigate([`supplier-profile/${this.metaDataValues["tsmId"]}`]);
      }
    });

  }

  public async checkRiskAssessment(relationshipNumber: string): Promise<any> {
    const url = `${environment.tsmBackendServiceURL}/riskAssessmentData/validateRelationshipId?relationshipnumber=${relationshipNumber}`;
    return await this.HttpService.GetPromise(url);
  }

  public validateAntiCorruptionLength(fieldValue: string): boolean {
    const regex = /^[0-9]{5,6}$/;
    return !regex.test(fieldValue); 
  }
  
  getMetadataInfo() {
    var metatdataInfo = {
      isdraft: this.isDraft,
      assessment_status: this.isDraft ? 'In Progress' : 'Submitted',
      short_desc: this.shortDesc,
      questionnaire_type: this.questionnaireType
    };
    if (this.raId === 0) {
      metatdataInfo['category_code'] = this.metaDataValues['categoryCode'];
      metatdataInfo['sub_category_code'] =
        this.metaDataValues['subCategoryCode'];
      metatdataInfo['tsm_id'] = this.metaDataValues['tsmId'];
      metatdataInfo['requestor_name'] = this.metaDataValues['requestorName'];
      metatdataInfo['market_unit'] = this.metaDataValues['marketUnit'];
      metatdataInfo['country_served'] = this.metaDataValues['countryServed'];
      metatdataInfo['category'] = this.metaDataValues['category'];
      metatdataInfo['sub_catergory'] = this.metaDataValues['subCategory'];
      metatdataInfo['supplier_manager'] = this.metaDataValues['supplierPoc'];
      metatdataInfo['supplier_name'] = this.metaDataValues['supplierName'];
      metatdataInfo['is_independent_contractor'] = this.metaDataValues['independentContractor'];
      metatdataInfo['supplier_contact_first_name'] = this.metaDataValues['supplierContactFirstName'];
      metatdataInfo['supplier_contact_last_name'] = this.metaDataValues['supplierContactLastName'];
    }
    return metatdataInfo;
  }

  getDataPrivacyDetails(value) {
    let dataPrivacy = {};
    this.raFormMapping.forEach((section) => {
      if (section.id == 'Data_Privacy') {
        section.fieldset.forEach((question) => {
          dataPrivacy[question.name] = value[question.name];
        })
      }
    })
    const dp = {
      "dataPrivacy": dataPrivacy
    }
    return dp;
  }

  getBiBdaDetails(value) {
    let BI_BDA = {};
    this.raFormMapping.forEach((section) => {
      if (section.id == 'BI_BDA') {
        section.fieldset.forEach((question) => {
          if (question.name === "BI_BDA_1bEndDate" || question.name === "BI_BDA_1bStartDate") {
            value[question.name] = value[question.name] === '' ? '' : new Date(value[question.name]).toLocaleDateString("en-US");
          }
          BI_BDA[question.name] = value[question.name];
        })
      }
    })
    const biBda = {
      "BI_BDA": BI_BDA
    }
    return biBda;
  }

  getInfoSecDetails(value) {
    let InformationSecurity = {};
    this.raFormMapping.forEach((section) => {
      if (section.id == 'InformationSecurity') {
        section.fieldset.forEach((question) => {
          if (question.name === 'InformationSecurity_1Yes') {
            value[question.name] = value[question.name].toUpperCase();
          }
          InformationSecurity[question.name] = value[question.name];
        })
      }
    })
    const infoSec = {
      "InformationSecurity": InformationSecurity
    }
    return infoSec;
  }

  getBusinessResilienceDetails(value) {
    let BusinessResilience = {};
    this.raFormMapping.forEach((section) => {
      if (section.id == 'BusinessResilience') {
        section.fieldset.forEach((question) => {
          BusinessResilience[question.name] = value[question.name];
        })
      }
    })
    const br = {
      "BusinessResilience": BusinessResilience
    }
    return br;
  }

  getBusinessCapability(value) {
    let BusinessCapability = {};
    this.raFormMapping.forEach((section) => {
      if (section.id == 'BusinessCapability') {
        section.fieldset.forEach((question) => {
          BusinessCapability[question.name] = value[question.name];
        })
      }
    })
    const bc = {
      "BusinessCapability": BusinessCapability
    }
    return bc;
  }

  getAssetSafety(value) {
    let AssetSafety = {};
    this.raFormMapping.forEach((section) => {
      if (section.id == 'AssetSafety') {
        section.fieldset.forEach((question) => {
          if (question.name === "AS1") {
            if (Array.isArray(value[question.name])) {
              value[question.name] = value[question.name].join();
            }
          }
          AssetSafety[question.name] = value[question.name];
        })
      }
    })
    const as = {
      "AssetSafety": AssetSafety
    }
    return as;
  }

  getArtificialIntelligence(value) {
    let ArtificialIntelligence = {};
    this.raFormMapping.forEach((section) => {
      if (section.id == 'ArtificialIntelligence') {
        section.fieldset.forEach((question) => {
          ArtificialIntelligence[question.name] = value[question.name];
        })
      }
    })
    const ai = {
      "ArtificialIntelligence": ArtificialIntelligence
    }
    return ai;
  }
  getIntellectualPropertyDetails(value)
  {
    let IRQ_IntellectualProperty = {};
    this.raFormMapping.forEach((section) => {
      if(section.id == 'IRQ_IntellectualProperty') {
        section.fieldset.forEach((question) => {
          IRQ_IntellectualProperty[question.name] = value[question.name];
        })
      }
    })
    const ip = {
      "IRQ_IntellectualProperty" : IRQ_IntellectualProperty
    }
    return ip;
  }

  getConflictOfInterestDetails(value) {
    let IRQ_ConflictOfInterest = {};
    this.raFormMapping.forEach((section) => {
      if (section.id == 'IRQ_ConflictOfInterest') {
        section.fieldset.forEach((question) => {
          IRQ_ConflictOfInterest[question.name] = value[question.name];
        });
      }
    });
    const coi = {
      "IRQ_ConflictOfInterest": IRQ_ConflictOfInterest
    };
    return coi;
  }
  
  getMoneyLaunderingDetails(value) {
    let IRQ_MoneyLaundering = {};
    this.raFormMapping.forEach((section) => {
      if (section.id == 'IRQ_MoneyLaundering') {
        section.fieldset.forEach((question) => {
          IRQ_MoneyLaundering[question.name] = value[question.name];
        });
      }
    });
    const ml = {
      "IRQ_MoneyLaundering": IRQ_MoneyLaundering
    };
    return ml;
  }
  
  getFinancialReportingDetails(value) {
    let IRQ_FinancialReporting = {};
    this.raFormMapping.forEach((section) => {
      if (section.id == 'IRQ_FinancialReporting') {
        section.fieldset.forEach((question) => {
          IRQ_FinancialReporting[question.name] = value[question.name];
        });
      }
    });
    const fr = {
      "IRQ_FinancialReporting": IRQ_FinancialReporting
    };
    return fr;
  }
  
  getFourthPartyDetails(value) {
    let IRQ_FourthParty = {};
    this.raFormMapping.forEach((section) => {
      if (section.id == 'IRQ_FourthParty') {
        section.fieldset.forEach((question) => {
          IRQ_FourthParty[question.name] = value[question.name];
        });
      }
    });
    const fp = {
      "IRQ_FourthParty": IRQ_FourthParty
    };
    return fp;
  }
  
  getReputationalDetails(value) {
    let IRQ_Reputational = {};
    this.raFormMapping.forEach((section) => {
      if (section.id == 'IRQ_Reputational') {
        section.fieldset.forEach((question) => {
          IRQ_Reputational[question.name] = value[question.name];
        });
      }
    });
    const rep = {
      "IRQ_Reputational": IRQ_Reputational
    };
    return rep;
  }
  
  getDataAndAiDetails(value) {
    let IRQ_DataAndAI = {};
    this.raFormMapping.forEach((section) => {
      if (section.id == 'IRQ_DataAndAI') {
        section.fieldset.forEach((question) => {
          IRQ_DataAndAI[question.name] = value[question.name];
        });
      }
    });
    const dataAndAi = {
      "IRQ_DataAndAI": IRQ_DataAndAI
    };
    return dataAndAi;
  }
  
  getGeopoliticalDetails(value) {
    let IRQ_Geopolitical = {};
    this.raFormMapping.forEach((section) => {
      if (section.id == 'IRQ_Geopolitical') {
        section.fieldset.forEach((question) => {
          IRQ_Geopolitical[question.name] = value[question.name];
        });
      }
    });
    const geopolitical = {
      "IRQ_Geopolitical": IRQ_Geopolitical
    };
    return geopolitical;
  }
  
  getIrqBusinessResiliencyDetails(value) {
    let IRQ_BusinessResiliency = {};
    this.raFormMapping.forEach((section) => {
      if (section.id == 'IRQ_BusinessResiliency') {
        section.fieldset.forEach((question) => {
          IRQ_BusinessResiliency[question.name] = value[question.name];
        });
      }
    });
    const br = {
      "IRQ_BusinessResiliency": IRQ_BusinessResiliency
    };
    return br;
  }
  
  getGeneralDetails(value) {
    let IRQ_General = {};
    this.raFormMapping.forEach((section) => {
      if (section.id == 'IRQ_General') {
        section.fieldset.forEach((question) => {
          if (question.name === "General_1") {
            if (Array.isArray(value[question.name])) {
              value[question.name] = value[question.name].join();
            }
          }
          IRQ_General[question.name] = value[question.name];
        });
      }
    });
    const general = {
      "IRQ_General": IRQ_General
    };
    return general;
  }
  
  getCyberSecurityDetails(value) {
    let IRQ_CyberSecurity = {};
    this.raFormMapping.forEach((section) => {
      if (section.id == 'IRQ_CyberSecurity') {
        section.fieldset.forEach((question) => {
          if (question.name === "CyberSecurity_7") {
            if (Array.isArray(value[question.name])) {
              value[question.name] = value[question.name].join();
            }
          }
          IRQ_CyberSecurity[question.name] = value[question.name];
        });
      }
    });
    const cs = {
      "IRQ_CyberSecurity": IRQ_CyberSecurity
    };
    return cs;
  }
  
  getIrqDataPrivacyDetails(value) {
    let IRQ_DataPrivacy = {};
    const qnList = ['DataPrivacy_2', 'DataPrivacy_4', 'DataPrivacy_7', 'DataPrivacy_8', 'DataPrivacy_11','DataPrivacy_13'];
    this.raFormMapping.forEach((section) => {
      if (section.id == 'IRQ_DataPrivacy') {
        section.fieldset.forEach((question) => {
          if (qnList.includes(question.name)) {
            if (Array.isArray(value[question.name])) {
              IRQ_DataPrivacy[question.name] = value[question.name].join();
            } 
          } 
          else {
            IRQ_DataPrivacy[question.name] = value[question.name];
          }
        });
      }
    });
    const dp = {
      "IRQ_DataPrivacy": IRQ_DataPrivacy
    };
    return dp;
  }
  
  getAntiCorruptionDetails(value) {
    let IRQ_AntiCorruption = {};
    this.raFormMapping.forEach((section) => {
      if (section.id == 'IRQ_AntiCorruption') {
        section.fieldset.forEach((question) => {
          IRQ_AntiCorruption[question.name] = value[question.name];
        });
      }
    });
    const ac = {
      "IRQ_AntiCorruption": IRQ_AntiCorruption
    };
    return ac;
  }
  
  getIrqAssetSafetyDetails(value) {
    let IRQ_AssetsSafety = {};
    this.raFormMapping.forEach((section) => {
      if (section.id == 'IRQ_AssetsSafety') {
        section.fieldset.forEach((question) => {
          IRQ_AssetsSafety[question.name] = value[question.name];
        });
      }
    });
    const as = {
      "IRQ_AssetsSafety": IRQ_AssetsSafety
    };
    return as;
  }
  // update supplier mapping info
  private async updateSupplierMapping() {
    this.raService.raModel.RequestForm.RiskAssessmentInfo = trim(
      this.dynamicForm.values()
    );
  }
  // resubmit request form
  async resubmitForm(): Promise<boolean> {
    return true;
  }

  async resubmit() {
    this.resubmitForm().then((data: boolean) => {
      if (data) {
        this.vm.saved();
        this.dialogService
          .dialog(
            `Your change request has been successfully submitted.`,
            ButtonCommands.Ok,
            DialogType.success,
            "Please Note"
          )
          .then((result: boolean) => {
            this.router.navigate([`buyer`]);
          });
      }
    });
  }

  async cancel(): Promise<any> {
    this.router.navigate([`supplier-profile/${this.metaDataValues["tsmId"]}`]);
  }

  evaluateBibda = async () => {
        const confirmType = {
      options: {},
      centered: true,
      size: "lg",
      windowClass: "modal-country-notify",
      bibdaCaseId: this.riskAssessmentInfo.BI_BDA_1a,
      irqbibdaCaseId: this.riskAssessmentInfo.AntiCorruption_2,
      raId:this.raId,
      raFormMapping: this.raFormMapping,
      riskAssessmentInfo:this.riskAssessmentInfo
    };
    let modalReference;
    if (confirmType.irqbibdaCaseId) {
      modalReference = this.ngbModalService.open(PopUpIrqBibdaScoreComponent, confirmType);
    } else {
      modalReference = this.ngbModalService.open(PopUpBibdaScoreComponent, confirmType);
    }
    modalReference.componentInstance.confirmType = confirmType;
  }
}
