import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { NgbDateParserFormatter, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel
} from "@azure/msal-browser";
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent
} from "@azure/msal-angular";

import { environment } from "src/environments/environment";
import { b2cPolicies, apiConfig } from "./b2c-config";

import { AppRoutingModule } from "./app-routing.module";
import { SusHubFrontendModule } from "@lsl16/sushub-frontend";
import { DynamicComponentService, SustainabilitySharedComponentsModule, DynamicRaComponentService , DynamicRaContext, DictionaryRaService, CommonService } from "@lsl16/sustainability-shared-components";

import { AuthService } from "./services/auth/auth.service";
import { AuthInterceptor } from "../app/services/auth/auth.interceptor";

import { ArrayPipe } from "./pipes/array.pipe";
import { HtmlPipe } from "./pipes/html/html.pipe";

import { PermissionDirective } from "./permission.directive";

import { AppComponent } from "./app.component";

import { DashboardComponent } from "./views/dashboard/dashboard.component";
import { LayoutComponent } from "./views/layout/layout.component";
import { CallbackComponent } from "./views/callback/callback.component";
import { AddSupplierComponent } from "./views/add-supplier/add-supplier.component";
import { SupplierActionSelectionComponent } from "./views/supplier-action-selection/supplier-action-selection.component";
import { AddGeographyServedComponent } from "./views/add-geography-served/add-geography-served.component";
import { SustainabilityAssessmentComponent } from "./views/sustainability-assessment/sustainability-assessment.component";
import { SupplierProfileContainerComponent } from "./views/supplier-profile-containter/supplier-profile-container.component";
import { BaseSupplierProfileComponent } from "./views/supplier-profile-containter/base-supplier-profile/base-supplier-profile.component";
import { SvSupplierProfileComponent } from "./views/supplier-profile-containter/sv-supplier-profile/sv-supplier-profile.component";
import { BvSupplierProfileComponent } from "./views/supplier-profile-containter/bv-supplier-profile/bv-supplier-profile.component";
import { SupplierAdminBoardComponent } from "./views/supplier-admin-board/supplier-admin-board.component";
import { SupplierUserProfileComponent } from "./views/supplier-user-profile/supplier-user-profile.component";
import { CreateNewUserComponent } from "./views/create-new-user/create-new-user.component";
import { LandingContainerComponent } from "./views/landing-container/landing-container.component";
import { supllierHelpAndSupportComponent } from "./views/supplier-help-and-support/supplier-help-and-support.component";
import { GdprDashboardComponent } from "./views/gdpr-dashboard/gdpr-dashboard.component";
import { DashboardSearchComponent } from "./views/dashboard-search/dashboard-search.component";
import { DashboardCardComponent } from "./components/dashboard-card/dashboard-card.component";
import { CarouselComponent } from "./components/carousel/carousel.component";
import { PopUpSelectCountryServedComponent } from "./components/pop-up-select-country-served/pop-up-select-country-served.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { PaginationBarComponent } from "./components/pagination-bar/pagination-bar.component";
import { SearchComponent } from "./components/search/search.component";
import { SubheaderComponent } from "./components/subheader/subheader.component";
import { CardComponent } from "./components/card/card.component";
import { SupplierCardListComponent } from "./components/supplier-card-list/supplier-card-list.component";
import { BaseContainerComponent } from "./components/base-container/base-container.component";
import { PopUpComponent } from "./components/pop-up/pop-up.component";
import { PopUpBVComponent } from "./components/pop-up-bv/pop-up-bv.component";
import { OptionsCardComponent } from "./components/options-card/options-card.component";
import { OptionsCardStatusComponent } from "./components/options-card-status/options-card-status.component";
import { SecondaryBannerComponent } from "./components/secondary-banner/secondary-banner.component";
import { TabBarComponent } from "./components/tab-bar/tab-bar.component";
import { ProfileDetailsComponent } from "./components/profile-details/profile-details.component";
import { PopUpWelcomeComponent } from "./components/pop-up-welcome/pop-up-welcome.component";
import { UserSecondaryBannerComponent } from "./components/user-secondary-banner/user-secondary-banner.component";

import { SubheaderService } from "./services/subheader/subheader.service";
// import { SupplierInformationService } from "./services/supplierInfo/supplier-information.service"; // commenting as not in USE as part of ASA : US "591210"
import { LoginGuard } from "./services/auth/login.guard";
import { AuthSupplierProfileComponent } from './views/supplier-profile-containter/auth-supplier-profile/auth-supplier-profile.component';
import { PopUpDeleteUserComponent } from './components/pop-up-delete-user/pop-up-delete-user.component';
import { SustainabilityAssessmentReportComponent } from './views/sustainability-assessment-report/sustainability-assessment-report.component';
import { SystemAdminBoardComponent } from './views/system-admin-board/system-admin-board.component';
import { SystemAdminBoardUsersComponent } from './components/system-admin-board/system-admin-board-users/system-admin-board-users.component';
import { SystemAdminBoardUserItemComponent } from './components/system-admin-board/system-admin-board-user-item/system-admin-board-user-item.component';
import { SubheaderMultipleDropdownComponent } from './components/subheader-multiple-dropdown/subheader-multiple-dropdown.component'
import { SelectDropDownBoxComponent } from './components/select-drop-down-box/select-drop-down-box.component';
import { SelectDropDownBoxAdminComponent } from './components/select-drop-down-box-admin/select-drop-down-box-admin.component';
import { PopUpDisabledComponent } from "./components/pop-up-disabled/pop-up-disabled.component";
import { PopUpPromisionsupplierComponent } from './components/pop-up-promisionsupplier/pop-up-promisionsupplier.component';
import { PopUpPromisionbuyerComponent } from './components/pop-up-promisionbuyer/pop-up-promisionbuyer.component';
import { PopUpInactiveComponent } from "./components/pop-up-inactive/pop-up-inactive.component";
import { PopUpBvManagerequestComponent } from './components/pop-up-bv-managerequest/pop-up-bv-managerequest.component';
import { SustainabilityAssessmentNewComponent } from './views/sustainability-assessment-new/sustainability-assessment-new.component';
import { PopUpCountryservedExistingComponent } from './components/pop-up-countryserved-existing/pop-up-countryserved-existing.component';
import { ManageLogComponent } from './components/manage-log/manage-log.component';
import { ManageLogItemComponent } from './components/manage-log-item/manage-log-item.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { SystemAdminUserProfileComponent } from './views/system-admin-user-profile/system-admin-user-profile.component';
import { SystemAdminUserProfileDetailComponent } from './components/system-admin-user-profile-detail/system-admin-user-profile-detail.component';
import { Supplierhub2esgComponent } from './views/supplierhub2esg/supplierhub2esg.component';
import { BuyerRelationshipComponent } from './views/buyer-relationship/buyer-relationship.component';
import { ConfirmSupplierParentCreationComponent } from './views/confirm-supplier-parent-creation/confirm-supplier-parent-creation.component';
import { SupplierUpdateSaComponent } from './views/supplier-update-sa/supplier-update-sa.component';
import { MessagePopUpComponent } from './components/message-pop-up/message-pop-up.component';
import { SupplierRelationshipComponent } from './views/supplier-relationship/supplier-relationship.component';
import { SupplierParentDetailComponent } from './views/supplier-parent-detail/supplier-parent-detail.component';
import { ConfirmParentComponent } from './views/confirm-parent/confirm-parent.component';
import { ScoringComponent } from "./views/scoring/scoring.component";
import { PopUpExitAddsupplierComponent } from './components/pop-up-exit-addsupplier/pop-up-exit-addsupplier.component';
import { ParentConfirmSupplierProfileComponent } from './views/supplier-profile-containter/parent-confirm-supplier-profile/parent-confirm-supplier-profile.component';
import { ParentRelationshipActionComponent } from './components/parent-relationship-action/parent-relationship-action.component';
import { PopUpParentRejectRelationshipComponent } from './components/pop-up-parent-reject-relationship/pop-up-parent-reject-relationship.component';
import { PopUpPrivacyComponent } from './components/pop-up-privacy/pop-up-privacy.component';
import { AddNewSupplierComponent } from "./components/add-new-supplier/add-new-supplier.component";
import { TerminationRequestBoardComponent } from "./views/termination-request-board/termination-request-board.component";
import { TerminationRequestLogComponent } from "./components/search/termination-request-log/termination-request-log.component";
import { TerminationRequestLogItemComponent } from "./components/search/termination-request-log-item/termination-request-log-item.component";
import { PopUpRejectComponent } from "./components/pop-up-reject/pop-up-reject.component";
import { PopUpTerminateComponent } from "./components/pop-up-terminate/pop-up-terminate.component";
import { popUpManageSARequestMessageComponent } from "./components/manage-SA-request-approve/pop-up-manage-SA-request-message/pop-up-manage-SA-request-message.component";
import { UserProfileContainerComponent } from './components/user-profile-container/user-profile-container.component';
import { UserProfileEditRolesComponent } from './views/user-profile-edit-roles/user-profile-edit-roles.component';
import { UserProfileEditSaAccessComponent } from './views/user-profile-edit-sa-access/user-profile-edit-sa-access.component';
import { SupplierAdminBoardContainerComponent } from './views/supplier-admin-board-container/supplier-admin-board-container.component';
import { SubsidiarySupplierAdminBoardComponent } from './views/subsidiary-supplier-admin-board/subsidiary-supplier-admin-board.component';
import { SubsidiarySupplierAdminBoardUsersComponent } from './components/subsidiary-supplier-admin-board/subsidiary-supplier-admin-board-users/subsidiary-supplier-admin-board-users.component';
import { SubsidiarySupplierAdminBoardUserItemComponent } from './components/subsidiary-supplier-admin-board/subsidiary-supplier-admin-board-user-item/subsidiary-supplier-admin-board-user-item.component';
import CustomDatasources from "./questionnaire/custom-datasources";
import { CustomFilters } from "./questionnaire/custom-filters";
import { CustomValidators } from "./questionnaire/custom-validators";
import { Validators } from "./questionnaire/constants";
import { BvPopUpTerminationComponent } from './components/bv-pop-up-termination/bv-pop-up-termination.component';
import { BvPopUpSuccessComponent } from './components/bv-pop-up-success/bv-pop-up-success.component';
import { CountryServedViewComponent } from './views/country-served-view/country-served-view.component';
import { PopUpPossibleScenariosComponent } from "./components/pop-up-possible-scenarios/pop-up-possible-scenarios.component";
import { ConfirmWithdrawRelationshipRequestComponent } from './components/confirm-withdraw-relationship-request/confirm-withdraw-relationship-request.component';
import { ParentSubsidiarySuppliersComponent } from './components/parent-subsidiary/parent-subsidiary-suppliers/parent-subsidiary-suppliers.component';
import { ParentSubsidiarySupplierItemComponent } from './components/parent-subsidiary/parent-subsidiary-supplier-item/parent-subsidiary-supplier-item.component';
import { ChartBarComponent } from "./components/chart-bar/chart-bar.component";
import { ScoreChartComponent } from "./components/score-chart/score-chart.component";
import { SectionPerformanceComponent } from "./components/section-performance-grid/section-performance-grid.component";
import { BvPopUpOnboardingComponent } from './components/bv-pop-up-onboarding/bv-pop-up-onboarding.component';
import { PopUpCountryServedNotifyComponent } from './components/pop-up-country-served-notify/pop-up-country-served-notify.component';
import { ConfirmCancelRelationshipRequestComponent } from './components/confirm-cancel-relationship-request/confirm-cancel-relationship-request.component';
import { SpecifyParentComponent } from './views/specify-parent/specify-parent.component';
import { SAScoringReportComponent } from './views/sascoring-report/sascoring-report.component';
import { SupplierNameComponent } from './components/filter-controls/supplier-name/supplier-name.component';
import { RequestorEmailComponent } from './components/filter-controls/requestor-email/requestor-email.component';
import { MarketUnitFilterComponent } from './components/filter-controls/market-unit-filter/market-unit-filter.component';
import { CountryServedFilterComponent } from './components/filter-controls/country-served-filter/country-served-filter.component';
import { EsgStatusFilterComponent } from './components/filter-controls/esg-status-filter/esg-status-filter.component';
import { RequestorPurposeFilterComponent } from './components/filter-controls/requestor-purpose-filter/requestor-purpose-filter.component';
import { InternalPocEmailFilterComponent } from './components/filter-controls/internal-poc-email-filter/internal-poc-email-filter.component';
import { SubmissionDateFilterComponent } from "./components/filter-controls/submission-date-filter/submission-date-filter.component";
import { CustomDateParserFormatter } from "./components/filter-controls/date-formatter/CustomDateParserFormatter";
import { RequestDateFilterComponent } from './components/filter-controls/request-date-filter/request-date-filter.component';
import { DiligenceDateFilterComponent } from './components/filter-controls/diligence-date-filter/diligence-date-filter.component';
import { SupplierCategoryFilterComponent } from "./components/filter-controls/supplier-category-filter/supplier-category-filter.component";
import { RecentParentComponent } from './views/recent-parent/recent-parent.component';
import { ReportPreviewComponent } from './views/report-preview/report-preview.component';
import { SaAndScoringReportDownloadButtonComponent } from './components/sa-and-scoring-report-download-button/sa-and-scoring-report-download-button.component';
import { InviteParentSupplierComponent } from './views/invite-parent-supplier/invite-parent-supplier.component';
import { PopUpConfirmRejectInviteSupplierComponent } from './components/pop-up-confirm-reject-invite-supplier/pop-up-confirm-reject-invite-supplier.component';
import { ParentChildHistoryComponent } from './views/parent-child/parent-child-history/parent-child-history.component';
import { ParentChildHistoryItemComponent } from './views/parent-child/parent-child-history-item/parent-child-history-item.component';
import { TagComponent } from './components/tag/tag.component';
import { GlobalParentDunsFilterComponent } from './components/filter-controls/global-parent-duns-filter/global-parent-duns-filter.component';
import { BvEsgAssessmentComponent } from './components/bv-esg-assessment/bv-esg-assessment.component';
import { BvNewSupplierProfileComponent } from './views/supplier-profile-containter/bv-new-supplier-profile/bv-new-supplier-profile.component';
import { PopUpSaStatusComponent } from "src/app/components/pop-up-sa-status/pop-up-sa-status.component";
import { SustainabilityAssessmentDownloadComponent } from './views/sustainability-process-report/sustainability-process-report.component';
import { downloadReportDateFilterComponent } from './components/filter-controls/download-report-date-filter/download-report-date-filter.component';
import { AppRoutingCache } from './app-routing.cache';
import { RouteReuseStrategy } from '@angular/router';
import { SubSuppliersComponent } from './views/sub-supplier/sub-suppliers/sub-suppliers.component';
import { SubSupplierItemComponent } from './views/sub-supplier/sub-supplier-item/sub-supplier-item.component';
import { AddSubSupplierComponent } from './views/add-sub-supplier/add-sub-supplier.component';
import { RelationStatusComponent } from './components/relation-status/relation-status.component';
import { InviteNewSupplierComponent } from './views/invite-new-supplier/invite-new-supplier.component';
import { HistoricalSubSuppliersComponent } from './views/sub-supplier/historical-sub-suppliers/historical-sub-suppliers.component';
import { HistoricalSubSupplierItemComponent } from './views/sub-supplier/historical-sub-supplier-item/historical-sub-supplier-item.component';
import { BvNewOnboardingComponent } from './components/bv-new-onboarding/bv-new-onboarding.component';
import { SelectInputComponent } from './components/select-input/select-input.component';
import { PopUpOnboardingComponent } from "./components/pop-up-onboarding/pop-up-onboarding.component";
import { PopUpStatusComponent } from "src/app/components/pop-up-status/pop-up-status.component";
import { RequestMultipleSaContainerComponent } from './views/request-multiple-sa-container/request-multiple-sa-container.component';
import { BaseSupplierFilterComponent } from "./components/base-filter/base-supplier-filter.component";
import { RequestMultipleSaSearchComponent } from './views/request-multiple-sa-search/request-multiple-sa-search.component';
import { IncludeIndirectCheckboxFilterComponent } from './components/filter-controls/include-indirect-checkbox-filter/include-indirect-checkbox-filter.component';
import { BvHelpSupportComponent } from './views/bv-help-support/bv-help-support.component';
import { PopUpFaqComponent } from "./components/pop-up-faq/pop-up-faq.component";
import { MultipleSaUploaderComponent } from './components/multiple-sa-uploader/multiple-sa-uploader.component';
import { RequestMultipleSaUploadComponent } from './views/request-multiple-sa-upload/request-multiple-sa-upload.component';
import { SupplierValidationContainerComponent } from './views/multi-sa/supplier-validation-list/supplier-validation-container/supplier-validation-container.component';
import { SupplierValidationListComponent } from './views/multi-sa/supplier-validation-list/supplier-validation-list/supplier-validation-list.component';
import { SupplierValidationItemComponent } from './views/multi-sa/supplier-validation-list/supplier-validation-item/supplier-validation-item.component';
import { PopUpMassInviteComponent } from './components/pop-up-mass-invite/pop-up-mass-invite.component';
import { RequestMultipleSaProcessFlowComponent } from './views/request-multiple-sa-process-flow/request-multiple-sa-process-flow.component';
import { PowerBiDashboardComponent } from './views/power-bi-dashboard/power-bi-dashboard.component';
import { PowerBiMenuButtonComponent } from './components/power-bi-menu-button/power-bi-menu-button.component';
import { ManageSAUpdatesApprovalsComponent } from './views/manage-SA-updates-approvals/manage-sa-updates-approvals.component';
import { ManageSARequestApproveLogComponent } from "./components/manage-SA-request-approve/manage-SA-request-approve-log/manage-SA-request-approve-log.component";
import { manageSARequestApproveLogItemComponent } from "./components/manage-SA-request-approve/manage-SA-request-approve-log-item/manage-SA-request-approve-log-item.component";
import { PopUpSaUpdateApproveComponent } from './components/pop-up-sa-update-approve/pop-up-sa-update-approve.component';
import { PopUpSaUpdateRejectComponent } from './components/pop-up-sa-update-reject/pop-up-sa-update-reject.component';
import { NotificationContainerComponent } from './views/notification-container/notification-container.component';
import { NotificationListComponent } from './components/notifications/notification-list/notification-list.component';
import { NotificationItemComponent } from './components/notifications/notification-item/notification-item.component';
import { PopUpNotificationRedirectErrorComponent } from './components/pop-up-notification-redirect-error/pop-up-notification-redirect-error.component';
import { ComplianceFilterComponent } from './components/filter-controls/compliance-filter/compliance-filter.component';
import { SingleDropdownComponent } from './components/single-dropdown/single-dropdown.component';
import { SupplierDuplicateCheckComponent } from './components/supplier-duplicate-check/supplier-duplicate-check.component';
import { PopUpBuyerTerminateReasonComponent } from './components/pop-up-buyer-terminate-reason/pop-up-buyer-terminate-reason.component';
import { HelpAndSupportDownloadComponent } from "./components/help-and-support-download/help-and-support-download.component";
import { DocumentManagementComponent } from './views/document-management/document-management.component';
import { PopUpUploadFileComponent } from './components/pop-up-upload-file/pop-up-upload-file.component';
import { SingleDropSelectComponent } from './components/single-drop-select/single-drop-select.component';
import { ConfirmationBoxComponent } from './components/confirmation-box/confirmation-box.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { SvEsgAssessmentComponent } from './components/sv-esg-assessment/sv-esg-assessment.component';
import { SupplierOnboardingComponent } from "./components/supplier-onboarding/supplier-onboarding.component";
import { AuthorityOnboardingComponent } from './components/authority-onboarding/authority-onboarding/authority-onboarding.component';
import { AuthorityEsgAssessmentComponent } from './components/authority-esg-assessment/authority-esg-assessment/authority-esg-assessment.component';
import { HelpAndSupportDynamicComponent } from "./views/help-and-support-dynamic/help-and-support-dynamic.component";
import { ActivityLogComponent } from "./views/activity-log/activity-log.component";
import { EmailTemplateManagerComponent } from './views/email-template-manager/email-template-manager.component';
import { EmailTemplateManagerLogItemComponent } from "./components/email-template-manager/email-template-manager-log-item/email-template-manager-log-item.component";
import { EmailTemplateManagerLogComponent } from "./components/email-template-manager/email-template-manager-log/email-template-manager-log.component";
import { PopUpTemplateEditComponent } from "./components/pop-up-template-edit/pop-up-template-edit.component";
import { PopUpTemplateDeleteComponent } from "./components/pop-up-template-delete/pop-up-template-delete.component";
import { SupplierEmailMaskPipe } from './views/activity-log/supplier-email-mask.pipe';
import { BvRiskAssessmentComponent } from './components/bv-risk-assessment/bv-risk-assessment.component';
import { PopUpRiskAssessmentComponent } from './components/pop-up-risk-assessment/pop-up-risk-assessment.component';
import { RiskAssessmentQuestionnaireComponent } from './components/risk-assessment-questionnaire/risk-assessment-questionnaire.component';
import { DialogService,  ConfirmService } from "@lsl16/sustainability-shared-components";
import { MatIconModule } from "@angular/material/icon";
import { RiskassessmentService } from "./services/riskassessment/riskassessment.service";
import {InherentQuestionnaireService} from "./services/inherentRiskQuestionnaire/inherentQuestionnaire.service";
import { GridTableComponent } from './components/grid-table/grid-table.component';
import { NewDashboardSearchComponent } from './views/new-dashboard-search/new-dashboard-search.component';
import { NewSearchComponent } from './components/new-search/new-search.component';
import { NewTerminationRequestLogComponent } from './components/new-search/new-termination-request-log/new-termination-request-log.component';
import { NewTerminationRequestLogItemComponent } from './components/new-search/new-termination-request-log-item/new-termination-request-log-item.component';
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgxPaginationModule } from 'ngx-pagination';
import { CreateBulkTsmProfilesComponent } from './views/create-bulk-tsm-profile-container/create-bulk-tsm-profiles/create-bulk-tsm-profiles.component';
import { CreateBulkProfileUploadComponent } from './views/create-bulk-tsm-profile-container/create-bulk-profile-upload/create-bulk-profile-upload.component';
import { BulkProfilesValidationListComponent } from './views/create-bulk-tsm-profile-container/bulk-profiles-validation-list/bulk-profiles-validation-list.component';
import { BulkProfilesValidationItemComponent } from './views/create-bulk-tsm-profile-container/bulk-profiles-validation-item/bulk-profiles-validation-item.component';
import { BulkProfilesProcessFlowComponent } from './views/create-bulk-tsm-profile-container/bulk-profiles-process-flow/bulk-profiles-process-flow.component';

import { FileUploadModule } from "ng2-file-upload";
import { BvRaRequestMessagePopUpComponent } from './components/bv-ra-request-message-pop-up/bv-ra-request-message-pop-up.component';
import { TsmIDComponent } from './components/filter-controls/tsm-id/tsm-id.component';
import { PopUpCustomGridTableComponent } from './components/pop-up-custom-grid-table/pop-up-custom-grid-table.component';
import { RaReportComponent } from './views/ra-report/ra-report.component';
import { RaReportFilterComponent } from './components/filter-controls/ra-report-filter/ra-report-filter.component'
import { CreationDateFilterComponent } from './components/filter-controls/creation-date-filter/creation-date-filter.component'
import { PopUpBibdaScoreComponent } from "./components/pop-up-bibda-score/pop-up-bibda-score.component";
import { RaReportPreviewComponent } from './views/ra-report-preview/ra-report-preview.component';
import { PopUpConsentRenewalComponent } from './components/pop-up-consent-renewal/pop-up-consent-renewal.component';
import { CompanyCategoryFilterComponent } from './components/filter-controls/company-category-filter/company-category-filter.component';
import { CompanySizeFilterComponent } from './components/filter-controls/company-size-filter/company-size-filter.component';
import { CompanySubcategoryFilterComponent } from './components/filter-controls/company-subcategory-filter/company-subcategory-filter.component';
import { SaRequestDateFilterComponent } from './components/filter-controls/sa-request-date-filter/sa-request-date-filter.component';
import { SaRequestTriggerDateFilterComponent } from './components/filter-controls/sa-request-trigger-date-filter/sa-request-trigger-date-filter.component';
import { SupplierScoreFilterComponent } from './components/filter-controls/supplier-score-filter/supplier-score-filter.component';
import { SaRetriggeringContainerComponent } from './views/sa-retriggering-container/sa-retriggering-container.component';
import { SaRetriggeringPreviewComponent } from './views/sa-retriggering-preview/sa-retriggering-preview.component';
import { SaRetriggeringProcessFlowComponent } from './views/sa-retriggering-process-flow/sa-retriggering-process-flow.component';
import { SaRetriggeringSearchComponent } from './views/sa-retriggering-search/sa-retriggering-search.component';
import { CreateMergeProfileComponent } from './views/create-merge-profile/create-merge-profile.component';
import { PopUpMergeProfileComponent } from './views/pop-up-merge-profile/pop-up-merge-profile.component';
import { SaRetriggerStatusContainerComponent } from './views/sa-retrigger-status-container/sa-retrigger-status-container.component';
import { SaRetriggerStatusPreviewComponent } from './views/sa-retrigger-status-preview/sa-retrigger-status-preview.component';
import { PopUpDuplicateComponent } from './components/pop-up-duplicate/pop-up-duplicate.component';
import { BvInherentRiskQuestionnaireComponent } from './components/bv-inherent-risk-questionnaire/bv-inherent-risk-questionnaire.component';
import { PopUpInherentRiskQuestionnaireComponent } from './components/pop-up-inherent-risk-questionnaire/pop-up-inherent-risk-questionnaire.component';
import { PopUpIrqBibdaScoreComponent } from './components/pop-up-irq-bibda-score/pop-up-irq-bibda-score.component';
import { LinkIrqToOnboardingComponent } from "./views/link-irq-to-onboarding/link-irq-to-onboarding.component";
import { QuestionnaireMaintenancePageComponent } from "./views/questionnaire-maintenance-page/questionnaire-maintenance-page.component";
function storeDeepLink() {
  const url = sessionStorage.getItem('deepLink');

  // don't override the storage, if an url is already defined there
  if (url) {
    return
  }

  sessionStorage.setItem('deepLink', window.location.href);
}

const getLoginHint = () => {
  let str = window.location.hash;
  str = str.substring(1, str.length).replace("/?", "");
  let arr = str.split("&");
  let obj = {};
  for (let i = 0; i < arr.length; i++) {
    let tmp_arr = arr[i].split("=");
    obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
  }
  return obj['login_hint'] ? obj['login_hint'] : 'test@test.com';
}

storeDeepLink();

const loginHint = getLoginHint();

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;


/******MSAL related start******/
export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.auth.susSite,
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      navigateToLoginRequestUrl: true,
      postLogoutRedirectUri: "/",
      knownAuthorities: [b2cPolicies.authorityDomain]
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(apiConfig.uri, apiConfig.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {

  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...apiConfig.scopes],
      loginHint: loginHint,
    }
  };
}

/******MSAL related end******/
@NgModule({ declarations: [
        AppComponent,
        LayoutComponent,
        DashboardComponent,
        CallbackComponent,
        SearchComponent,
        SupplierCardListComponent,
        PaginationBarComponent,
        ArrayPipe,
        HeaderComponent,
        FooterComponent,
        SubheaderComponent,
        CardComponent,
        BaseContainerComponent,
        PopUpComponent,
        PopUpBVComponent,
        SupplierActionSelectionComponent,
        OptionsCardComponent,
        OptionsCardStatusComponent,
        AddSupplierComponent,
        AddGeographyServedComponent,
        PopUpSelectCountryServedComponent,
        SustainabilityAssessmentNewComponent,
        PopUpCountryservedExistingComponent,
        SecondaryBannerComponent,
        TabBarComponent,
        ProfileDetailsComponent,
        PopUpWelcomeComponent,
        SustainabilityAssessmentComponent,
        SupplierProfileContainerComponent,
        BaseSupplierProfileComponent,
        SvSupplierProfileComponent,
        BvSupplierProfileComponent,
        SupplierAdminBoardComponent,
        SupplierUserProfileComponent,
        UserSecondaryBannerComponent,
        CreateNewUserComponent,
        LandingContainerComponent,
        supllierHelpAndSupportComponent,
        HtmlPipe,
        PermissionDirective,
        GdprDashboardComponent,
        AuthSupplierProfileComponent,
        PopUpDeleteUserComponent,
        DashboardSearchComponent,
        DashboardCardComponent,
        CarouselComponent,
        SustainabilityAssessmentReportComponent,
        SustainabilityAssessmentDownloadComponent,
        SystemAdminBoardComponent,
        SystemAdminBoardUsersComponent,
        SystemAdminBoardUserItemComponent,
        SubheaderMultipleDropdownComponent,
        SelectDropDownBoxComponent,
        SelectDropDownBoxAdminComponent,
        PopUpDisabledComponent,
        PopUpInactiveComponent,
        PopUpPromisionsupplierComponent,
        PopUpPromisionbuyerComponent,
        PopUpBvManagerequestComponent,
        ManageLogComponent,
        ManageLogItemComponent,
        AutofocusDirective,
        SystemAdminUserProfileComponent,
        SystemAdminUserProfileDetailComponent,
        Supplierhub2esgComponent,
        BuyerRelationshipComponent,
        ConfirmSupplierParentCreationComponent,
        SupplierUpdateSaComponent,
        MessagePopUpComponent,
        ScoringComponent,
        SupplierRelationshipComponent,
        SupplierParentDetailComponent,
        ConfirmParentComponent,
        PopUpExitAddsupplierComponent,
        ParentConfirmSupplierProfileComponent,
        ParentRelationshipActionComponent,
        PopUpParentRejectRelationshipComponent,
        PopUpPrivacyComponent,
        AddNewSupplierComponent,
        TerminationRequestBoardComponent,
        TerminationRequestLogComponent,
        TerminationRequestLogItemComponent,
        PopUpRejectComponent,
        PopUpTerminateComponent,
        UserProfileContainerComponent,
        UserProfileEditRolesComponent,
        UserProfileEditSaAccessComponent,
        SupplierAdminBoardContainerComponent,
        SubsidiarySupplierAdminBoardComponent,
        SubsidiarySupplierAdminBoardUsersComponent,
        SubsidiarySupplierAdminBoardUserItemComponent,
        BvPopUpTerminationComponent,
        BvPopUpSuccessComponent,
        CountryServedViewComponent,
        PopUpPossibleScenariosComponent,
        ConfirmWithdrawRelationshipRequestComponent,
        ParentSubsidiarySuppliersComponent,
        ParentSubsidiarySupplierItemComponent,
        ChartBarComponent,
        ScoreChartComponent,
        SectionPerformanceComponent,
        BvPopUpOnboardingComponent,
        PopUpCountryServedNotifyComponent,
        ConfirmCancelRelationshipRequestComponent,
        SpecifyParentComponent,
        SAScoringReportComponent,
        SupplierNameComponent,
        RequestorEmailComponent,
        MarketUnitFilterComponent,
        CountryServedFilterComponent,
        EsgStatusFilterComponent,
        RequestorPurposeFilterComponent,
        InternalPocEmailFilterComponent,
        SubmissionDateFilterComponent,
        RequestDateFilterComponent,
        DiligenceDateFilterComponent,
        SupplierCategoryFilterComponent,
        RecentParentComponent,
        ReportPreviewComponent,
        SaAndScoringReportDownloadButtonComponent,
        InviteParentSupplierComponent,
        PopUpConfirmRejectInviteSupplierComponent,
        ParentChildHistoryComponent,
        ParentChildHistoryItemComponent,
        TagComponent,
        GlobalParentDunsFilterComponent,
        BvEsgAssessmentComponent,
        BvNewSupplierProfileComponent,
        SubSuppliersComponent,
        SubSupplierItemComponent,
        AddSubSupplierComponent,
        RelationStatusComponent,
        InviteNewSupplierComponent,
        HistoricalSubSuppliersComponent,
        HistoricalSubSupplierItemComponent,
        PopUpSaStatusComponent,
        downloadReportDateFilterComponent,
        BvNewOnboardingComponent,
        SelectInputComponent,
        PopUpOnboardingComponent,
        PopUpStatusComponent,
        RequestMultipleSaContainerComponent,
        BaseSupplierFilterComponent,
        RequestMultipleSaSearchComponent,
        IncludeIndirectCheckboxFilterComponent,
        BvHelpSupportComponent,
        PopUpFaqComponent,
        MultipleSaUploaderComponent,
        RequestMultipleSaUploadComponent,
        SupplierValidationContainerComponent,
        SupplierValidationListComponent,
        SupplierValidationItemComponent,
        PopUpMassInviteComponent,
        RequestMultipleSaProcessFlowComponent,
        BulkProfilesProcessFlowComponent,
        PowerBiDashboardComponent,
        PowerBiMenuButtonComponent,
        ManageSAUpdatesApprovalsComponent,
        ManageSARequestApproveLogComponent,
        manageSARequestApproveLogItemComponent,
        PopUpSaUpdateApproveComponent,
        PopUpSaUpdateRejectComponent,
        NotificationContainerComponent,
        NotificationListComponent,
        NotificationItemComponent,
        popUpManageSARequestMessageComponent,
        PopUpNotificationRedirectErrorComponent,
        ComplianceFilterComponent,
        SingleDropdownComponent,
        SupplierDuplicateCheckComponent,
        PopUpBuyerTerminateReasonComponent,
        HelpAndSupportDownloadComponent,
        DocumentManagementComponent,
        PopUpUploadFileComponent,
        SingleDropSelectComponent,
        ConfirmationBoxComponent,
        UploadFileComponent,
        SvEsgAssessmentComponent,
        SupplierOnboardingComponent,
        AuthorityOnboardingComponent,
        AuthorityEsgAssessmentComponent,
        HelpAndSupportDynamicComponent,
        ActivityLogComponent,
        PopUpTemplateEditComponent,
        PopUpTemplateDeleteComponent,
        EmailTemplateManagerComponent,
        EmailTemplateManagerLogComponent,
        EmailTemplateManagerLogItemComponent,
        SupplierEmailMaskPipe,
        BvRiskAssessmentComponent,
        PopUpRiskAssessmentComponent,
        RiskAssessmentQuestionnaireComponent,
        GridTableComponent,
        NewDashboardSearchComponent,
        NewSearchComponent,
        NewTerminationRequestLogComponent,
        NewTerminationRequestLogItemComponent,
        CreateBulkTsmProfilesComponent,
        CreateBulkProfileUploadComponent,
        BulkProfilesValidationListComponent,
        BulkProfilesValidationItemComponent,
        BvRaRequestMessagePopUpComponent,
        TsmIDComponent,
        PopUpBibdaScoreComponent,
        PopUpIrqBibdaScoreComponent,
        LinkIrqToOnboardingComponent,
        RaReportComponent,
        RaReportFilterComponent,
        CreationDateFilterComponent,
        PopUpCustomGridTableComponent,
        RaReportPreviewComponent,
        PopUpConsentRenewalComponent,
        CompanyCategoryFilterComponent,
        CompanySizeFilterComponent,
        CompanySubcategoryFilterComponent,
        SaRequestDateFilterComponent,
        SaRequestTriggerDateFilterComponent,
        SupplierScoreFilterComponent,
        SaRetriggeringContainerComponent,
        SaRetriggeringPreviewComponent,
        SaRetriggeringProcessFlowComponent,
        SaRetriggeringSearchComponent,
        CreateMergeProfileComponent,
        PopUpMergeProfileComponent,
        SaRetriggerStatusContainerComponent,
        SaRetriggerStatusPreviewComponent,
        PopUpDuplicateComponent,
        BvInherentRiskQuestionnaireComponent,
        PopUpInherentRiskQuestionnaireComponent,
        QuestionnaireMaintenancePageComponent
            ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [BrowserModule,
        AppRoutingModule,
        NgbModule,
        FormsModule,
        MatIconModule,
        SustainabilitySharedComponentsModule.forRoot(environment),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        }),
        SusHubFrontendModule.forRoot(environment),
        MsalModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        FileUploadModule,
        NgxPaginationModule], providers: [
        ArrayPipe,
        AuthService,
        SubheaderService,
        CommonService,
        DialogService,
        ConfirmService,
        DynamicRaContext,
        DynamicRaComponentService,
        DictionaryRaService,
        RiskassessmentService,
        InherentQuestionnaireService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
        MsalService,
        MsalGuard,
        LoginGuard,
        MsalBroadcastService,
        PermissionDirective,
        CustomDatasources,
        CustomFilters,
        CustomValidators,
        DynamicComponentService,
        { provide: RouteReuseStrategy, useClass: AppRoutingCache },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
  constructor(
    private componentService: DynamicComponentService,
    customDatasources: CustomDatasources,
    customFilters: CustomFilters,
    customValidators: CustomValidators
  ) {
    this.componentService.registerDatasourceClass(customDatasources);
    this.componentService.registerCustomFilterClass(customFilters);
    this.componentService.registerCustomValidatorClass(customValidators);

    this.componentService.registerValidatorError(
      Validators.EMAIL_ALREADY_PRESENT,
      "The email is already in use for an existing point of contact."
    );

    this.componentService.registerValidatorError(
      Validators.IDENTICAL_DUNS_NUMBER,
      "Please specify a different D-U-N-S Number for parent or supplier company"
    );

    this.componentService.registerValidatorError(
      Validators.REQUIRED_TAX_ID,
      "Please enter tax id number for above tax type"
    );

    this.componentService.registerValidatorError(
      Validators.REQUIRED_TAX_TYPE,
      "Please select tax type for below tax id number"
    );

  }

}

export function httpTranslateLoader(http: HttpClient) {
  //  return new TranslateHttpLoader(http, 'http://localhost:3000/api', '/en')
  return new TranslateHttpLoader(http);
}
