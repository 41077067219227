import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import * as LDClient from "launchdarkly-js-client-sdk";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LaunchDarklyService {

  public static LD_ISMIXCATEGORY = "is-mix-category";
  private static TOGGLE_KEY_PREFIX = "LD_";

  public ldSubject = new Subject<string>();
  public ldChange = this.ldSubject.asObservable();


  constructor() {

  }

  private getActualKey(name: string) {
    return LaunchDarklyService.TOGGLE_KEY_PREFIX + name;
  }

  private triggerRefresh() {
    this.ldSubject.next('REFRESH');
  }

  public getToggle(name: string) {
    return localStorage.getItem(this.getActualKey(name));
  }


  public setToggle(name: string, value: string) {
    localStorage.setItem(this.getActualKey(name), value);
  }

  public initLaunchDarkly(kind: string, key: string, name: string) {
    // Set clientSideID to your LaunchDarkly client-side ID
    try{
      const clientSideID =`${environment.ldClientId}`;
      // Set up the context properties. This context should appear on your
      // LaunchDarkly contexts dashboard soon after you run the demo.
      const context = {
        kind: kind,
        key: key,
        name: name
      };
      const ldClient = LDClient.initialize(clientSideID, context);
      // console.log('client id', clientSideID);
      ldClient.on('initialized', (value) => {
        console.log("initialized---")

      });
      ldClient.on('failed', (err) => {
        console.log("failed---",err)
        
      });
      ldClient.on('ready', async (value) => {

        const flagValueMixCategory = await ldClient.variation(LaunchDarklyService.LD_ISMIXCATEGORY, false);
        this.setToggle(LaunchDarklyService.LD_ISMIXCATEGORY, flagValueMixCategory);
        this.triggerRefresh()
      
      });

      ldClient.on("change", (changes) => {
        Object.keys(changes).forEach((flagName) => {
            this.setToggle(flagName, changes[flagName].current);
            this.triggerRefresh();
        });
      });        


    }catch(error){
      console.log("Error happened in initLaunchDarkly",error);
    }
  }

}
