import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'tsm-requestor-email',
  templateUrl: './requestor-email.component.html',
  styleUrls: ['./requestor-email.component.sass']
})
export class RequestorEmailComponent implements OnInit {
  @Output() valueChanged = new EventEmitter();
  @Input() clearFilter: Subject<boolean>;
  @Input() invalidValue: Subject<string>;
  @Output() emailError = new EventEmitter<boolean>();

  requestorEmail = '';
  invalid = false;

  labelTranslations = {
    controlLabel: '',
    inputPlaceholder: '',
    invalidMsg: ''
  }
  private emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  invalidEmail:boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.clearFilter.subscribe(() => this.clear());
    this.invalidValue.subscribe((event) => this.handleInvalid(event));
  }
  inputChanged() {
    this.invalid = false;
    this.validateEmail();
    this.valueChanged.emit(this.requestorEmail);
    let printablevalue= this.requestorEmail;
    printablevalue=printablevalue.replace(/[\x00-\x1F]+/g, ' ');
    this.requestorEmail = printablevalue; 
  }

  validateEmail(){
    if(this.requestorEmail){
      const isValid = this.emailRegex.test(this.requestorEmail);
      if(isValid){
        this.invalidEmail = false;
        this.emailError.emit(false);
      }
      else{
        this.invalidEmail = true;
        this.emailError.emit(true);
      }
    }
    else{
      this.invalidEmail = false;
        this.emailError.emit(false);
    }
  }

  clear() {
    this.invalid = false;
    this.requestorEmail = '';
    this.emailError.emit(false);
    this.invalidEmail = false;
  }

  handleInvalid(event) {
    if (event === 'requestorEmail')
      this.invalid = true;
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.labelTranslations = multilanguageJson.body.main.reportFilters.requestorEmail;
      }
    }
  }
}
