import { Subject } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CountryService } from '@lsl16/sustainability-shared-components';

@Component({
  selector: 'tsm-country-served-filter',
  templateUrl: './country-served-filter.component.html',
  styleUrls: ['./country-served-filter.component.sass']
})
export class CountryServedFilterComponent implements OnInit {

  @Output() valueChanged = new EventEmitter();
  @Input() clearFilter: Subject<boolean>;
  @Output() dropdownError = new EventEmitter<boolean>();

  values: any[];
  countries: any[] = [];

  labelTranslations = {
    controlLabel: '',
    inputPlaceholder: ''
  }

  constructor(private countryService: CountryService) { }

  async ngOnInit(): Promise<void> {
    await this.loadCountryList()
    this.clearFilter.subscribe(() => this.clear());
  }

  async loadCountryList() {
    const response = await this.countryService.getActiveCountryData();
    this.filterCountry(response);
  }

  filterCountry(servedData) {
    servedData.forEach(element => {
      let state = element.countryServedEnable;
      if (state == true) {
        let country = {
          'Code': element.tsmCode,
          'Text': element.countryServedName
        };
        this.countries.push(country);
      }
    });
    this.countries.sort(function (obj1, obj2) {
      var val1 = obj1.Text;
      var val2 = obj2.Text;
      if (val1 > val2) {
        return 1;
      } else if (val1 < val2) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  dropdownChanged(selectedValues) {
    this.values = selectedValues;
    this.valueChanged.emit(this.values);
  }

  clear() {
    this.values = [];
    this.countries.forEach(country => {
      country.checked = false;
    });
  }
  onDropdownError(hasError: boolean) {
    this.dropdownError.emit(hasError);
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.labelTranslations = multilanguageJson.body.main.reportFilters.countryServed;
      }
    }
  }
}
