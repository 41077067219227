<h2>List of Onboardings</h2>
<div class="loadEffect" style="position: fixed;left: 50%;top:30%;z-index: 1000;" *ngIf="loading">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
</div>
<div class="record mb-12 shadow rounded-0 position-relative border onboarding-display" *ngIf="onboardingResults && onboardingResults.length > 0; else noRecordsMsg">
    <div class="onboarding-table">
            <div class="onboarding-table-scrollable" >
            <div class="row mt-3 ms-3 table-header">
            <div class="col-common  col-sno table-overflow">S. NO</div>
            <div class="col-common col-others table-overflow">CATEGORY</div>
            <div class="col-common col-others table-overflow">SUBCATEGORY</div>
            <div class="col-common col-others table-overflow">MARKET UNIT</div>
            <div class="col-common col-others table-overflow">ONBOARDING COUNTRY</div>
            <div class="col-common col-others table-overflow">SUPPLIER CODE</div>
            <div class="col-common col-others table-overflow">ACTION</div>
        </div>
        <div class="table-overflow">
            <div>
                <div tabindex="0" class="row mt-3  ms-3" *ngFor="let record of onboardingResults; let i = index">
                    <div class="col-common col-sno col-font-common">
                        <div class="text-overflow" placement="bottom-right" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="sno">
                            {{ i+1 }}
                        </div>
                        <ng-template #sno>
                            <div class="tip-inner-content">
                                {{ i+1 }}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-common col-others col-font-common">
                        <div class="text-overflow" placement="bottom-right" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="category">
                            {{record.category_name}}
                        </div>
                        <ng-template #category>
                            <div class="tip-inner-content">
                                {{record.category_name}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-common col-others col-font-common">
                        <div class="text-overflow" placement="bottom-right" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="subcategory">
                            {{record.subcategory_name}}
                        </div>
                        <ng-template #subcategory>
                            <div class="tip-inner-content">
                                {{record.subcategory_name}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-common col-others col-font-common">
                        <div class="text-overflow" placement="bottom-right" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="marketUnit">
                            {{record.market_unit_name}}
                        </div>
                        <ng-template #marketUnit>
                            <div class="tip-inner-content">
                                {{record.market_unit_name}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-common col-others col-font-common">
                        <div class="text-overflow" placement="bottom-right" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="onboardingCountry">
                            {{record.setupcountry}}
                        </div>
                        <ng-template #onboardingCountry>
                            <div class="tip-inner-content">
                                {{record.setupcountry}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-common col-others col-font-common">
                        <div class="text-overflow" placement="bottom-right" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="supplierCode">
                            {{record.suppliercode}}
                        </div>
                        <ng-template #supplierCode>
                            <div class="tip-inner-content">
                                {{record.suppliercode}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-common col-others a-button">
                        <a class="" (click)="linkIrqToOnboarding(record)">Link</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<ng-template #noRecordsMsg>
    <div class="no-records" *ngIf="!loading">
        <p>No records found.</p>
    </div>
</ng-template>