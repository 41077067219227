import { isEmpty } from "lodash";
import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { CriticalOrInvalidAnswer } from '@lsl16/sushub-frontend/lib/services/review/assessment-review.service';
import { AuthService } from "src/app/services/auth/auth.service";
import { HeaderEvents, HeaderService } from "src/app/services/header/header-service";

@Component({
    selector: 'tsm-scoring',
    templateUrl: './scoring.component.html',
    styleUrls: ['./scoring.component.sass']
})
export class ScoringComponent implements OnInit, OnChanges {
    @Input() reviewData: any;

    public translations = {
        dueDiligenceSummary: "",
        score: "",
        overallScore: "",
        noCriticalGapsMsg: "",
        improvementRequired: "",
        compliant: "",
        questionLabels: {}
    };
    public criticalAnswers: CriticalOrInvalidAnswer[] = [];
    public sections = [];
    public performanceStatus: string;
    public userType: string;
    public overallScoreVisible: boolean = false;

    sectionTitlesMap = {
        gettingStarted: "Getting Started",
        Environment: "Environment",
        "Human Social": "Human Rights & Social Impact",
        Supplier: "Supplier Inclusion & Diversity",
        esMgtConfig: "Ethics & Sustainability Management"
    };

    constructor(private authService: AuthService, private headerService: HeaderService) { }

    async ngOnInit(): Promise<void> {
        this.userType = this.authService.getRole();
        this.refreshData();
        this.subscribeEvents();
    }

    loadTranslations() {
        if (localStorage.getItem('multiLanguage')) {
            const multilanguageJson = JSON.parse(localStorage.getItem('multiLanguage'));
            if (multilanguageJson.questionnire) {
                this.translations = multilanguageJson.questionnire.Scoring;
                this.translations = { ...this.translations, ...multilanguageJson.questionnire.title };
                const questionLabels = this.getQuestionnaireLabels(multilanguageJson.questionnire);
                this.translations.questionLabels = questionLabels;
            }
        }
    }

    private getQuestionnaireLabels(questionnire) {
        const labels = {};
        for (const secId of Object.keys(this.sectionTitlesMap)) {
            const sectionName = this.sectionTitlesMap[secId];
            if (!questionnire[sectionName]) { continue; }
            Object.assign(labels, questionnire[sectionName]);
        }
        return labels;
    }

    private getCriticalAnswers(questions) {
        if (isEmpty(questions)) {
            return [];
        }
    
        const criticalQuestions = [];
        for (const questionId of Object.keys(questions)) {
            const question = questions[questionId];
            if (!question.critical) { continue; }

            criticalQuestions.push({
                question: this.translations.questionLabels[questionId].label,
                response: this.translations.questionLabels[questionId].data[question.answer],
                comment: question.comments
             });
        }

        return criticalQuestions;
    }
    
    private getSectionsPerformance(sectionsData) {
        if (isEmpty(sectionsData)) {
            return [];
        }

        const result = [];
        for (const sectionName of Object.keys(this.sectionTitlesMap)) {
            if (!sectionsData[sectionName] || sectionName === "gettingStarted") { continue; }

            const section = sectionsData[sectionName];
            const percentage = Math.round((section.score / section.maximum) * 100);
            result.push({
                name: this.translations[this.sectionTitlesMap[sectionName]],
                score: isNaN(percentage) ? 0 : percentage,
                sectionMaximum: section.maximum
             });
        }

        return result;
    }

    private refreshData() {
        this.overallScoreVisible = this.userType === 'authority' || this.authService.isBuyerExpert();
        this.loadTranslations();
        this.criticalAnswers = this.getCriticalAnswers(this.reviewData?.questions);
        this.sections = this.getSectionsPerformance(this.reviewData?.score?.sections);
        this.performanceStatus = (this.criticalAnswers.length) > 0 ? this.translations.improvementRequired : this.translations.compliant;
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        if (changes["reviewData"]) {
            this.refreshData();
        }
    }

    private subscribeEvents() {
        this.headerService.eventEmit.subscribe((event) => {
            if (event === HeaderEvents.multiLanguageChanged) {
                this.refreshData();
            }
        });
    }
}
