import { FilterData, ReportType, EsgReportService, ReportPreview, TimeoutError } from '../../services/esgReport/esg-report.service';
import { EventEmitter, Component, Input, Output, OnInit } from '@angular/core';
import { each } from 'lodash'

export type RequestStatusEvent = {
  status: "Error" | "Success";
}

export type ApplyFilterEvent = {
  reportType: ReportType;
  requestType?: any;
  filters: FilterData[];
}


type PreviewState = 'initial' | 'NoRecordsFound' | 'RecordsFound' | 'TimeoutError';

@Component({
  selector: 'tsm-report-preview',
  templateUrl: './report-preview.component.html',
  styleUrls: ['./report-preview.component.sass']
})
export class ReportPreviewComponent implements OnInit {
  @Input() applyFilterEvent: EventEmitter<ApplyFilterEvent>;
  @Output() requestStatusEventEmitter = new EventEmitter<RequestStatusEvent>(true);
  public reportPreview: ReportPreview;
  public filters: FilterData[] = [];
  public previewState: PreviewState = 'initial';
  public statusLiterals: any;
  public reportType: ReportType;

  public countInfoLabel = "";

  public translatedLabels = {
    noSearchMessage: "",
    emptySearchMessage: "",
    countInfoMessage: "",
    timeoutErrorMessage: "",
  }
  constructor(private reportService: EsgReportService) { }

  ngOnInit(): void {
    if (this.applyFilterEvent) {
      this.applyFilterEvent.subscribe(next => this.handleApplyFilterEvent(next));
    }
  }

  async fetchPreviewRecords(filterEvent: ApplyFilterEvent) {
    try {
      this.reportPreview = await this.reportService.queryReport(filterEvent.reportType, filterEvent.filters);

      this.handleReportPreview(this.reportPreview);

      this.requestStatusEventEmitter.emit({ status: 'Success' });
    }
    catch (error) {
      this.requestStatusEventEmitter.emit({ status: 'Error' });
      if (error instanceof TimeoutError) {
        this.previewState = 'TimeoutError';
      }
      console.error(`Failed to fetch report preview: ${error}`);
    }
  }

  private handleApplyFilterEvent(event: ApplyFilterEvent) {
    this.filters = event.filters;
    this.reportType = event.reportType;
    this.fetchPreviewRecords(event);
  }

  handleReportPreview(reportPreview: ReportPreview) {
    reportPreview = this.formatEsgStatus(reportPreview);
    reportPreview = this.formatDates(reportPreview);

    this.reportPreview = reportPreview;
    this.countInfoLabel = this.translatedLabels.countInfoMessage.replace("${totalCount}", reportPreview.rowcount.toString());

    if (reportPreview.rowcount != 0) {
      this.previewState = 'RecordsFound';
    } else {
      this.previewState = 'NoRecordsFound';
    }
  }

  private formatDates(reportPreview: ReportPreview): ReportPreview {
    each(reportPreview.columns, (col, i) => {
      if (col.colType === 'Date') {
        this.reportPreview.rows.forEach((row) => {
          row[i] = row[i].split('T')[0];
        });
      }
    });

    return reportPreview;
  }

  private formatEsgStatus(preview: ReportPreview): ReportPreview {
    const esgStatusIndex = preview.columns.findIndex(col => col.id === "esgStatus");

    if (esgStatusIndex < 0) {
      return preview;
    }

    preview.rows.forEach(row => {
      if (row.length > esgStatusIndex) {
        row[esgStatusIndex] = row[esgStatusIndex].toLowerCase();
        if (row[esgStatusIndex] && this.statusLiterals && this.statusLiterals[row[esgStatusIndex]]) {
          row[esgStatusIndex] = this.statusLiterals[row[esgStatusIndex]];
        }
      }
    });

    return preview;
  }

  ngAfterContentChecked() {
    if (localStorage.getItem("multiLanguage")) {
      const multiLanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      if (multiLanguageJson.body != undefined) {
        this.translatedLabels = multiLanguageJson.body.main.saReportPreview;
      }
      if (multiLanguageJson && multiLanguageJson.questionnire && multiLanguageJson.questionnire.reviewSummary) {
        if (multiLanguageJson.questionnire.reviewSummary.statusLiterals) {
          this.statusLiterals = multiLanguageJson.questionnire.reviewSummary.statusLiterals;
        }
      }
    }
  }
}
