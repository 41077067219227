import _ from 'lodash';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpService } from '@lsl16/sustainability-shared-components';
import { Subject } from 'rxjs';
import * as FileSaver from 'file-saver';
import { XmlConversionWorkerService } from "../xmlConversionWorker/xml-conversion-worker.service";
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { HttpClient } from "@angular/common/http";
import { saveAs } from "file-saver";

const allowedFilterTypes = ['supplierName', 'globalUltimateParentDUNS', 'countryServed', 'marketUnit', 'requestDateFrom', 'requestDateTo', 'requestPurpose', 'internalPoCEmail', 'diligenceEndDateFrom', 'diligenceEndDateTo', 'requestorEmail', 'esgSubmissionDateFrom', 'esgSubmissionDateTo', 'esgStatus', 'includeIndirect', 'supplierCategory', 'includeCompliant','supplierTsmId','categories','subcategories', 'creationDate', 'raStatus', 'biBdaStatus', 'infoSecScore', 'dataPrivacyScore', 'includeRaQuesAns','companyCategory','companySize','companySubCategory','supplierScore','saRequestDate','creationDateFrom','creationDateTo','lastReminderSent'] as const;
export type ReportType = 'sa_scoring' | 'supplier_records' | 'ra_report';
export type ColumnType = 'String' | 'Number' | 'Date' | 'Boolean';
export type FilterType = typeof allowedFilterTypes[number];
export type ColumnId = "supplierTsmId" | "supplierName" | "Indirect" | "supplierCategory" | "marketUnit" | "countryServed" | "esgStatus" | "esgSubmissionDate";
export type filterModes = "multiSa";
 
export type FilterValue =
  | string
  | number
  | Date
  | boolean
  | string[]
  | number[]
  | Date[]
  | boolean[];
 
export type fromToDateFilter = {
  from: FilterType;
  to: FilterType;
};
 
function isReportPreview(obj: any): obj is ReportPreview {
  const preview = obj as ReportPreview;
  return (
    preview.columns !== undefined &&
    preview.rowcount !== undefined &&
    preview.rows !== undefined
  );
}
 
export class TimeoutError extends Error {
  constructor(msg: string) {
    super(msg);
 
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, TimeoutError.prototype);
  }
}
 
export type ReportPayload = {
  reportType: ReportType;
  mode: 'QUERY' | 'GENERATE';
  filters: FilterData[];
};
 
export type FilterData = {
  filterName: FilterType;
  filterValue: FilterValue;
};
 
export type ReportPreview = {
  rowcount: number;
  columns: ReportColumn[];
  rows: string[][];
};
 
export type ReportColumn = {
  id: ColumnId;
  label: string;
  colType: ColumnType;
  width: string;
};
 
@Injectable({
  providedIn: 'root',
})
export class EsgReportService {
  private errorEventSubject = new Subject<string>();
  public getcompanyCategorySubject = new Subject<any>();
  private fileName = "";
  private downloadUrl = "";
 
  constructor(
    private httpService: HttpService,
    private http: HttpClient,
    private xmlConversion: XmlConversionWorkerService
  ) { }
 
  /* istanbul ignore next - no auts for getters*/
  public getErrorEvent(): Observable<string> {
    return this.errorEventSubject.asObservable();
  }
 
  public getCompanyCategory(): Observable<any> {
    return this.getcompanyCategorySubject.asObservable();
  }
 
  public async queryReport(
    reportType: ReportType,
    filters: FilterData[]
  ): Promise<ReportPreview> {
    const url = `${environment.tsmReportServiceURL}/report/getReport`;
 
    const payload: ReportPayload = {
      reportType: reportType,
      mode: 'QUERY',
      filters: filters.filter((f) => this.isValidValueForQuery(f.filterValue)),
    };
 
    const response = await this.httpService.PostPromiseHttpResponse<HttpResponse<any> | HttpErrorResponse>(url, payload);
    const responseBody = response['body'];
 
    if (!isReportPreview(responseBody)) {
      this.handleError(response);
    } else {
      responseBody.columns.forEach((col) => {
        col.label = col.label.toUpperCase();
      });
      return responseBody;
    }
  }
  public async generateRaReport(payload: any) {
    const url = `${environment.tsmBackendServiceURL}/riskAssessmentData/getRiskAssessmentDBReport`;
    const response = await this.httpService.PostPromiseHttpResponse<HttpResponse<any> | HttpErrorResponse>(url, payload);
    const responseBody = response['body'];
 
    if (!responseBody) {
      this.handleError(response);
    } else {
      return responseBody;
    }
  }
  downloadRaReport = async (payload, downloadReport): Promise<any> => {
    payload['downloadReport'] = downloadReport;
    try {
      this.fileName = "RiskAssessmentReport" + '.xlsx' ;
      this.downloadUrl = `${environment.tsmBackendServiceURL}/riskAssessmentData/getRiskAssessmentDBReport`;
      const file: any = await this.http.post(this.downloadUrl, payload, { responseType: "blob" }).toPromise();
      if(file == undefined) {
        console.log('Exception in download')
        return;
      }
      const blob = new Blob([file], { type: 'application/vnd.oasis.opendocument.spreadsheet' });
      saveAs(blob, this.fileName);
    } catch (e) {
      //don't do logs in the frontend, but in future this is where we could put some logic to tell the user their download failed via UI
      console.log(e);
    }
  };
 
  public async generateReport(reportType: ReportType, filters: FilterData[]) {
    const url = `${environment.tsmReportServiceURL}/report/getReport`;
 
    const payload: ReportPayload = {
      reportType: reportType,
      mode: 'GENERATE',
      filters: filters.filter((f) => this.isValidValueForQuery(f.filterValue)),
    };
 
    const response = await this.httpService.PostPromiseHttpResponse<HttpResponse<any> | HttpErrorResponse>(url, payload);
    const responseBody = response['body'];
 
    const isResponseValid = responseBody.hasOwnProperty('Workbook');
    if (!isResponseValid) {
      this.handleError(response);
    } else {
      const result = await this.xmlConversion.convertJsonToXmlString(responseBody);
      const blob = new Blob([result], { type: 'application/vnd.oasis.opendocument.spreadsheet' });
      FileSaver.saveAs(blob, 'Report_' + reportType + '.xml');
    }
  }
 
  private handleError(response: HttpErrorResponse | HttpResponse<HttpResponse<any> | HttpErrorResponse>) {
    let errorMessage = `Your request couldn't be completed. Please try again in a few minutes.`;
 
    if (response['body']?.hasOwnProperty('error')) {
      const errorType = response['body']['error'];
 
      if (errorType === 'TIMEOUT') {
        errorMessage = `Report request timed out.`;
      }
      else if (errorType === 'TOO_MANY_RECORDS') {
        errorMessage = `Endpoint identified too many records.`;
      }
      else {
        errorMessage = response['body']['msg'] || response['body']['message'];
      }
      throw new TimeoutError(errorMessage);
    }
 
    this.errorEventSubject.next(errorMessage);
    throw new Error(`An error occurred while calling the endpoint: ${JSON.stringify(response)}`);
  }
 
  private isValidValueForQuery(value?: FilterValue): boolean {
    if (value === null || value === undefined) {
      return false;
    }
 
    if (Array.isArray(value) && value.length === 0) {
      return false;
    }
 
    return true;
  }
 
  public async filteredReTriggeringSA(payload: any) {
    const url = `${environment.tsmBackendServiceURL}/supplierUpdateSA/filteredReTriggeringSA`;
    
    const response = await this.httpService.PostPromiseHttpResponse<HttpResponse<any> | HttpErrorResponse>(url, payload);
    const responseBody = response['body'];
    for (let i = 0; i <responseBody.length ; i++) {
        if(responseBody[i].esgStatus ==="awaiting acceptance"){
          responseBody[i].esgStatus = "Awaiting acceptance"}
        else if(responseBody[i].esgStatus ==="awaiting submission")
          responseBody[i].esgStatus = "Awaiting submission"
      }
    
    if (!responseBody) {
      this.handleError(response);
    } else {
      return responseBody;
    }
  }

  public async reTriggeringSA(payload: any): Promise<any> {
    const url = `${environment.tsmBackendServiceURL}/supplierUpdateSA/reTriggeringSA`;
     return await this.httpService.PostPromise(url, payload);  
  }

  public async reminderingSA(payload: any): Promise<any> {
    const url = `${environment.tsmBackendServiceURL}/supplierUpdateSA/saReminderRetrigger`;
     return await this.httpService.PostPromise(url, payload);  
  }

  public async getReTriggeringSA(reTriggerId: any): Promise<any> {
    const url = `${environment.tsmBackendServiceURL}/supplierUpdateSA/getReTriggeredSA/${reTriggerId}`;
     return await this.httpService.GetPromise(url);  
  }
}