
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { isNgbDateStruct } from 'src/app/models/Search';

export class BaseDateFilterComponent {
    
    errorMessage:object ={
        invalid: "Please enter a valid date",
        futuredate:"Enter a date lesser than or equal to today's date",
      }

    isDateValid(date) {

        if (date) {
            let result = this.validatingDate(date);
            if (result) {
                let isfuture = this.checkingFutureDate(date);

                if (!isfuture) { 
                    return "valid"; 
                } else { 
                    return "future"; 
                }

            } else {
                return "invalid";
            }
        } else { 
            return "valid";
        }

    }

    validatingDate(date) {
        if (date && isNgbDateStruct(date)) {
            if (!this.isValidNgbDateStruct(date)) {
                return false;
            }
            return true;
        }
        return false;
    }

    checkingFutureDate(date) {
        const currDate: any = new Date();
        const dateFromAsDate = new Date(date.year, date.month - 1, date.day);
        if (dateFromAsDate > currDate) {
            return true
        }
        return false;
    }

    isValidNgbDateStruct(date: NgbDateStruct): boolean {
        const { year, month, day } = date;

        // Validate year
        if (year < 1900 || year > 9999) return false;

        // Validate month
        if (month < 1 || month > 12) return false;

        // Validate day (considering month and leap years)
        const daysInMonth = this.getDaysInMonth(month, year);
        if (day < 1 || day > daysInMonth) return false;

        return true;
    }

    // Helper method to get the number of days in a month, considering leap years
    getDaysInMonth(month: number, year: number): number {
        return new Date(year, month, 0).getDate();
    }

}