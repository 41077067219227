import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'tsm-internal-poc-email-filter',
  templateUrl: './internal-poc-email-filter.component.html',
  styleUrls: ['./internal-poc-email-filter.component.sass']
})
export class InternalPocEmailFilterComponent implements OnInit {
  @Output() valueChanged = new EventEmitter();
  @Input() clearFilter: Subject<boolean>;
  @Input() invalidValue: Subject<string>;
  @Output() emailError = new EventEmitter<boolean>();

  internalPocEmail = '';
  invalid = false;

  labelTranslations = {
    controlLabel: '',
    inputPlaceholder: '',
    invalidMsg: ''
  }

  private emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  invalidEmail:boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.clearFilter.subscribe(() => this.clear());
    this.invalidValue.subscribe((event) => this.handleInvalid(event));
  }
  inputChanged() {
    this.invalid = false;
    this.validateEmail();
    this.valueChanged.emit(this.internalPocEmail);
    let printablevalue= this.internalPocEmail;
    printablevalue=printablevalue.replace(/[\x00-\x1F]+/g, ' ');
    this.internalPocEmail = printablevalue; 
  }

  validateEmail(){
    if(this.internalPocEmail){
      const isValid = this.emailRegex.test(this.internalPocEmail);
      if(isValid){
        this.invalidEmail = false;
        this.emailError.emit(false);
      }
      else{
        this.invalidEmail = true;
        this.emailError.emit(true);
      }
    }
    else{
      this.invalidEmail = false;
        this.emailError.emit(false);
    }
  }
  clear() {
    this.invalid = false;
    this.internalPocEmail = '';
    this.emailError.emit(false);
    this.invalidEmail= false;
  }
  handleInvalid(event) {
    if (event === 'internalPoCEmail')
      this.invalid = true;
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.labelTranslations = multilanguageJson.body.main.reportFilters.internalPocEmail;
      }
    }
  }
}
