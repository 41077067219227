<h1 class="center-text">Questionnaire Maintenance Page</h1>
<div class="container">
  <div class="row">
    <div class="form-group">
      <div class="col-6 col-md-4">
        <label for="section-select">Action</label>
        <select id="section-select" [(ngModel)]="selectedAction" (change)="onActionChange()">
          <option value="" disabled selected *ngIf="!selectedAction">Select an action</option> 
           <option value="" disabled selected hidden>Select an action</option>
          <option value="add">1. Add a new question</option>
          <option value="update">2. Update an existing question</option>
        </select>
      </div>
    </div>
  </div>

  <!-- Add new question section -->
  <div *ngIf="selectedAction === 'add'">
    <h1 class="center-text">1. Add a new question</h1>
    <div class="container">
      <div class="row">
        <div class="col-6 col-md-4">
          <div class="form-group">
            <label for="section-select">Section</label>
            <select id="section-select" [(ngModel)]="selectedSection">
              <option value="" disabled selected *ngIf="!selectedSection">Select a section</option> 
              <option *ngFor="let section of sections" [value]="section.label">{{ section.label }}</option>
            </select>
          </div>
        </div>
        <div class="col-6 col-md-4">
          <div class="form-group">
            <label for="type-select">Type</label>
            <select id="type-select" [(ngModel)]="selectedType">
              <option value="" disabled selected *ngIf="!selectedType">Select a type</option> 
               <option *ngFor="let item of types" [value]="item.type">
                {{ item.type }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="label-input">Label</label>
            <input id="label-input" type="text" [(ngModel)]="label" placeholder="Enter Label here">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="sublabel-input">Sub Label (optional)</label>
            <input id="sublabel-input" type="text" [(ngModel)]="subLabel" placeholder="Enter Sub Label here">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-4">
          <div class="form-group">
            <label for="required-select">Required</label>
            <select id="required-select" [(ngModel)]="selectedRequired">
              <option value="null" disabled selected *ngIf="!selectedRequired">Select an option</option> 
              <option [value]="true">true</option>
              <option [value]="false">false</option>
            </select>
          </div>
        </div>
        <div class="col-6 col-md-4">
          <div class="form-group">
            <label for="question-name-input">Question Name</label>
            <input id="question-name-input" type="text" [(ngModel)]="questionName" placeholder="Enter a name">
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-4">
            <div class="form-group">
              <label for="question-name-input">Tracking ID</label>
              <input id="question-name-input" type="text" [(ngModel)]="trackingID" placeholder="Enter the tracking">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right">
          <button class="btn btn-secondary" (click)="onCancel()">Cancel</button>
          <button class="btn btn-primary" (click)="onSubmit()">Submit</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Update an existing question section -->
  <div *ngIf="selectedAction === 'update'">
    <h1 class="center-text">2. Update an Existing Question</h1>
    <div class="container">
      <div class="row">
        <div class="col-6 col-md-4">
          <div class="form-group">
            <select id="section-select" [(ngModel)]="selectedTrackingId">
              <option value="" disabled selected *ngIf="!selectedTrackingId">Select Tracking ID</option>
              <option *ngFor="let trackingId of trackingIds" [value]="trackingId">
                {{ trackingId }}
              </option>
            </select>
            </div>
        </div>
        <div class="col-6 col-md-4">
          <button (click)="searchSection()">Search</button>
        </div>
      </div>
      <div *ngIf="showFormFields">
        <div class="row">
          <div class="col-6 col-md-4">
            <div class="form-group">
              <label for="section-select">Section</label>
              <select id="section-select" [(ngModel)]="updatedSectionDetails.e_sect_name">
                <option value="" disabled selected *ngIf="!updatedSectionDetails.e_sect_name">
                  Select a section
                </option>
                <option *ngFor="let section of uniqueSections" [value]="section.e_sect_name">
                  {{ section.e_sect_name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-6 col-md-4">
            <div class="form-group">
              <label for="type-select">Type</label>
              <select id="type-select" [(ngModel)]="updatedType">
                <option value="" disabled selected *ngIf="!updatedType">Select a Type</option> 
                <option *ngFor="let item of types" [value]="item.type">
                  {{ item.type }}
                </option>
              </select>
              
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-2">
            <div class="form-group">
              <label for="label-input">Label</label>
              <input id="label-input" type="text" [(ngModel)]="updatedLabel" placeholder="Enter Label here">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-2">
            <div class="form-group">
              <label for="sublabel-input">Sub Label (optional)</label>
              <input id="sublabel-input" type="text" [(ngModel)]="updatedSubLabel" placeholder="Enter Sub Label here">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-4">
            <div class="form-group">
              <label for="required-select">Required</label>
              <select id="required-select" [(ngModel)]="selectedUpdatedRequired">
                <option [ngValue]="null" disabled selected>Select an option</option>
                <option [ngValue]="true">true</option>
                <option [ngValue]="false">false</option>
              </select>
            </div>
          </div>
          <div class="col-6 col-md-4">
            <div class="form-group">
              <label for="question-name-input">Question Name</label>
              <input id="question-name-input" type="text" [(ngModel)]="updatedquestionName" placeholder="Enter a name">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-right">
            <button class="btn btn-secondary" (click)="onCancelButton()">Cancel</button>
            <button class="btn btn-primary" (click)="onSubmitButton()">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
