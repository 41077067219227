import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AlertPropsType, ButtonPropsType, MultipleDropdownComponent } from "@lsl16/sustainability-shared-components";
import { SubheaderPropsType } from "../../models/SubheaderProps";
import { CategoryFilterType } from "../../models/Search";
import { SupplierGeneralProfileService } from "../../services/supplier-general-profile.service";
import { EsgQuestionnaireService, GeneralSupplierType } from "@lsl16/sushub-frontend";
import { AuthService } from "../../services/auth/auth.service";
import { LoginUser } from "../../models/LoginUser";
import { Popup } from "../../models/Popup";
import { fromEvent } from 'rxjs';
import { AppRoutingCache } from '../../app-routing.cache';
import { EventService } from "src/app/services/EventService.service";

@Component({
    selector: "tsm-dashboard-search",
    templateUrl: "./dashboard-search.component.html",
    styleUrls: ["./dashboard-search.component.sass"]
})
export class DashboardSearchComponent implements OnInit {
    @ViewChild("multipleDropDown") multipleDropDown: MultipleDropdownComponent;

    pageNumber: number = 1;
    totalNumberOfPages: number = 0;
    advSearchSearchButton: ButtonPropsType;
    advSearchCancelButton: ButtonPropsType;
    props: SubheaderPropsType;
    popData: Popup;
    suppliers: GeneralSupplierType[];
    categoryFilters: CategoryFilterType[];
    emptyResult: boolean;
    lastQuery: string;
    loading: boolean;
    esgEventEmitSubscription: Subscription;
    terminateOperationSubscription: Subscription;
    terminateSucessMessage = "Supplier is terminated."
    shouldShowSuccessTerminateAlert: boolean = false;
    submissionSuccess: boolean;
    submissionSuccessAlertProps;
    hiden: boolean = true;
    shown: boolean = false;
    subscribeScoll: any;
    successTerminateAlertProps: AlertPropsType;
    constructor( 
        private authService: AuthService,
        private router: Router,
        private supplierService: SupplierGeneralProfileService,
        private esgQuestionnaireService: EsgQuestionnaireService,
        private eventService: EventService
    ) {
        AppRoutingCache.getRouteText().subscribe(res => {
            if (res.url === '/dashboard-search' && res.type === 'attach') {
                setTimeout(() => {
                    if (sessionStorage.getItem('dashBoardSearchScrollTop') !== '0') {
                        window.scrollTo(document.body.scrollHeight,JSON.parse(sessionStorage.getItem('dashBoardSearchScrollTop')) || 0)
                        if (JSON.stringify(document.documentElement.scrollTop) === sessionStorage.getItem('dashBoardSearchScrollTop')) {
                            sessionStorage.setItem('dashBoardSearchScrollTop', '0')
                        }
                    }
                }, 0);
            } else {
                window.scrollTo(document.body.scrollHeight,0)
                let tsmRoot = <HTMLElement> document.getElementsByTagName("tsm-root")[0];
                if (tsmRoot) {
                    tsmRoot.style.background = "#f2f2f2";
                }
            }
          });
          this.terminateSubscription();
    }
    ngOnInit(): void {     
        this.subscribeScoll = fromEvent(window, 'scroll')
            .subscribe(() => {
                this.onWindowScroll(); 
            });
        this.setSubheaderProps();
    }    

    logout() {
        this.authService.logout();
    }

    get loginUser(): LoginUser {
        return this.authService.getLoginUser();
    }

    getRole(): string {
        return this.authService.getRole();
    }
    
    hide(){
        this.hiden = false;
        this.shown = false;
    }

    show(){
        this.hiden = true;
        this.shown = true;
    }
    /**
     * Description: Invoked when the child search component returns results.
     * If 'emptySearch' event is emitted, calls #getInitialListOfSuppliers.
     * fetches initial list of all suppliers without search filters based on page number.
     * Also retrieves total number of result pages for pagination bar.
     */
    handleSearchResults = (supplierSearchResults): void => {
        if (typeof supplierSearchResults === "object") {
            if (supplierSearchResults.type === "emptySearch") {
                this.suppliers = [];
                this.totalNumberOfPages = 0;
                this.resetPageNumber();
                this.resetEmptyResult();
            } else if (supplierSearchResults.type === "searchSuccess") {
                this.suppliers = supplierSearchResults.suppliers;
                this.suppliers.length === 0 ? this.handleEmptyResult() : this.resetEmptyResult();
                this.totalNumberOfPages = supplierSearchResults.pages === undefined ? 0 :  supplierSearchResults.pages;
            } else if (supplierSearchResults.type === "searchFailed") {
                this.suppliers = [];
                this.totalNumberOfPages = 0;
                this.resetPageNumber();
                this.resetEmptyResult();
            }
        }
    };

    /**
     * Description: Invoked when the child pagination bar component emits a page change event.
     * sets local pageNumber variable to page number emitted
     * @Params currentPage
     */
    handleCurrentPage = (currentPage: number): void => {
        this.pageNumber = currentPage;
    };
    changeCurrentPage = (page) => {
        this.handleCurrentPage(page)
    }
    /**
     * Description: Invoked each time the addSupplierEmitter is invoked
     */
    handleAddSupplier(): void {
        this.router.navigateByUrl("/add-supplier");
    }
    /**
     * Description: resets page number to 1. Invoked each time the user triggers a new search.
     */
    resetPageNumber = (): void => {
        this.pageNumber = 1;
    };

    /**
     * Description: updates the errorstate of the functiion to true when called
     */
    handleEmptyResult() {
        this.emptyResult = true;
        window.scrollTo(0, 0);
        let elements = <HTMLCollectionOf<HTMLElement>>document.getElementsByTagName("tsm-add-new-supplier");
        if (elements && elements.length > 0) {
            let element = <HTMLElement>elements[0].firstChild;
            if (element) {
                setTimeout(() => {
                    if (this.scollPostion().height < 790 || this.scollPostion().height > 950) {
                        element.style.bottom = "31px";
                    } else {
                        element.style.bottom = "103px";
                    }
                }, 1);
            }
        }
        this.subscribeScoll = fromEvent(window, 'scroll')
            .subscribe(() => {
                this.onWindowScroll();
            });
    }

    /**
     * Description: updates the errorstate of the functiion to false when called
     */
    resetEmptyResult() {
        this.emptyResult = false;
        window.scrollTo(0, 0);
        let elements = <HTMLElement>document.getElementsByTagName("tsm-add-new-supplier")[0].firstChild;
        if (elements) {
            elements.style.bottom = "31px";
        }
        if (this.getRole() === 'authority') {
            setTimeout(() => {
                let elementsPagination = <HTMLElement>document.getElementsByTagName("tsm-pagination-bar")[0];
                elementsPagination.style.marginBottom = "0px";
                elementsPagination.style.zIndex = "1000";
            }, 1);
        }
        this.subscribeScoll = fromEvent(window, 'scroll')
            .subscribe(() => {
                this.onWindowScroll();
            });
    }

    /**
     * Description: initialises subheader props
     */
    setSubheaderProps = (): void => {
        this.props = {
            isWelcomeMessage: true,
            headingPrefix: "new suppler invitation",
            headingPrefixColor: "purple",
            heading: this.loginUser.name,
            tag: "TECHNOLOGY BUYER"
        };
    };

    /**
     * Description: Subscribes to #eventEmit of esgQuestionnaireService and sets #subheaderProps accordingly.
     * @returns: None
     */
    esgQuestionnaireServiceSubscription = (): void => {
        this.esgEventEmitSubscription = this.esgQuestionnaireService.eventEmit.subscribe((event) => {
            event === "submissionSuccess" && (this.submissionSuccess = true);
        });
    };

    /**
     * Description: closes alert by setting #submissionSuccess to false
     * @returns: None
     */
    onAlertDismiss = (): void => {
        this.submissionSuccess = false;
    };
    
    onTerminateAlertDismiss = (): void => {
        this.shouldShowSuccessTerminateAlert = false;
    };

    terminateSubscription = (): void => {
        this.terminateOperationSubscription = this.eventService.eventEmit.subscribe(event => {       
            if (event == "terminateSuccess") {
                this.shouldShowSuccessTerminateAlert = true;
                this.successTerminateAlertProps = {
                    type: "alert-requestVerifiedSuccess",
                    message: "supplier is terminated",
                    icon: "assets/images/icon-tick.svg",
                    iconFill: "#376803",
                    iconClose: "assets/images/icon-cross-green.svg",
                    onDismiss: this.onTerminateAlertDismiss };
                    window.scrollTo(0, 0);
                 }
        })
    }
    scollPostion() {
        let t, l, w, h;
        if (document.documentElement && document.documentElement.scrollTop) {
            t = document.documentElement.scrollTop;
            l = document.documentElement.scrollLeft;
            w = document.documentElement.scrollWidth;
            h = document.documentElement.scrollHeight;
        } else if (document.body) {
            t = document.body.scrollTop;
            l = document.body.scrollLeft;
            w = document.body.scrollWidth;
            h = document.body.scrollHeight;
        }
        return {
            top: t,
            left: l,
            width: w,
            height: h
        };
      }

    onWindowScroll() {
        let scrollBottom = this.scollPostion().height - window.innerHeight - this.scollPostion().top;
        let elements = document.getElementsByTagName("tsm-add-new-supplier")[0];

        if (elements) {
            const firstChild = <HTMLElement>elements.firstChild;
            if (scrollBottom < 1) {
                firstChild.style.bottom = "103px";
            } else {
                firstChild.style.bottom = "31px";
            }
        }
    }
}
