import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ButtonPropsType } from "@lsl16/sustainability-shared-components";
import { Popup } from "../../models/Popup";
import { ModalService } from "src/app/services/modal/modal.service";
import { Options } from "../../models/Options";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { OptionsTwo } from "src/app/models/supplieroptions";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { environment } from "src/environments/environment";
import { BvPopUpSuccessComponent } from "../bv-pop-up-success/bv-pop-up-success.component";
import { SupplierGeneralProfileService } from "src/app/services/supplier-general-profile.service";


@Component({
  selector: 'tsm-bv-pop-up-termination',
  templateUrl: './bv-pop-up-termination.component.html',
  styleUrls: ['./bv-pop-up-termination.component.sass']
})
export class BvPopUpTerminationComponent implements OnInit {
  @Input() confirmType;
  showReason: boolean = false;
  reasonList = [
    "Wrong Point of Contact invited",
    "Wrong, incorrect or duplicated company profile used",
    "Pilot SA terminated - no reminders or open record needed any more",
    "Invited by mistake",
    "Onboarding terminated",
    "Aging invites (to be confirmed if we want to use termination for this reason)",
    "Other reason",
  ];
  ProfileDetailStrictData = {
    options: {},
    supplier: {},
    centered: true,
    size: "lg",
    windowClass: "countdown-termination",
  };
  show: boolean = false;
  loading: boolean = false;
  content: string;
  modalData: Popup;
  buttonProps1: ButtonPropsType = {
    label: "Close",
    backgroundColor: "white",
    color: "purple",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    borderColor: "purple",
  };
  buttonProps2: ButtonPropsType = {
    label: "Withdraw",
    backgroundColor: "gray",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    width: 115,
    height: 48,
    padding: "16px 24px",
    margin: "0 0 0 2px",
    hoverBackgroundColor: "darkBlue",
    hoverColor: "white",
    disabled: true,
    onClick: () => {
      this.submit()
    }
  };
  loadingButtonProps: ButtonPropsType = {
    label: "Withdraw",
    backgroundColor: "gray",
    isProcessing: true,
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    width: 115,
    height: 48,
    padding: "16px 24px",
    margin: "0 0 0 2px",
    hoverBackgroundColor: "darkBlue",
    hoverColor: "white",
    disabled: true,
    onClick: () => {
      this.submit()
    }
  };
  constructor(public activeModal: NgbActiveModal,
    public modalService: ModalService,
    public ngbModalService: NgbModal,
    private httpService: HttpService,
    private router: Router,
    public supplierGeneralProfileService: SupplierGeneralProfileService,
  ) { }
  ngOnInit(): void {
    this.modalData = this.modalService.getterminationData();
    this.buttonProps1.onClick = this.activeModal.close;
  }

  closePop() {
    this.buttonProps1.onClick()
  }
  onKeyUp() {
    if (this.content.length === undefined || this.content.length === 0) {
      this.buttonProps2 = {
        label: "Withdraw",
        backgroundColor: "gray",
        color: "white",
        fontSize: 16,
        fontFamily: "Graphik-Medium",
        width: 115,
        height: 48,
        padding: "16px 24px",
        margin: "0 0 0 2px",
        hoverBackgroundColor: "darkBlue",
        hoverColor: "white",
        disabled: true,
        onClick: () => {
          this.submit()
        }
      };

    } else {
      this.buttonProps2 = {
        label: "Withdraw",
        backgroundColor: "blue",
        color: "white",
        fontSize: 16,
        fontFamily: "Graphik-Medium",
        width: 115,
        height: 48,
        padding: "16px 24px",
        margin: "0 0 0 2px",
        hoverBackgroundColor: "darkBlue",
        hoverColor: "white",
        disabled: false,
        onClick: () => {
          this.submit()
        }
      };
    }
  }
  check(item) {
    if (item === "Other reason") {
      this.showReason = true
      this.content = ""
      this.buttonProps2 = {
        label: "Withdraw",
        backgroundColor: "gray",
        color: "white",
        fontSize: 16,
        fontFamily: "Graphik-Medium",
        width: 115,
        height: 48,
        padding: "16px 24px",
        margin: "0 0 0 2px",
        hoverBackgroundColor: "darkBlue",
        hoverColor: "white",
        disabled: true,
        onClick: () => {
          this.submit()
        }
      };
    } else {
      this.showReason = false
      this.content = item
      this.buttonProps2 = {
        label: "Withdraw",
        backgroundColor: "blue",
        color: "white",
        fontSize: 16,
        fontFamily: "Graphik-Medium",
        width: 115,
        height: 48,
        padding: "16px 24px",
        margin: "0 0 0 2px",
        hoverBackgroundColor: "darkBlue",
        hoverColor: "white",
        disabled: false,
        onClick: () => {
          this.submit()
        }
      };
    }
  }
  async submit() {
    try {
      this.loading = true;
      const reasonUrl = `${environment.tsmBackendServiceURL}/terminate/addRecord`
      let reason: string;
      if (this.showReason) {
        reason = "Other reason: " + this.content
      } else {
        reason = this.content
      }
      const data = {
        supplierTsmId: this.confirmType.options.tsmId,
        supplierName: this.confirmType.options.supplierName,
        countryServed: this.confirmType.options.countryServed,
        terminateReason: reason,
        taskId: this.confirmType.options.taskId,
        isSusExpertBuyer: this.confirmType.options?.isSusExpertBuyer || false

      }
      const response: any = await this.httpService.PostPromise(reasonUrl, data);
      debugger;
      if (response.data === "termination success" || response.data === "termination approved success") {
        this.buttonProps1.onClick()
        if (response.data === "termination approved success")
          this.supplierGeneralProfileService.eventEmit.emit("Withdraw-approved");
        if (response.data === "termination success")
          this.supplierGeneralProfileService.eventEmit.emit("Withdraw");
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false;
    }
  }
}
