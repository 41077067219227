
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { isNgbDateStruct } from 'src/app/models/Search';
@Component({
  selector: 'tsm-sa-request-date-filter',
  templateUrl: './sa-request-date-filter.component.html',
  styleUrls: ['./sa-request-date-filter.component.sass']
})
export class SaRequestDateFilterComponent implements OnInit {
  @Output() dateFromChanged = new EventEmitter<Date>();
  // @Output() dateToChanged = new EventEmitter<Date>();
  @Input() clearFilter: Subject<boolean>;
  @Input() invalidValue: Subject<string>;
  @Output() dropdownError = new EventEmitter<boolean>();

  public dateFrom?: NgbDateStruct = null;
  public dateTo?: NgbDateStruct = null;

  fromDateInvalid = false;
  invalidDate = false;
  // toDateInvalid = false;
  // toDateLessThanFrom = false;

  labelTranslations = {
    controlLabel: '',
    from: '',
    to: '',
    inputPlaceholder: '',
    invalidFromDateMsg: '',
    // invalidToDateMsg: '',
    // invalidToDateLessMsg: ''
  }

  constructor() { }

  ngOnInit(): void {
    this.clearFilter.subscribe(() => this.clear());
    this.invalidValue.subscribe((event) => this.handleInvalid(event));
  }

  inputFromChanged() {
    this.resetInvalidFlags();
    // If the input is cleared, reset the error state
    if (!this.dateFrom) {
      this.resetInvalidFlags(); // Clear errors if date is empty
      this.onDropdownError(false); // No error
      return;
    }
    if (this.dateFrom && isNgbDateStruct(this.dateFrom)) {
      if (!this.isValidNgbDateStruct(this.dateFrom)) {
        this.invalidDate = true; // Invalid NgbDateStruct
        this.onDropdownError(true);
        return;
      }
  
      // If valid, clear the error and emit the date
      this.handleDateChanged(this.dateFromChanged, this.dateFrom);
      return;
    }
  
    // If not NgbDateStruct, treat it as invalid input
    this.invalidDate = true;
    this.onDropdownError(true);
  }

  // inputToChanged() {
  //   this.handleDateChanged(this.dateToChanged, this.dateTo);
  // }

  private handleDateChanged(eventEmitter: EventEmitter<Date|unknown>, value: NgbDateStruct|unknown) { 
    this.resetInvalidFlags();

    if (value && isNgbDateStruct(value)) {
      const selectedDate = new Date(value.year, value.month-1, value.day);
      const currentDate = new Date();

      if (selectedDate <= currentDate) {
        eventEmitter.emit(selectedDate);
        this.onDropdownError(false);
      } else {
        this.fromDateInvalid = true;
        this.onDropdownError(true);
      }
    } else {
      eventEmitter.emit(value);
    }
  }

  clear() {
    this.resetInvalidFlags();
    this.dateFrom = null;
    this.dateTo = null;
  }

  resetInvalidFlags() {
    this.onDropdownError(false);
    this.fromDateInvalid = false;
    this.invalidDate = false;
    // this.toDateInvalid = false;
    // this.toDateLessThanFrom = false;
  }

  handleInvalid(event) {
    if (event === 'requestDateFrom')
      this.fromDateInvalid = true;
      this.onDropdownError(true);
    // if (event === 'requestDateTo')
    //   this.toDateInvalid = true;
    // if (event === 'requestDateFrom#requestDateTo')
    //   this.toDateLessThanFrom = true;
  }
  private isValidNgbDateStruct(date: NgbDateStruct): boolean {
    const { year, month, day } = date;
  
    // Validate year
    if (year < 1900 || year > 9999) return false;
  
    // Validate month
    if (month < 1 || month > 12) return false;
  
    // Validate day (considering month and leap years)
    const daysInMonth = this.getDaysInMonth(month, year);
    if (day < 1 || day > daysInMonth) return false;
  
    return true;
  }

  onDropdownError(hasError: boolean) {
    this.dropdownError.emit(hasError);
  }
  
  // Helper method to get the number of days in a month, considering leap years
  private getDaysInMonth(month: number, year: number): number {
    return new Date(year, month, 0).getDate();
  }
  

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.labelTranslations = multilanguageJson.body.main.SaRetriggeringProcessFlow.requestDatePriorTo;
      }
    }
  }
}
