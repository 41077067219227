<div class="modal-header">
  <h4 class="modal-title">Start Onboarding</h4>
</div>
<div class="modal-body"  #focusContainer  tabindex="-1">
  <div class="body-content" *ngIf="showComponent">
    <div class="modal-body-content">
      <div class="text">
        <span style="font-size: larger">Selection is limited to countries sharing the same onboarding questionnaire.<br></span>
        <span style="font-style: italic">For onboarding this supplier to further countries, please initiate a further onboarding request in TSM from the supplier profile.</span>
      </div>
      <qnr-dynamic-form [configs]="configs" [model]="model" [context]="context" [callingComponent]="callingComponent" #form></qnr-dynamic-form>
    </div>
    <div *ngIf="independentContractorFlag">
      <p class="errorMsg">Please select a value</p>
    </div>
    <div class="button-content">
      <sh-button ngbAutofocus [props]="cancelProps"></sh-button>
      <sh-button class="continueButton" ngbAutofocus [props]="continueProps" [userType]="userType"></sh-button>
    </div>
  </div>
</div>

<div class="loadEffect" style="position: fixed;left: 42%;top:30%;z-index: 1000;" *ngIf="loading">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
</div>