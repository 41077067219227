import { Component, Input, OnInit, EventEmitter, Output, SimpleChanges, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { ButtonPropsType } from "@lsl16/sustainability-shared-components";
import { environment } from "src/environments/environment";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import _ from "lodash";
import { ActivatedRoute, Router } from "@angular/router";
import { RiskassessmentService } from 'src/app/services/riskassessment/riskassessment.service';
import { InherentQuestionnaireService } from  'src/app/services/inherentRiskQuestionnaire/inherentQuestionnaire.service'
import { serchCriteria } from './search-criteria.model';
import { IRQStatusCode, IRQStatusText } from './inherent-risk-questionnaire-status.enum';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { PopUpInherentRiskQuestionnaireComponent } from "src/app/components/pop-up-inherent-risk-questionnaire/pop-up-inherent-risk-questionnaire.component";


@Component({
  selector: 'tsm-bv-inherent-risk-questionnaire',
  templateUrl: './bv-inherent-risk-questionnaire.component.html',
  styleUrls: ['./bv-inherent-risk-questionnaire.component.sass']
})
export class BvInherentRiskQuestionnaireComponent implements OnInit, OnChanges {
  @ViewChild('tooltip', { static: false }) tooltip!: NgbTooltip;
  @Output() refershTerminateCallBack = new EventEmitter();
  @Input() allMu: any;
  @Input() allCountry: any;
  @Input() allCategory: any;
  @Input() allSubCategory: any;
  @Input() supplier: any;
  @Input() isDuplicate = false;
  statusFlag: boolean = true;
  featureFlagIRQ: boolean = false;

  tempMarketUnit: any = [];
  allCountryServedCodeList: any = [];
  tempLocation: any = [];
  tempCategory: any = [];
  tempSubCategory: any = [];
  tempIRQStatus: any = [
    {
      Code: IRQStatusCode.pendingReview,
      Text: IRQStatusText.pendingReview
    }, {
      Code: IRQStatusCode.materialityDetermined,
      Text: IRQStatusText.materialityDetermined
    }, {
      Code: IRQStatusCode.dueDiligenceCompleted,
      Text: IRQStatusText.dueDiligenceCompleted
    }
  ];
  allAssessmentList: any = [];
  searchCriteria: serchCriteria = {
    countryLocation: [],
    marketUnit: [],
    category: [],
    subCategory: [],
    irqStatus: []
  };
  oldSearchCriteria: any = _.cloneDeep(this.searchCriteria);

  exhibitAssessmentList: any = [];
  mu: string = "";
  country: any = [];
  IRQData = [];
  filterFlag: boolean;
  // supplierPoc: string = "";
  raAdminFlag: boolean = false;
  selectedSupplierTsmId: any;
  isBiAccess: boolean;
  raPriorityRoles = {
    scm: 'scm',
    srdp: 'srdp',
    prisk: 'prisk',
    srsa: 'srsa',
    srra: 'srra'
  }
  cancellationReasons: string[] = [
    'Duplicate IRQ Detected',
    'Third-Party Service No Longer Required',
    'Not Chosen During Pre-Selection Process',
    'Other'
  ];
  isModalVisible: boolean = false;
  isCancelFailed: boolean = false;
  isCancelled: boolean = false;
  cancel_reason: string = '';
  cancel_comment: string = '';
  reasonError: boolean = false;
  commentError: boolean = false;
  flag: boolean = true;
  irqId: string;
  loading: boolean = false;

  openModal(irqId: string) {
    this.isCancelled = false;
    this.isCancelFailed = false;
    if(!this.isModalVisible){
    this.irqId = irqId;
    this.isModalVisible = true;
    document.body.style.overflow = 'hidden';
    }
  }
  async closeModal() {
    this.isModalVisible = false;
    this.clearFields();
    this.isCancelFailed = false;
    if(this.isCancelled){
      this.loading = true;
      this.isCancelled = false; 
      await this.triggerIRQMetaData(this.selectedSupplierTsmId);
      this.filterAssessmentList(this.searchCriteria);
    }
    this.loading = false;
    document.body.style.overflow = 'auto';
  }
  async confirmCancel() {
    if (!this.cancel_reason) {
      this.reasonError = true;
      this.flag = false;
    }
    if (!this.cancel_comment) {
      this.commentError = true;
      this.flag = false;
    }
    if (this.cancel_reason && this.cancel_comment) this.flag = true;
    const url = `${environment.tsmBackendServiceURL}/riskAssessmentData/cancelIRQ`;
    if (this.flag) {
      await this.HttpService.PostPromise(url, { irqId: this.irqId, cancel_reason: this.cancel_reason, cancel_comment: this.cancel_comment })
        .then((response: any) => {
          if (response.status === 200) {
            this.clearFields();
            this.isModalVisible = false;
            this.isCancelled = true;
            this.isCancelFailed = false;
          }
          else {
            console.error('IRQ Cancellation failed:', response);
            this.handleCancelFailed();
          }
        })
        .catch(error => {
          console.error('Error cancelling IRQ:', error);
          this.handleCancelFailed();
        });
    }
  }
  clearFields() {
    this.cancel_reason = '';
    this.cancel_comment = '';
    this.reasonError = false;
    this.commentError = false;
  }
  handleCancelFailed() {
    this.isCancelFailed = true;
    this.isModalVisible = false;
    this.isCancelled = false;
  }
  constructor(
    config: NgbModalConfig,
    public ngbModalService: NgbModal,
    private HttpService: HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private riskAssessmentService: RiskassessmentService,
    private InherentQuestionnaireService: InherentQuestionnaireService,
    private authService: AuthService
  ) {
    config.backdrop = "static";
  }
  async ngOnInit(): Promise<void> {
    // To get the supplier TSM ID 
    const routeParam = this.route.snapshot.paramMap.get("id");
    this.selectedSupplierTsmId = routeParam;
    await this.triggerIRQMetaData(this.selectedSupplierTsmId);
    this.filterAssessmentList(this.searchCriteria);
  }
  handleEnterKey(tooltip: NgbTooltip, event: KeyboardEvent): void {
    tooltip.open();
    setTimeout(() => {
      const tooltipContent = document.getElementById('tooltipContent');
      if (tooltipContent) {
        tooltipContent.focus(); // Focus tooltip content
      }
    }, 0);
  }
  // Handle blur to close tooltip
  handleBlur(event: FocusEvent, tooltip: NgbTooltip): void {
    const relatedTarget = event.relatedTarget as HTMLElement;
    if (relatedTarget && relatedTarget.closest('#tooltipContent')) {
      // If focus is moving inside the tooltip, don't close
      return;
    }
    tooltip.close();
  }
  // Manage key navigation within the tooltip
  handleTooltipKeydown(event: KeyboardEvent, tooltip: NgbTooltip, tooltipTrigger: ElementRef): void {
    const tooltipElements = Array.from(
      document.querySelectorAll('#tooltipContent a, #tooltipContent [tabindex]:not([tabindex="-1"])')
    ) as HTMLElement[];
    const currentIndex = tooltipElements.indexOf(document.activeElement as HTMLElement);
    if (event.key === 'Tab') {
      event.preventDefault();
      if (currentIndex === tooltipElements.length - 1) {
        tooltip.close();
        setTimeout(() => tooltipTrigger.nativeElement.focus(), 0);
      } else {
        tooltipElements[currentIndex + 1]?.focus();
      }
    }
  }

  saveSectionEvent(e, dropdown): void {
    this.searchCriteria[dropdown] = e;
    this.filterAssessmentList(this.searchCriteria);
  }


  // show data according to selected box
  private filterAssessmentList(searchCriteria: serchCriteria): void {
    let tempLoc = this.allCountry;
    this.exhibitAssessmentList = this.IRQData.filter(item => item.questionnaireType === 'IRQ');
    if(this.exhibitAssessmentList.length > 0){
        this.filterFlag = true;
    }
    else this.filterFlag = false;
    if (searchCriteria.marketUnit.length > 0) {
        const searchMarketUnit = searchCriteria.marketUnit.map((ent: any) => {
            return ent.Code;
        });
        this.exhibitAssessmentList = this.exhibitAssessmentList.filter((item) => {
            const MarketUnitsList = item?.marketUnit.split(',').map(unit => unit.trim());
            const intersection = MarketUnitsList.some(unit => searchMarketUnit.includes(unit));
            if (intersection) {
              return true;
            }
            return false;
        });
        tempLoc = this.allCountry.filter((item) => {
            const MarketUnitsList = item?.marketUnit.split(',').map(unit => unit.trim());
            return MarketUnitsList.some(unit => searchMarketUnit.includes(unit));
        });
    }
    if (!_.isEqual(searchCriteria.marketUnit, this.oldSearchCriteria.marketUnit)) {
        searchCriteria.countryLocation = [];
        this.tempLocation = this.getServedCountryList(tempLoc);
    }
    if (searchCriteria.countryLocation.length > 0) {
        const searchCountryLocation = searchCriteria.countryLocation.map((ent: any) => {
            return ent.Text;
        });
        this.exhibitAssessmentList = this.exhibitAssessmentList.filter((item) => {
            const intersection = item?.countryServed.filter(country => new Set(searchCountryLocation).has(country));
            if (intersection.length > 0) {
          return true;
        }
        return false;
        });
    }
    if (searchCriteria.category.length > 0) {
        const searchCategory = searchCriteria.category.map((ent: any) => {
            return ent.Text;
        });
        this.exhibitAssessmentList = this.exhibitAssessmentList.filter((item) => {
            const intersection = new Set(searchCategory).has(item?.category);
            if (intersection) {
          return true;
        }
        return false;
        });
    }
    if (searchCriteria.subCategory.length > 0) {
        const searchSubCategory = searchCriteria.subCategory.map((ent: any) => {
            return ent.Text;
        });
        this.exhibitAssessmentList = this.exhibitAssessmentList.filter((item) => {
            const intersection = new Set(searchSubCategory).has(item?.subCategory);
            if (intersection) {
          return true;
        }
        return false;
        });
    }
    if (searchCriteria.irqStatus.length > 0) {
        const searchEsgStatus = searchCriteria.irqStatus.map((ent: any) => {
            return ent.Text;
        });
        this.exhibitAssessmentList = this.exhibitAssessmentList.filter((item) => {
            let status;
            if (item?.infoseclifecyclestatus === null) {
                status = "Pending Review"
        }
        else {
                status = item?.infoseclifecyclestatus
            }
            if (searchEsgStatus.includes(status)) {
          return true;
        }
        return false;
        });
    }
    this.oldSearchCriteria = _.cloneDeep(searchCriteria);
}

  getServedCountryList(servedData): any {
    const tmpLoc = [];
    servedData?.forEach(element => {
      const state = element.countryServedEnable;
      if (state === true) {
        const country = {
          Code: element.tsmCode,
          Text: element.countryServedName
        };
        tmpLoc.push(country);
      }
    });
    return this.sortSelectResource(tmpLoc);
  }

  sortSelectResource = (resource: any): any => {
    return resource?.sort((obj1: any, obj2: any) => {
      const val1 = obj1.Text;
      const val2 = obj2.Text;
      if (val1 > val2) {
        return 1;
      } else if (val1 < val2) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  async triggerIRQMetaData(supplierTsmId): Promise<void> {
    // To get the risk assessment metadata details
    const IRQDetails = `${environment.tsmBackendServiceURL}/riskAssessmentData/getRiskAssessmentMetaData`;
    const response = await this.HttpService.PostPromise(IRQDetails, { supplier_TsmId: supplierTsmId, raFlag: false });
    console.log(" IRQ response", response);
    this.featureFlagIRQ = (response as any).featureflagIRQ?.value || false;
    console.log("Feature Flag IRQ :", this.featureFlagIRQ);

    this.IRQData = this.getAllIRQList(response);
    // this.supplierPoc = (this.supplier.supplierAdmin ? this.supplier.supplierAdmin.userName : this.supplier.pointOfContactEmail)
  }

  private async initialAssessmentList(): Promise<void> {
    const irqList = this.IRQData;
    irqList.forEach((item: any) => {
      item.arrayServedCountry = item.servedCountryList.map((country: any) => {
        return country.name;
      }).sort();
      item.arrayServedCountryCode = item.servedCountryList.map((country: any) => {
        return country.tsmCode;
      });
      this.allCountryServedCodeList = this.allCountryServedCodeList.concat(item.arrayServedCountryCode);
      const tempStrMarketUnit = item.servedCountryList.map((country: any) => {
        return country.marketUnit;
      }).sort();
      item.arrayMarketUnit = Array.from(new Set(tempStrMarketUnit));

    });
    this.allAssessmentList = irqList;
    this.exhibitAssessmentList = this.allAssessmentList.sort((a, b) => a.creationDate.localeCompare(b.creationDate));
  }

  isRequestor(assessment): boolean {
    let userId = JSON.parse(localStorage.getItem("userInfo")).pocEmail;
    if (assessment.requestorName === userId) {
      return true
    }
    return false
  }

  hasWriteAccess(assessment): boolean {
    let RAFlag = localStorage.getItem("RA_flag");
    if (RAFlag === this.raPriorityRoles.prisk || RAFlag === this.raPriorityRoles.srsa) {
      return true
    }
    return false
  }

  hasViewAccess(assessment): boolean {
    let RAFlag = localStorage.getItem("RA_flag");
    this.isBiAccess = this.authService.isBI();
    if (RAFlag === this.raPriorityRoles.scm || RAFlag === this.raPriorityRoles.srdp || RAFlag === this.raPriorityRoles.prisk || RAFlag === this.raPriorityRoles.srsa || RAFlag === this.raPriorityRoles.srra || this.isBiAccess) {
      return true
    }
    return false
  }

  async triggerDraft(selectedIRQ) {
    this.InherentQuestionnaireService.irqMetadata = {
      "tsmId": selectedIRQ.tsmId,
    }
    this.router.navigateByUrl(`/inherent-questionnaire/2/${selectedIRQ.raId}`);
  }

  getAllIRQList(assessmentList) {
    let IRQArray = []
    assessmentList.riskAssessmentMetaData.forEach((assessment) => {
      IRQArray.push(assessment);
    })
    return IRQArray;
  }

  sharedButtonProps: ButtonPropsType = {
    label: "",
    fontSize: 16,
    height: 48,
    padding: "10px 0px 10px 0px",
    borderColor: "black-12",
    fontFamily: "Graphik"
  };

  requestIRQButton: ButtonPropsType = {
    ...this.sharedButtonProps,
    width: 319,
    color: "white",
    backgroundColor: "blue",
    disabled: false,
    label: "Start New Inherent Risk Questionnaire",
    onClick: () => {
      this.onRequestIRQButtonClick();
    },
  };

  onRequestIRQButtonClick = async () => {
    const confirmType = {
      options: {},
      centered: true,
      size: "lg",
      supplier: this.supplier,
      windowClass: "modal-country-notify",
      supplierTsmId: this.selectedSupplierTsmId
    };
    const modalReference = this.ngbModalService.open(PopUpInherentRiskQuestionnaireComponent, confirmType);
    modalReference.componentInstance.confirmType = confirmType;
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.requestIRQButton.disabled = this.isDuplicate;
    if (changes.supplier !== undefined && this.supplier && this.supplier.status === "Terminated") {//Terminated
      this.statusFlag = false;
    }
    if (changes.allMu && changes.allMu.currentValue) {
      this.tempMarketUnit = this.sortSelectResource(changes.allMu.currentValue);
    }
    if (changes.allCategory && changes.allCategory.currentValue) {
      this.tempCategory = this.sortSelectResource(changes.allCategory.currentValue);
    }
    if (changes.allSubCategory && changes.allSubCategory.currentValue) {
      this.tempSubCategory = this.sortSelectResource(changes.allSubCategory.currentValue);
    }
    if (changes.allCountry && changes.allCountry.currentValue) {
      const dataCountry = changes.allCountry.currentValue;
      this.tempLocation = this.getServedCountryList(dataCountry);
    }

    if (changes.supplier.currentValue !== undefined) {
      await this.initialAssessmentList();
    }
  }

  async triggerViewDetails(selectedIRQ) {
    let IrqbibdaReview;
    if (selectedIRQ.bibdaStatus) {
      if (selectedIRQ.bibdaStatus === "Not Started/On-going") {
        IrqbibdaReview = "1";
      } else {
        IrqbibdaReview = "2";
      }
    }

    const queryParams = {
      IRQ: IrqbibdaReview,
      supplierTsmId: this.selectedSupplierTsmId
    };
    this.router.navigate([`/inherent-questionnaire/1`, `${selectedIRQ.raId}`], { queryParams });
  }

  async downloadReport(selectedRA): Promise<void> {
    await this.InherentQuestionnaireService.downloadReportOfRA(selectedRA.raId);
  }

  async linkIrq(selectedIRQ)
  {
    this.router.navigate([`/link-irq-to-onboarding`],{
      queryParams: { irqId: selectedIRQ.raId}
    });
  }
}
