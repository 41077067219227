<div class="grid-table-container shadow border mt-3 p-3 bg-white mx-3">
    <div class="customise-btn">
        <img src="assets/icons/settings-24px-purple.svg" (click)="openPopUp()" class="icon-customise" alt="icon-customise">
    </div>
    <ngx-datatable class="bootstrap" [reorderable]="true"  [rows]="suppliersData" [headerHeight]="30" [scrollbarH]="true" [footerHeight]="footerHeight" [rowHeight]="32"
        [columnMode]="'force'"  [messages]="{emptyMessage: 'No Suppliers to display',totalMessage:'suppliers found'}" [limit]="50" >
        <ngx-datatable-column *ngFor="let col of columns" [prop]="col.prop" [width]="col.width"
            [frozenLeft]="col.isLeftPinnedColumn" [frozenRight]="col.isRightPinnedColumn">
            <ng-template ngx-datatable-header-template>
                <div class="cell-template-header-text">
                    {{col.name | uppercase}}
                </div>
            </ng-template>
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                <ng-contianer [ngSwitch]="col.prop">
                    <ng-contianer *ngSwitchCase="'supplierName'">
                        <a tabindex="0" class="btn btn-link p-0 text-start" href="/#/supplier-profile/{{row.tsmId}}" target="_self" (click)="openSupplier(row.tsmId)" (keydown.enter)="openSupplier(row.tsmId)">{{value}}</a>
                    </ng-contianer>
                    <ng-contianer *ngSwitchCase="'internationalCompanyName'">
                        <ng-container *ngTemplateOutlet="tooltipTemp; context:{item:value,limit:30}">
                        </ng-container>
                    </ng-contianer>
                    <ng-contianer *ngSwitchCase="'countryOfDelivery'">
                        <ng-container *ngTemplateOutlet="tooltipTemp; context:{item:value,limit:20}">
                        </ng-container>
                    </ng-contianer>
                    <ng-contianer *ngSwitchCase="'domainName'">
                        <ng-container *ngTemplateOutlet="tooltipTemp; context:{item:value,limit:15}">
                        </ng-container>
                    </ng-contianer>
                    <ng-contianer *ngSwitchCase="'tsmId'">
                        <ng-container *ngTemplateOutlet="tooltipTemp; context:{item:value,limit:50}">
                        </ng-container>
                    </ng-contianer>
                    <ng-contianer *ngSwitchCase="'tsmStatus'">
                        <span class="text-danger pending-status" *ngIf="value === 'Pending'">{{value}}</span>
                        <span class="text-success" *ngIf="value === 'Active'">{{value}}</span>
                        <span class="text-warning" *ngIf="value === 'Terminated'">{{value}}</span>
                    </ng-contianer>
                    <span *ngSwitchDefault>{{value}}</span>
                </ng-contianer>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</div>

<ng-template #tooltipTemp let-data="item" let-limit="limit">
    <span class="d-block" [ngbTooltip]="(data.toString().length > limit) ? data : null"
        tooltipClass="manage-user-tooltip-class">
        {{ (data.toString().length > limit) ? (data.toString() | slice:0:limit)+'... ' : data.toString() }}
    </span>
</ng-template>