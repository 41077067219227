<tsm-subheader [props]="reportProps"></tsm-subheader>
<div class="container-fluid mb-5">
  <div class="main_bg">
    <div class="container container-fluid first-container d-flex">
      <div class="form m-5 p-5">
        <div class="advanced-search advanced-search-content">
          <div class="search-panel bg-white mb-3 mx-0">
            <div class="filters-area row g-3">
              <div class="col-4 px-16">
                <tsm-supplier-name
                  [clearFilter]="clearFilter"
                  (valueChanged)="filterCriteriaChanged($event, 'supplierName')"
                ></tsm-supplier-name>
              </div>
              <div class="col-4 px-16" id="mkunit">
                <tsm-market-unit-filter
                  [clearFilter]="clearFilter"
                  (valueChanged)="filterCriteriaChanged($event, 'marketUnit')"
                  (dropdownError)="handleDropdownError('marketUnit', $event)"
                >
              </tsm-market-unit-filter>
              </div>
              <div class="col-4 px-16">
                <tsm-country-served-filter
                  [clearFilter]="clearFilter"
                  (valueChanged)="
                    filterCriteriaChanged($event, 'countryServed')
                  "
                  (dropdownError)="handleDropdownError('countryServed', $event)"
                >
                </tsm-country-served-filter>
              </div>
              <div class="col-4 px-16">
                <tsm-global-parent-duns-filter
                  [clearFilter]="clearFilter"
                  [invalidValue]="invalidValue"
                  (valueChanged)="
                    filterCriteriaChanged($event, 'globalUltimateParentDUNS')
                  "
                  (dunsError)="handleDropdownError('globalUltimateParentDUNS', $event)"
                >
                </tsm-global-parent-duns-filter>
              </div>
              <div class="col-4 px-16">
                <tsm-requestor-email
                  [clearFilter]="clearFilter"
                  [invalidValue]="invalidValue"
                  (valueChanged)="
                    filterCriteriaChanged($event, 'requestorEmail')
                  "
                  (emailError)="handleDropdownError('requestorEmail', $event)"
                >
                </tsm-requestor-email>
              </div>
              <div class="col-3 px-16">
                <tsm-esg-status-filter
                  [clearFilter]="clearFilter"
                  (valueChanged)="filterCriteriaChanged($event, 'esgStatus')"
                  (dropdownError)="handleDropdownError('esgStatus', $event)"
                ></tsm-esg-status-filter>
              </div>
              <div class="col-4 px-16">
                <tsm-requestor-purpose-filter
                  [clearFilter]="clearFilter"
                  (valueChanged)="
                    filterCriteriaChanged($event, 'requestPurpose')
                  "
                  (dropdownError)="handleDropdownError('requestPurpose', $event)"
                >
                </tsm-requestor-purpose-filter>
              </div>
              <div class="col-4 px-16">
                <tsm-internal-poc-email-filter
                  [clearFilter]="clearFilter"
                  [invalidValue]="invalidValue"
                  (valueChanged)="
                    filterCriteriaChanged($event, 'internalPoCEmail')
                  "
                  (emailError)="handleDropdownError('internalPoCEmail', $event)"
                >
                </tsm-internal-poc-email-filter>
              </div>
              <div class="col-4 px-16">
                <tsm-submission-date-filter
                  [clearFilter]="clearFilter"
                  [invalidValue]="invalidValue"
                  (dateFromChanged)="
                    filterCriteriaChanged($event, 'esgSubmissionDateFrom')
                  "
                  (dateFromError)="handleDropdownError('esgSubmissionDateFrom', $event)"
                  (dateToChanged)="
                    filterCriteriaChanged($event, 'esgSubmissionDateTo')
                  "
                  (dateToError)="handleDropdownError('esgSubmissionDateTo', $event)"
                >
                </tsm-submission-date-filter>
              </div>
              <div class="col-4 px-16">
                <tsm-request-date-filter
                  [clearFilter]="clearFilter"
                  [invalidValue]="invalidValue"
                  (dateFromChanged)="
                    filterCriteriaChanged($event, 'requestDateFrom')
                  "
                  (dateFromError)="handleDropdownError('requestDateFrom', $event)"
                  (dateToChanged)="
                    filterCriteriaChanged($event, 'requestDateTo')
                  "
                  (dateToError)="handleDropdownError('requestDateTo', $event)"
                >
                </tsm-request-date-filter>
              </div>
              <div class="col-4 px-16">
                <tsm-diligence-date-filter
                  [clearFilter]="clearFilter"
                  [invalidValue]="invalidValue"
                  (dateFromChanged)="
                    filterCriteriaChanged($event, 'diligenceEndDateFrom')
                  "
                  (dateFromError)="handleDropdownError('diligenceEndDateFrom', $event)"
                  (dateToChanged)="
                    filterCriteriaChanged($event, 'diligenceEndDateTo')
                  "
                  (dateToError)="handleDropdownError('diligenceEndDateTo', $event)"
                >
                </tsm-diligence-date-filter>
              </div>
              <div class="col-4 px-16">
                <tsm-supplier-category-filter
                  [clearFilter]="clearFilter"
                  (valueChanged)="
                    filterCriteriaChanged($event, 'supplierCategory')
                  "
                  (dropdownError)="handleDropdownError('supplierCategory', $event)"
                >
                </tsm-supplier-category-filter>
              </div>
              <div class="col-4 px-16">
                <tsm-compliance-filter
                  [clearFilter]="clearFilter"
                  (valueChanged)="filterCriteriaChanged($event, 'includeCompliant')"
                  (dropdownError)="handleDropdownError('includeCompliant', $event)"
                >
                </tsm-compliance-filter>
              </div>
              <div class="col-4 px-16">
                <tsm-include-indirect-checkbox-filter
                    [clearFilter]="clearFilter"
                    (includeIndirectChanged)="filterCriteriaChanged($event, 'includeIndirect')"
                    >
                </tsm-include-indirect-checkbox-filter>
            </div>
            <div class="col-5 px-16">
                <tsm-tsm-id
                [clearFilter]="clearFilter"
                (valueChanged)="filterCriteriaChanged($event, 'supplierTsmId')"
              >
              </tsm-tsm-id>
            </div>
              <div class="inline-block"></div>
            </div>
            <div class="d-flex justify-content-end">
              <div class="align-self-end me-3">
                <sh-button [props]="clearFilterButton"></sh-button>
              </div>
              <div class="align-self-end">
                <sh-button [props]="applyFilterButton"></sh-button>
              </div>
            </div>
            <div 
              id="waitingMessage"
              class="d-flex justify-content-end waitingMessage"
              *ngIf="showWaitingMessage && !filterRequestCompleted">
                {{waitingMessageLabel}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mx-5">
      <tsm-report-preview
        [applyFilterEvent]="applyFilterEventEmitter"
        (requestStatusEventEmitter)="handleRequestStatusEvent($event)"></tsm-report-preview>
    </div>
  </div>
</div>
