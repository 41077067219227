<label tabindex="0" class="control-label" for="requestDate"
    [ngStyle]="fromDateInvalid ? {'color': '#d63059'}:''">Request Trigger Date On Or After</label>
<div class="row">
    <div class="col-6">
        <div class="date-from">
            <div class="input-area date-picker">
                <input class="form-control date-input" id="request-date-from" [placeholder]="labelTranslations.inputPlaceholder" name="dp"
                    [(ngModel)]="dateFrom" ngbDatepicker #d="ngbDatepicker" (dateSelect)="inputFromChanged()"
                    (input)="inputFromChanged()"
                    (errorStatus)="onDropdownError($event)">

                <span class="calendar" type="button" (click)="d.toggle()"><img class="ico"
                    src="../../../../assets/icons/calendar.svg" alt="calendarIcon" tabindex="0" (keydown.enter)="d.toggle()"></span>

            </div>
        </div>
        <div class="invalid-msg" tabindex="0" id="invalid-fromdate" *ngIf="fromDateInvalid">{{labelTranslations.invalidFromDateMsg}}
        </div>
        <div class="invalid-msg" tabindex="0" id="invalid-date" *ngIf="invalidDate">Please enter a valid date
        </div>
    </div>
</div>