import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { isNgbDateStruct } from 'src/app/models/Search';
import { BaseDateFilterComponent } from 'src/app/components/base-date-filter/base-date-filter.component';

@Component({
  selector: 'tsm-diligence-date-filter',
  templateUrl: './diligence-date-filter.component.html',
  styleUrls: ['./diligence-date-filter.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class DiligenceDateFilterComponent extends BaseDateFilterComponent implements OnInit {
  @Output() dateFromChanged = new EventEmitter<Date>();
  @Output() dateToChanged = new EventEmitter<Date>();
  @Input() clearFilter: Subject<boolean>;
  @Input() invalidValue: Subject<string>;
  @Output() dateFromError = new EventEmitter<boolean>();
  @Output() dateToError = new EventEmitter<boolean>();

  public dateFrom?: NgbDateStruct = null;
  public dateTo?: NgbDateStruct = null;

  fromDateInvalid = false;
  toDateInvalid = false;
  toDateLessThanFrom = false;

  labelTranslations = {
    controlLabel: '',
    from: '',
    to: '',
    inputPlaceholder: '',
    invalidFromDateMsg: '',
    invalidToDateMsg: '',
    invalidToDateLessMsg: ''
  }

  invalidfromDate: boolean = false;
  fromDateErrorMsg:string ='';
  invalidToDate: boolean = false;
  toDateErrorMsg : string ='';

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.clearFilter.subscribe(() => this.clear());
    this.invalidValue.subscribe((event) => this.handleInvalid(event));
  }

  inputFromChanged() {
    this.resetFromDateFlags();
    let isfromvalid =this.isDateValid(this.dateFrom);
    if(isfromvalid === "valid"){
      this.handleDateChanged(this.dateFromChanged, this.dateFrom);
    }
    else if(isfromvalid === "future"){
      this.setFromDateInvalid(this.errorMessage['futuredate']);
    }
    else {
      this.setFromDateInvalid(this.errorMessage['invalid']);
    }
  }

  inputToChanged() {
    this.resetToDateFlags();
    let istovalid =this.isDateValid(this.dateTo);
    if(istovalid === "valid"){
      this.handleDateChanged(this.dateToChanged, this.dateTo);
    }
    else if(istovalid === "future"){
      this.setToDateInvalid(this.errorMessage['futuredate']);
    }
    else {
      this.setToDateInvalid(this.errorMessage['invalid']);
    }
  }

  resetFromDateFlags() {
    this.dateFromError.emit(false);
    this.invalidfromDate = false;
  }

  setFromDateInvalid(err){
    this.invalidfromDate = true; 
    this.dateFromError.emit(true);
    this.fromDateErrorMsg = err;
  }

  resetToDateFlags() {
    this.dateToError.emit(false);
    this.invalidToDate = false;
  }

  setToDateInvalid(err){
    this.invalidToDate = true; 
    this.dateToError.emit(true);
    this.toDateErrorMsg = err;
  }

  private handleDateChanged(eventEmitter: EventEmitter<Date|unknown>, value: NgbDateStruct|unknown) { 
    this.resetInvalidFlags();

    if (value && isNgbDateStruct(value)) {
      eventEmitter.emit(new Date(value.year, value.month-1, value.day));
    } else {
      eventEmitter.emit(value);
    }
  }


  clear() {
    this.resetInvalidFlags();
    this.dateFrom = null;
    this.dateTo = null;
    this.resetFromDateFlags();
    this.resetToDateFlags();
  }

  resetInvalidFlags() {
    this.fromDateInvalid = false;
    this.toDateInvalid = false;
    this.toDateLessThanFrom = false;
  }

  handleInvalid(event) {
    if (event === 'diligenceEndDateFrom')
      this.fromDateInvalid = true;
    if (event === 'diligenceEndDateTo')
      this.toDateInvalid = true;
    if (event === 'diligenceEndDateFrom#diligenceEndDateTo')
      this.toDateLessThanFrom = true;
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.labelTranslations = multilanguageJson.body.main.reportFilters.diligenceEndDate;
      }
    }
  }
}
