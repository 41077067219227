import {Injectable} from "@angular/core";
import {HttpService} from "@lsl16/sustainability-shared-components";
import { HttpClient } from "@angular/common/http";
import {environment} from "src/environments/environment";
import {createSupplierProfileResponseType, createSupplierRequestDataType} from "./types";
import {AuthService} from "../auth/auth.service";


@Injectable({
    providedIn: "root"
})
export class SupplierHubService {
    supplierHubUrlEndpoint = environment.supplierHubRedirectBaseUrl;
    tsmBackendSupplierHubServiceUrl = `${environment.tsmBackendServiceURL}/supplier-hub`;
    env = environment.env;

  constructor(private httpService: HttpService,
              private authService: AuthService,
              private http: HttpClient
  ) {
  }

  createSupplierProfile = async (supplierInformation: createSupplierRequestDataType): Promise<string> => {
    const url = `${this.tsmBackendSupplierHubServiceUrl}/create-supplier-profile`;
    const accessToken = `Bearer ${this.authService.getToken()}`
    supplierInformation.env = environment.env // Tech debt Add environment variable when calling to supplier hub
    const response: createSupplierProfileResponseType = await this.http.post<createSupplierProfileResponseType>(
      url,
      supplierInformation, {
        headers: {
          'Authorization': accessToken
        }
      }
    ).toPromise();
    return response.data.supplierCode;
  };

    getOnboardingRequestRedirectUrl = async (supplierInformation: createSupplierRequestDataType): Promise<string> => {
        try {
          let envParams  = environment.env
          if(environment.env == "dev"){
            envParams  = "de";
          }else if (environment.env == "test"){
            envParams  = "te";
          }if (environment.env == "perf"){
            envParams  = "pe";
          }if (environment.env == "prod"){
            envParams  = "pr";
          }if (environment.env == "stage"){
            envParams  = "st";
          }if (environment.env == "local"){
            envParams  = "local";
          }if (environment.env == "playground"){
            envParams  = "play";
          }
            const supplierCode = await this.createSupplierProfile(supplierInformation);
           
            return `${this.supplierHubUrlEndpoint}/step1?supplierCode=${supplierCode}&env=${this.env}&ciotsm=${envParams}`;
        } catch (error) {}
    };

    /**
     * Description: creates a redirect url based on key1 and key2 arguments to redirect buyer to profiles card page in Supplier Hub
     * can be used to handle onboarding card access now button on buyer action page/ buyer view supplier profile
     * @param: key1: string - supplierTsmId
     * @param: key2: string - countryServed
     * @returns: url: string
     */
    getProfilesCardPageRedirectUrl = (key1: string, key2: string): string => {
        let envParams  = environment.env
          if(environment.env == "dev"){
            envParams  = "de";
          }else if (environment.env == "test"){
            envParams  = "te";
          }if (environment.env == "perf"){
            envParams  = "pe";
          }if (environment.env == "prod"){
            envParams  = "pr";
          }if (environment.env == "stage"){
            envParams  = "st";
          }if (environment.env == "local"){
            envParams  = "local";
          }if (environment.env == "playground"){
            envParams  = "play";
          }
        const url = `${this.supplierHubUrlEndpoint}/tsmsearch?key1=${key1}&key2=${key2}&env=${this.env}&ciotsm=${envParams}`;
        return url;
    };
}
