import { Component } from '@angular/core';
import { EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { EsgReportService } from 'src/app/services/esgReport/esg-report.service';

@Component({
  selector: 'tsm-company-subcategory-filter',
  templateUrl: './company-subcategory-filter.component.html',
  styleUrls: ['./company-subcategory-filter.component.sass']
})
export class CompanySubcategoryFilterComponent {
  @Output() valueChanged = new EventEmitter();
  @Input() clearFilter: Subject<boolean>;
  @Input() tempcompanyCodeData: any[];
  @Output() dropdownError = new EventEmitter<boolean>();

  values: any[];
  labelTranslations = {
    controlLabel: '',
    inputPlaceholder: ''
  }
  companySubCategory = [];

  constructor(private esgReportService: EsgReportService) { }

  async ngOnInit(): Promise<void> {
    this.esgReportService.getCompanyCategory().subscribe(data => {
      console.log(data)
      this.companySubCategory = data
      this.clear();
      this.valueChanged.emit(this.values);

  });
    console.log("this.tempcompanyCodeData", this.tempcompanyCodeData);
    this.clearFilter.subscribe(() => this.clear());
  }

  dropdownChanged(selectedValues) {
    this.values = selectedValues;
    this.valueChanged.emit(this.values);
  }

  clear() {
    this.values = [];
    this.companySubCategory.forEach(csc => {
      csc.checked = false;
    });
  }
  onDropdownError(hasError: boolean) {
    this.dropdownError.emit(hasError);
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.labelTranslations = multilanguageJson.body.main.SaRetriggeringProcessFlow.companySubCategory;
      }
    }
  }


}
