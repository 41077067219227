import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'tsm-irq-success-popup',
  templateUrl: './irq-success-popup.component.html',
  styleUrls: ['./irq-success-popup.component.scss']
})
export class IrqSuccessPopupComponent {
  constructor(public dialogRef: MatDialogRef<IrqSuccessPopupComponent>) {}

  closePopup() {
    this.dialogRef.close();
  }
}

