import { Component, OnInit } from '@angular/core';
import { InherentQuestionnaireService } from 'src/app/services/inherentRiskQuestionnaire/inherentQuestionnaire.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-questionnaire-maintenance-page',
  templateUrl: './questionnaire-maintenance-page.component.html',
  styleUrls: ['./questionnaire-maintenance-page.component.sass']
})
export class QuestionnaireMaintenancePageComponent implements OnInit {
  selectedAction: string = '';
  uniqueSections: any[] = [];
  sections: any[] = [];
  types: any[] = [];
  selectedSection: string = '';
  selectedType: string = '';
  selectedRequired: boolean | null = null;
  label: string = '';
  subLabel: string = '';
  questionName: string = '';
  updatedquestionName: string = '';
  updatedType: string = '';
  trackingID: string = '';
  trackingIds: string[] = [];
  selectedUpdatedRequired: boolean | null = null;
  updatedSectionDetails: any = {};
  selectedTrackingId: string = '';
  updatedLabel: string = '';
  updatedSubLabel: string = '';
  showFormFields: boolean = false;

  constructor(private InherentQuestionnaireService: InherentQuestionnaireService,
    private router: Router,) {}


  ngOnInit(): void {
    this.loadSections(); 
  }

  
  onActionChange(): void {
    if (this.selectedAction === 'add') {
      this.selectedSection = '';
      this.selectedType = '';
      this.selectedRequired = null;
      this.label = '';
      this.subLabel = '';
      this.questionName = '';
      this.trackingID = '';
      this.showFormFields = true; 
      this.loadSections();
    } else if (this.selectedAction === 'update') {
      this.selectedTrackingId = '';
      this.updatedSectionDetails = {};
      this.updatedLabel = '';
      this.updatedSubLabel = '';
      this.updatedquestionName = '';
      this.selectedUpdatedRequired = null;
      this.showFormFields = false; 
      this.fetchTrackingIds();
    }
  }
  

  loadSections(): void {
    this.InherentQuestionnaireService.irqSectionNames('data').then(
      (data) => {
        if (data?.data && Array.isArray(data.data)) {
          this.sections = [...new Set(data.data.map(item => item.sectionLabel))]
            .map(label => ({ label }));
          this.types = [...new Set(data.data.map(item => item.questionType))]
            .map(type => ({ type }));
        }
      },
      (error) => {
        console.error('Error fetching sections', error);
      }
    );
  }


  fetchTrackingIds(): void {
    this.InherentQuestionnaireService.irqTrackingId('data').then(
      (data) => {
        if (data?.trackingId && Array.isArray(data.trackingId)) {
          this.sections = data.trackingId;
          this.uniqueSections = Array.from(
            new Map(data.trackingId.map(item => [item.e_sect_name, item])).values()
          );
          this.trackingIds = data.trackingId
            .map(item => item.e_quest_tracking_id)
            .sort((a, b) => a.localeCompare(b, undefined, { numeric: true })); 
        }
      },
      (error) => {
        console.error('Error fetching tracking IDs', error);
      }
    );
  }

  
  searchSection(): void {
    const selectedSection = this.sections.find(
      section => section.e_quest_tracking_id === this.selectedTrackingId
    );

    if (selectedSection) {
      this.updatedSectionDetails = selectedSection;
      this.updatedLabel = selectedSection.e_quest_label || '';
      this.updatedSubLabel = selectedSection.e_quest_sub_label || '';
      this.updatedType = selectedSection.e_quest_type?.toLowerCase() || '';
      this.updatedquestionName = selectedSection.e_quest_name || '';
      this.selectedUpdatedRequired = selectedSection.e_quest_required ?? false;
      this.showFormFields = true;
    } else {
      this.showFormFields = false;
    }
  }


  onCancel(): void {
    this.resetForm();
  }
  
  onCancelButton(): void {
    this.resetForm();
  }
  
  resetForm(): void {
    this.selectedAction = '';
    this.showFormFields = false;
    this.selectedSection = '';
    this.selectedType = '';
    this.selectedRequired = null;
    this.label = '';
    this.subLabel = '';
    this.questionName = '';
    this.trackingID = '';
    this.selectedTrackingId = '';
    this.updatedLabel = '';
    this.updatedSubLabel = '';
    this.updatedquestionName = '';
    this.updatedType = '';
    this.selectedUpdatedRequired = null;
    this.updatedSectionDetails = {};
  }
  

  async onSubmit(): Promise<void> {
    const newQuestionData =  await this.InherentQuestionnaireService.addIrqSection({
      e_sect_label: this.selectedSection || '',
      e_quest_label: this.label || '',
      e_quest_type: this.selectedType || '',
      e_quest_sub_label: this.subLabel || '',
      e_quest_required: this.selectedRequired ?? false,
      e_quest_name: this.questionName || '',
      e_quest_tracking_id: this.trackingID || ''
    });
    this.router.navigateByUrl("/dashboard-search");
  }

  async onSubmitButton(): Promise<void> {
    const updatedSection = await this.InherentQuestionnaireService.updateIrqSection({
      e_sect_name: this.updatedSectionDetails.e_sect_name || '',
      e_quest_label: this.updatedLabel || '',
      e_quest_type: this.updatedType || '',
      e_quest_sub_label: this.updatedSubLabel || '',
      e_quest_required: this.selectedUpdatedRequired ?? false,
      e_quest_name: this.updatedquestionName || '',
      e_quest_tracking_id: this.selectedTrackingId || ''
    });
    this.router.navigateByUrl("/dashboard-search");
  }
}
