export enum IRQStatusCode {
    pendingReview = "pending review",
    materialityDetermined = "materiality determined",
    dueDiligenceCompleted = "due diligence completed"
}

export enum IRQStatusText{
    pendingReview = "Pending Review",
    materialityDetermined = "Materiality Determined",
    dueDiligenceCompleted = "Due Diligence Completed"
}
