<div *ngIf="ownToken; else loadEffect">
  <sh-alert
    [props]="notificationProps"
    *ngIf="afterStart && beforeEnd"
    style="display: block; background-color: #e1e3e4"
  ></sh-alert>
  <tsm-header></tsm-header>
  <div class="row">
    <sh-alert
      style="width: 100%"
      *ngIf="noParentSubmissionFlag"
      [props]="noParentSubmissionProps"
    ></sh-alert>
  </div>
  <div class="row">
    <sh-alert
      style="width: 100%"
      *ngIf="questionnaireConcurrentFlag"
      [props]="questionnaireConcurrentProps"
    ></sh-alert>
  </div>
  <div class="row">
    <sh-alert
      style="width: 100%"
      *ngIf="shouldShowSuccessAlert"
      [props]="successAlertProps"
    ></sh-alert>
  </div>
  <div class="row">
    <sh-alert
      style="width: 100%"
      *ngIf="questionnaireViewOnlyFlag"
      [props]="questionnaireViewOnlyProps"
    ></sh-alert>
  </div>
  <sh-alert
    [props]="saveSuccessAlertProps"
    [ngStyle]="{ display: saveSuccess ? 'block' : 'none' }"
  ></sh-alert>
  <sh-alert
    [props]="saveErrorAlertProps"
    [ngStyle]="{ display: saveError ? 'block' : 'none' }"
  ></sh-alert>
  <sh-alert [ngStyle]="{'display': riskAssessmentService?.verifyAlertVisiable ? 'block' : 'none'}" [props]="viewDetailsAlertProps"></sh-alert>
  <sh-alert [ngStyle]="{'display': bibdaScoreVisible ? 'block' : 'none'}" [props]="bibdaScoreAlertProps"></sh-alert>
  <sh-alert [ngStyle]="{'display': irqbibdaScoreVisible ? 'block' : 'none'}" [props]="irqbibdaScoreAlertProps"></sh-alert>
  <tsm-subheader *ngIf="subheaderProps" [props]="subheaderProps"></tsm-subheader>
  <router-outlet></router-outlet>
  <tsm-footer></tsm-footer>
</div>
<ng-template #loadEffect>
  <div style="min-height: 100px"></div>
  <div class="sk-chase">
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
  </div>
</ng-template>
