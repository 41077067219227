import { Component, OnInit } from '@angular/core';
import { BaseSupplierFilterComponent } from 'src/app/components/base-filter/base-supplier-filter.component';
import { FilterData, FilterType, fromToDateFilter, ReportType } from 'src/app/services/esgReport/esg-report.service';

@Component({
  selector: 'tsm-request-multiple-sa-search',
  templateUrl: './request-multiple-sa-search.component.html',
  styleUrls: ['./request-multiple-sa-search.component.sass']
})
export class RequestMultipleSaSearchComponent extends BaseSupplierFilterComponent implements OnInit {

  private filtersToApply: FilterType[] = ['supplierName', 'marketUnit', 'countryServed', 'requestorEmail', 'esgStatus', 'requestPurpose', 'esgSubmissionDateFrom', 'esgSubmissionDateTo', 'requestDateFrom', 'requestDateTo', 'includeIndirect', 'supplierCategory'];
  private emailTypes: FilterType[] = ['requestorEmail'];
  private dateTypes: fromToDateFilter[] = [{ from: 'esgSubmissionDateFrom', to: 'esgSubmissionDateTo' },
  { from: 'requestDateFrom', to: 'requestDateTo' }]
  reportType: ReportType = "supplier_records";

  private fieldErrors: { [key: string]: boolean } = {
    marketUnit: false,
    countryServed: false,
    requestorEmail:false,
    esgStatus: false,
    esgSubmissionDateFrom : false,
    esgSubmissionDateTo : false,
    requestDateFrom : false,
    requestDateTo : false,
    requestPurpose: false,
    supplierCategory: false
  };
  hasDropdownError: boolean = false;

  constructor() {
    super()
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initButtons();
    this.initFilterCriteria();
  }

  initFilterCriteria() {
    this.filterCriteria = {
      supplierName: "",
      marketUnit: null,
      countryServed: null,
      requestorEmail: "",
      esgStatus: null,
      requestPurpose: null,
      esgSubmissionDateFrom: null,
      esgSubmissionDateTo: null,
      requestDateFrom: null,
      requestDateTo: null,
      includeIndirect: false
    };
    this.filterCriteriaPayload = {
      supplierName: "",
      marketUnit: null,
      countryServed: null,
      requestorEmail: "",
      esgStatus: null,
      requestPurpose: null,
      esgSubmissionDateFrom: null,
      esgSubmissionDateTo: null,
      requestDateFrom: null,
      requestDateTo: null,
      includeIndirect: false
    }
  }

  initButtons() {
    this.clearFilterButton = {
      label: "",
      color: "purple",
      clickBorderColor: "purple",
      backgroundColor: "lightPurple",
      hoverBorderColor: "purple",
      fontSize: 16,
      height: 48,
      width: 180,
      onClick: async () => {
        this.clearFilters();
      }
    };
    this.applyFilterButton = {
      label: "",
      color: "white",
      fontSize: 16,
      backgroundColor: "blue",
      borderColor: "blue",
      height: 48,
      width: 180,
      disabled: this.hasDropdownError,
      onClick: async () => {
        this.applyFilters();
      }
    };
  }

  applyFilters() {
    this.invalidFields = [];
    this.filterCriteriaPayload = { ...this.filterCriteria };
    this.emailTypes.forEach((filter) => {
      this.checkEmail(filter);
    });

    this.dateTypes.forEach((filter) => {
      this.validateDates(filter);
      this.formatPayloadDate(filter);
    });

    if (Object.keys(this.invalidFields).length > 0) {
      return
    }
    const filterData: FilterData[] = this.filtersToApply.filter(filter => this.filterCriteriaPayload[filter])
      .map(filter => ({
        filterName: filter,
        filterValue: this.filterCriteriaPayload[filter]
      }));
    this.setFilterButtonsDisabledProps(false);
    this.applyFilterEventEmitter.emit({ reportType: this.reportType, filters: filterData });
    this.scheduleWaitingMessage();
  }

  clearFilters() {
    this.initFilterCriteria();
    this.clearFilter.next(true);
  }

    // Update the error handler to track all fields
   handleDropdownError(field: string, hasError: boolean): void {
    this.fieldErrors[field] = hasError; // Track errors for individual fields
    // Update the overall error state
    this.hasDropdownError = Object.values(this.fieldErrors).some((error) => error);
    // Update button disabled property
    this.setDisabledProps(this.hasDropdownError);
  }
  setDisabledProps(isDisabled: boolean) {
    // this.applyFilterButton.disabled = isDisabled;
    if(isDisabled){
    this.applyFilterButton = { 
      ...this.applyFilterButton,
      disabled: true,
      backgroundColor: 'mediumGrey',
      color: 'white' }; // Trigger change detection
    }
    else {
      this.applyFilterButton = {
        ...this.applyFilterButton,
        disabled: false,
        backgroundColor: 'blue',
        isProcessing: false,
        borderColor: 'blue',
    };
    }
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {        
        this.applyFilterButton.label = multilanguageJson.body.main.reportFilters.applyFilter;
        this.clearFilterButton.label = multilanguageJson.body.main.reportFilters.clearFilter;
        this.waitingMessageLabel = multilanguageJson.body.main.reportFilters.waitingMessage;
      }
    }
  }

}
