import { Injectable, inject } from "@angular/core";
import {
  ResolveFn,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";

import { InherentQuestionnaireService } from "./inherentQuestionnaire.service";
import { RiskAssessmentModel, createRaModel } from "../../models/riskAssessment";



export const InherentQuestionnaireResolverService: ResolveFn<RiskAssessmentModel> =
    async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      // return inject(AddEditClientService).getClientById();
       const irqService = inject(InherentQuestionnaireService);
    let irqCode = route.paramMap.get("code");
    let cid = route.paramMap.get("cid");
    let scid = route.paramMap.get("scid");
    let mode = route.paramMap.get("mode");
    irqService.raCode = irqCode;
    irqService.raModel = createRaModel(irqCode);

    if (mode === "0") {
      // new create supplier requestor form
      irqService.mode = "create";
      irqService.irqQuestion= await irqService.initilizeInherentQuestionnaireForm(cid,scid);
      await irqService.sortSelectResource(irqService.irqQuestion);
      let data = irqService.irqQuestion.data;
      data.forEach((record) => {
        if (record.fieldset) {
            record.fieldset = record.fieldset.filter(field => field.status === "Active");
        }
    });
    irqService.irqQuestion.data = data;
    } else if(mode==="1"){
      irqService.mode = "view";
      await irqService.loadSupplierRequestForm();
    }
     else {
      // retrieve this supplier requestor form
      irqService.mode = "edit";
      await irqService.loadSupplierRequestFormEdit();
      let data = irqService.irqQuestion.data;
      data.forEach((record) => {
        if (record.fieldset) {
            record.fieldset = record.fieldset.filter(field => field.status === "Active");
        }
    });
    irqService.irqQuestion.data = data;
    }
    return irqService.raModel;
  }

