import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../services/auth/auth.service";
import { LoginUser, Platform, Role } from "../../models/LoginUser";
import { b2cPolicies } from "../../b2c-config";
import { ReportService } from "src/app/services/authority/report.service";
import { EsgQuestionnaireService } from "@lsl16/sushub-frontend";
import { AdminBoardService } from "../../services/adminboard/admin-board.service";
import { TranslateService } from '@ngx-translate/core';
import { NgbDropdown, NgbModal, NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent, HttpService } from "@lsl16/sustainability-shared-components";
import { SupplierGeneralProfileService } from "../../services/supplier-general-profile.service";
import { HeaderEvents, HeaderService } from "src/app/services/header/header-service";
import { PopUpPromisionbuyerComponent } from "../pop-up-promisionbuyer/pop-up-promisionbuyer.component";
import { DashboardService } from "../../services/dashboard.service";
import { Subscription } from "rxjs";
import { ToolNotification } from "src/app/models/ToolNotification";
import { NotificationService } from "src/app/services/notification/notification.service";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
@Component({
    selector: "tsm-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.sass"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class HeaderComponent implements OnInit {
    // Initialize was clicked variable to false
    wasClicked = false;
    uploadlist: any = [];
    download: boolean = false;
    supportIconUrl = "assets/images/icon-faq.png";
    isSupportIconClicked = false;
    url: string;
    showTextFlag: boolean = false;
    reloadManageUser: boolean;
    deactivatedUserMessageShown: boolean = false;
    defaultProfile = "";
    roleIsDR: any;
    showSAandScoringReport: boolean = false;
    showDuplicateTaxIdSR: boolean = false;
    showSAReport: boolean = false;
    showHelp: boolean = false;
    isDelegatedRepresentative: boolean = false;
    isReportLinksAvailable: boolean;
    reportLink = {
        workspaceId: undefined,
        reportId: undefined
    };
    notifications: ToolNotification[] = [];

    languageText: string = '';
    languageName: string = '';
    authorityFileName: string = '';
    authorityFilePath: string = '';
    //supplierHeader: any;
    supplierHeader = {
        profile: '',
        logo: '',
        dashboard: '',
        report: '',
        logout: '',
        saAndScoringReport: '',
        saReport: '',
        downloadProcessReport: '',
        home: '',
        help: '',
        guide: '',
        chatbot: '',
        language: '',
        profileDetails: {
            manageUsers: '',
            viewAsBuyer: '',
            viewAsSupplier: '',
            viewAsAuthority: '',
            updatePassword: '',
            updatePhoneNumber: '',
            bulkUploadTemplate: '',
        }
    };
    successAlertVisible: boolean;
    failureAlertVisible: boolean;
    loading: boolean = false;
    time: any;
    spainish: boolean = false;
    portuguese: boolean = false;
    french: boolean = false;
    italiano: boolean = false;
    chinese: boolean = false;
    german: boolean = false;
    japanese: boolean = false;
    brazil: boolean = false;
    latamspanish: boolean = false;
    public notificationCount: number;
    public currentRaRole;
    private getCurrentCountryServed?: Subscription;
    private getCSFromQuestionnaire?: Subscription;
    private generalProfileServiceEventSubscription?: Subscription;
    private notificationEventSubscription?: Subscription;
    private notificationDropdownSubscription?: Subscription;
    raRoles = {
        scm: 'scm',
        srdp: 'srdp',
        prisk: 'prisk',
        srsa: 'srsa',
        srra: 'srra'
    }
    raRoleCodes = {
        scm: 'BR_07',
        srdp: 'BR_08',
        prisk: 'BR_09',
        srsa: 'BR_10',
        srra: 'BR_11'
    }
    @ViewChild('notificationDropdownRef', { static: false, read: NgbDropdown }) notificationDropdown: NgbDropdown;
    @ViewChild('profileDropdownRef') profileDropdownRef: NgbDropdown;
    
    isReportTooltipOpen = false;
    isProfileDropdownOpen = false;
    isDropdownOpen = false;
    isLanguageDropdownOpen = false;
    ra_flag: any;
    biRole: any;
    biRoles = {
        bi: 'BI'
    }

    constructor(
        private router: Router,
        private authService: AuthService,
        private reportService: ReportService,
        private esgQuestionnaireService: EsgQuestionnaireService,
        private adminBoardService: AdminBoardService,
        private cdRef: ChangeDetectorRef,
        private translate: TranslateService,
        private ngbModalService: NgbModal,
        private supplierGeneralProfileService: SupplierGeneralProfileService,
        private headerService: HeaderService,
        private dashboardService: DashboardService,
        private notificationService: NotificationService,
        private httpService: HttpService,
        private http: HttpClient,
        private activatedRoute: ActivatedRoute
    ) {

        const language = localStorage.getItem("current language");
        let role = this.authService.getRole()

        if (null != language && role === 'supplier') {
            this.translate.use(language);
        } else {
            this.translate.use('english');
        }
        this.translate.get('supplier.body.header').subscribe((res) => {
            this.supplierHeader = res;
        })
        this.translate.get('supplier').subscribe({
            next: (res: string) => {
                localStorage.setItem("multiLanguage", JSON.stringify(res)
                )
            }
        });
        let title;
        let publicMassage;
        this.translate.get('supplier.questionnire.title').subscribe({
            next: (res: any) => {
                title = res
                localStorage.setItem(
                    "title", JSON.stringify(res)
                )
            }
        });
        this.translate.get('supplier.questionnire.publicMassage').subscribe({
            next: (res: any) => {
                publicMassage = res
                localStorage.setItem(
                    "publicMassage", JSON.stringify(res)
                )
            }
        });
        this.translate.get('supplier.questionnire.Getting Started').subscribe({
            next: (res: string) => {
                localStorage.setItem(
                    "Getting Started", JSON.stringify(Object.assign(res, publicMassage, title))
                )
            }
        });
        this.translate.get('supplier.questionnire.Environment').subscribe({
            next: (res: any) => {
                localStorage.setItem(
                    "Environment", JSON.stringify(Object.assign(res, publicMassage, title))
                )
            }
        });
        this.translate.get('supplier.questionnire.Human Rights & Social Impact').subscribe({
            next: (res: any) => {
                localStorage.setItem(
                    "Human Rights & Social Impact", JSON.stringify(Object.assign(res, publicMassage, title))
                )
            }
        });
        this.translate.get('supplier.questionnire.Supplier Inclusion & Diversity').subscribe({
            next: (res: any) => {
                localStorage.setItem(
                    "Supplier Inclusion & Diversity", JSON.stringify(Object.assign(res, publicMassage, title))
                )
            }
        });
        this.translate.get('supplier.questionnire.Ethics & Sustainability Management').subscribe({
            next: (res: any) => {
                localStorage.setItem(
                    "Ethics & Sustainability Management", JSON.stringify(Object.assign(res, publicMassage, title))
                )
            }
        });

    }

    async ngOnInit(): Promise<void> {
        if (this.isNotificationsRequired()) {
            this.loadNotifications();
        }
        const {procurementSupport} = JSON.parse(localStorage.getItem("externalEmails")) ?? {};
        if(procurementSupport === undefined){
            await this.authService.setExternalEmails();
        }
        this.subscribeEvents();
        this.refresh();
        await this.determineDashboardAccess();
        this.translate.get('supplier.body.header').subscribe((res) => {
            this.supplierHeader = res;
        })
        const baseUrl = `${environment.tsmBackendServiceURL}/document/query/authority`;
        this.httpService.GetPromise(baseUrl).then((res: any) => {
            this.uploadlist = res.data
            if (this.uploadlist) {
                for (let i = 0; i < this.uploadlist.length; i++) {
                    if (this.uploadlist[i].status === "2") {
                        this.download = true
                    }
                }
            }
        })
        this.activatedRoute.queryParams.subscribe(params => {
            let urlLanguage = params['language'];
            if (urlLanguage) {
                let selectedLanguage = urlLanguage.toString()
                this.switchLanguage(selectedLanguage)
            }
        });

        //Below code make sure that, for Sustainability expert login, role should be same before and after refresh.
        if(this.authService.isSE()){
            if(localStorage.getItem('SustainabilityExpert_Role')){
                let seRole =  localStorage.getItem('SustainabilityExpert_Role');
                let cRole = this.authService.getRole();
                if( cRole!== seRole){
                    if(seRole == 'authority'){
                        this.setCurrentView('authority','authority');
                    }
                    else if(seRole == 'buyer'){
                        this.setCurrentView('buyer','buyer');
                    }
                }
            }
        }

        if(localStorage.getItem('RA_flag')){
            this.ra_flag = localStorage.getItem('RA_flag');
        }
    }

    ngAfterViewInit() {
        this.notificationDropdownSubscription = this.notificationDropdown.openChange.subscribe(() => {
            this.notificationService.emitNotificationEvent('resetMode');
        })
    }

    private subscribeEvents() {
        this.generalProfileServiceEventSubscription = this.supplierGeneralProfileService.eventEmit.subscribe((e) => {
            if (e === 'refreshPocProfilePermission') {
                this.refresh();
            }
        });

        this.getCurrentCountryServed = this.supplierGeneralProfileService.eventEmitForCS.subscribe((e) => {
            this.languageDisplayMethod(e);
        });

        this.getCSFromQuestionnaire = this.esgQuestionnaireService.eventEmitToHeaderCS.subscribe((e) => {
            this.languageDisplayMethod(e);
        });

        this.notificationEventSubscription = this.notificationService.getRefreshNotificationsEvent().subscribe(async (e) => {
            switch (e.name) {
                case "delete":
                case "refresh":
                    await this.loadNotifications();
                    break;
                case "closeNotification":
                    this.closeNotificationPanel();
                    break;
            }
        });
    }

    private async loadNotifications() {
        this.notifications = await this.notificationService.getToolNotifications();
        this.notificationCount = this.notifications.length;
        this.cdRef.detectChanges();
    }
    languageDisplayMethod(e) {
        let currentLanguage = localStorage.getItem('current language');
        let array = [];
        this.spainish = false;
        this.portuguese = false;
        this.french = false;
        this.italiano = false;
        this.chinese = false;
        this.japanese = false;
        this.german = false;
        this.brazil = false;
        this.latamspanish = false;
        if (e instanceof Array) {
            array = e
        } else {
            array.push(e);
        }
        array.forEach(element => {
            switch (element) {
                case 'ES':
                case 'AD':
                    this.spainish = true;
                    break;
                case 'PT':
                    this.portuguese = true;
                    break;
                case 'FR':
                case 'BE':
                case 'LU':
                case 'MA':
                case 'VN':
                    this.french = true;
                    break;
                case 'IT':
                    this.italiano = true;
                    break;
                case 'HK':
                case 'TW':
                case 'CN':
                    this.chinese = true;
                    break;
                case 'AT':
                case 'CH':
                case 'DE':
                case 'RS':
                    this.german = true;
                    break;
                case 'JP':
                    this.japanese = true;
                    break;
                case 'AR':
                case 'EC':
                case 'CR':
                case 'PE':
                case 'CO':
                case 'MX':
                case 'CL':
                case 'BR':
                    this.brazil = true;
                    this.latamspanish = true
                    break;
                default:
                    break;
            }
        });
        if (this[currentLanguage] != true) {
            this.switchLanguage('english')
        }
    }
    resetLanguageList(e, num) {
        localStorage.setItem('currentCountryServed', e);
        let currentLanguage = localStorage.getItem('current language');
        switch (e) {
            case 'ES':
            case 'AD':
                this.languageText = 'spainish';
                this.languageName = 'Español';
                break;
            case 'PT':
                this.languageText = 'portuguese';
                this.languageName = 'Português';
                break;
            case 'FR':
            case 'BE':
            case 'LU':
            case 'MA':
            case 'VN':
                this.languageText = 'french';
                this.languageName = 'Français';
                break;
            case 'IT':
                this.languageText = 'italiano';
                this.languageName = 'Italiano';
                break;
            case 'HK':
            case 'TW':
            case 'CN':
                this.languageText = 'chinese';
                this.languageName = '中文';
                break;
            case 'AT':
            case 'CH':
            case 'DE':
            case 'RS':
                this.languageText = 'german';
                this.languageName = 'Deutsch';
                break;
            case 'JP':
                this.languageText = 'japanese';
                this.languageName = '日本語';
                break;
            case 'AR':
            case 'EC':
            case 'CR':
            case 'PE':
            case 'CO':
            case 'MX':
            case 'CL':
            case 'BR':
                this.languageText = 'brazil';
                this.languageName = 'brazil';
                this.languageText = 'latamspanish';
                this.languageName = 'LATAM Spanish';
                break;
            default:
                this.languageText = '';
                this.languageName = '';
                break;
        }
        if (num == 1 && (currentLanguage != this.languageText || currentLanguage == '')) {
            this.switchLanguage('english')
        }
    }

    private refresh() {
        this.defaultProfile = this.authService.getLoginUser().defaultProfile;
        this.reloadManageUser = this.isCurrentProfileAdmin()
        this.hasReportAccess();
    }

    private hasReportAccess(): void {
        const isAuthority = this.authService.getRole() === 'authority';
        const isGeneralBuyerUser = this.authService.isGeneralBuyerUser();
        const isSystemSupplierAdmin = this.authService.isSystemSupplierAdmin();
        const isBuyerExpert = this.authService.isBuyerExpert();
        const isRequestorAdmin = this.authService.isRequestorAdmin();
        const isSustainabilityExpert = this.authService.isSustainabilityExpert();
        const isDelegatedRepresentative = this.authService.isDelegatedRepresentative();
        const isSystemAdmin = this.authService.isSystemAdmin();

        const isValidBuyer = isGeneralBuyerUser || isSystemSupplierAdmin || isBuyerExpert;
        const isValidAuthority = isSustainabilityExpert || isDelegatedRepresentative;

        this.showSAandScoringReport = isSystemAdmin || isValidBuyer || isValidAuthority;
        this.showDuplicateTaxIdSR = isBuyerExpert;
        this.showHelp = isSystemAdmin || isValidBuyer || isValidAuthority || isRequestorAdmin;
        this.isDelegatedRepresentative = isDelegatedRepresentative;
        this.showSAReport = isAuthority;
    }

    private async determineDashboardAccess(): Promise<void> {
        try {
            if (this.authService.getRole() !== 'supplier') {
                this.reportLink = await this.dashboardService.getReportLinks();
                this.isReportLinksAvailable = (this.reportLink?.workspaceId && this.reportLink?.reportId) ? true : false;
                this.cdRef.detectChanges();
            }
        } catch {
            this.isReportLinksAvailable = false;
        }
    }

    isCurrentProfileAdmin(): boolean {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (JSON.parse(userInfo.extension_tsm_TSM)[2].includes(2)) return true;
        } catch (err) {
            return false;
        }
        return false;
    }

    ngAfterContentChecked(): void {
        if (this.authService.isUserDeactivated() && !this.deactivatedUserMessageShown) {
            this.deactivatedUserMessageShown = true;
            if (!this.ngbModalService.hasOpenModals()) {
                this.deactivatedUserMessage();
            }
        }
        if (localStorage.getItem("multiLanguage")) {
            const multilanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
            if (multilanguageJson && multilanguageJson.body.header) {
                this.supplierHeader = multilanguageJson.body.header
            }
        }
        this.cdRef?.detectChanges();
    }

    recognizeDR(loginUser: any) {
        let role_sph = loginUser.extension_tsm_SPH.split('"')[6];
        let role_tsm = loginUser.extension_tsm_TSM.split('"')[6];
        let spd_dr = role_sph.indexOf('1') == -1 && role_sph.indexOf('2') == -1 && role_sph.indexOf('3') != -1;
        let tsm_dr = role_tsm.indexOf('1') == -1 && role_tsm.indexOf('2') == -1 && role_tsm.indexOf('3') != -1;
        this.roleIsDR = spd_dr && tsm_dr;
        return this.roleIsDR;
    }

    grantSupplierAdminReloadSubscription(): void {
        this.adminBoardService.eventEmit.subscribe(
            (event) => {
                if (event === "grant-supplier-admin-reload") {
                    this.reloadManageUser = false;
                    this.cdRef.detectChanges();
                    this.reloadManageUser = true;
                }
            }
        );
    }
    /**
     * Description: Monitors the openChange emitter to change dropdown caret styling
     */
    openChange(): void {
        this.wasClicked = !this.wasClicked;
    }

    /**
     * Description: Links back to the base url
     */
    backToLanding(): void {
        let role = this.headerRole();
        if (role === 'supplier') {
            this.router.navigateByUrl("/");
        } else {
            sessionStorage.setItem('useCache', 'false')
            sessionStorage.setItem('dashBoardSearchScrollTop', "0")
            this.router.navigateByUrl("/dashboard-search");
        }
        this.esgQuestionnaireService.eventEmit.emit("viewOnlyAlertClose");
    }

    get loginUser(): LoginUser {
        return this.authService.getLoginUser();
    }

    logout(): void {
        const profile = JSON.parse(localStorage.getItem("userInfo"))
        if (localStorage.getItem(profile.tsmId)) {
            localStorage.removeItem(profile.tsmId)
        }
        localStorage.setItem("current language", "english");
        this.authService.logout();
    }

    /**
     * Description: Links to the admin board
     * according to different role go to different page
     */
    toAdminBoard(): void {
        // go to supplier manage user page with supplier identify
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        const currentSupplier = this.loginUser.tsmId;
        if (currentSupplier) {
            this.router.navigateByUrl(`/supplier-admin-board?supplierTsmId=${currentSupplier}`);
        } else {
            this.router.navigateByUrl(`/supplier-admin-board`);
        }
    }

    goByerHelp(): void {
        this.router.navigateByUrl("/buyer-help");
    }

    toBulkUpload(): void {
        this.router.navigateByUrl("/help-support-new");
    }
    toMergeProfile(): void {
        this.router.navigateByUrl("/create-merge-profile");
    }

    toActions(): void {
        this.router.navigateByUrl("/questionnaire-maintenance-page");
    }

    toTerminationRequestBoard(): void {
        this.router.navigateByUrl("/termination-request-board");
    }
    toManageEmailTemplates(): void {
        this.router.navigateByUrl("/email-template-manager");
    }
    toManageRequestSA(): void {
        this.router.navigateByUrl("/manage-updates-approval-board");
    }
    toSystemAdminBoard(): void {
        this.router.navigateByUrl("/system-admin-board");
    }

    toSystemAdminCountry(): void {
        this.router.navigateByUrl("/country-served-view");
    }
    toDocumentManagement(): void {
        this.router.navigateByUrl("/document-management");
    }

    toSusAssessmentReport(): void {
        this.router.navigateByUrl("/sustainability-assessment-report");
    }

    toDownloadReport(): void {
        this.router.navigateByUrl("/sustainability-process-report");
    }
    toDownloadReportBuyerExp(): void {
        this.loading = true;
        this.time = setInterval(this.downloadReports, 10000)
    }
    downloadReports = async () => {
        var values: any = {};
        values = {
            reportType: "duplicateProfile"
        }
        let uploadResponse = await this.reportService.createReportBuyerExp();
        if (uploadResponse.msg === "Success" && uploadResponse.data.status === "created") {
            this.stopDownload()
            await this.reportService.downloadProcessDumpFileBuyerExp(uploadResponse.data.s3_url, values.reportType);
            this.successAlertVisible = true
            this.failureAlertVisible = false
            this.loading = false;
        } else if (uploadResponse.msg === "Failed") {
            this.failureAlertVisible = true
            this.successAlertVisible = false
            this.loading = false;
        }
    }
    stopDownload = () => {
        if (this.time) {
            clearInterval(this.time);
        }
    }

    toSAandScoringReport(): void {
        this.router.navigateByUrl("/sa-scoring-report");
    }
    toRAReport(): void {
        this.router.navigateByUrl("/ra-report");
    }

    getAllowedRoles = () => {
        let platform: Platform;

        platform = this.authService.getCurrentPlatform();

        return this.authService.getAllowedRoles(platform);
    };
    getBIRole = () => {
        if(this.authService.isBI()){
            this.biRole = this.biRoles.bi;
        }
        return this.biRole;
    }

    // getRiskAssessmentRoles = () => {
    //     let raRoles = [];
    //     if(JSON.parse(localStorage.getItem("userInfo")).extension_tsm_Roles){
    //         let newRoles = JSON.parse((JSON.parse(localStorage.getItem("userInfo")).extension_tsm_Roles));
    //         if(newRoles.includes(this.raRoleCodes.scm)) {
    //             raRoles.push(this.raRoles.scm);
    //         }
    //         if(newRoles.includes(this.raRoleCodes.srdp)) {
    //             raRoles.push(this.raRoles.srdp);
    //         }
    //         if(newRoles.includes(this.raRoleCodes.prisk)) {
    //             raRoles.push(this.raRoles.prisk);
    //         }
    //         if(newRoles.includes(this.raRoleCodes.srsa)) {
    //             raRoles.push(this.raRoles.srsa);
    //         }
    //         if(newRoles.includes(this.raRoleCodes.srra)) {
    //             raRoles.push(this.raRoles.srra);
    //         }
    //     }
    //     return raRoles;
    // }

    getRiskAssessmentRoles = () => {
        let raRoles = [];
        if (this.authService.isScm()) {
            raRoles.push(this.raRoles.scm);
        }
        if (this.authService.isSrdp()) {
            raRoles.push(this.raRoles.srdp);
        }
        if (this.authService.isPrisk()) {
            raRoles.push(this.raRoles.prisk);
        }
        if (this.authService.isSrsa()) {
            raRoles.push(this.raRoles.srsa);
        }
        if (this.authService.isSrra()) {
            raRoles.push(this.raRoles.srra);
        }
        return raRoles;
    }

    isSystemAdmin = () => this.authService.isSystemAdmin();

    setCurrentView = async (role: Role, raRole: any): Promise<void> => {
        this.currentRaRole = raRole;
        localStorage.setItem("RA_flag", this.currentRaRole);
        this.authService.setCurrentRole(role);

        //We are stoging the current role in local storage for sustainabilityexpert login
        if(this.authService.isSE()){ 
            localStorage.setItem("SustainabilityExpert_Role", role);    
        }

        await this.authService.handleButtonPermission()

        let platform = this.authService.getCurrentPlatform();

        if (platform === "sph" && role === "buyer") {
            this.backToLanding();
        }
        await this.router.navigateByUrl("/");
        window.location.reload()
    };

    goSupport = () => {
        this.router.navigateByUrl("/supplier-support");
    };

    goHome = () => {
        this.router.navigateByUrl("/");
    };

    goChatbot = () => {
        window.open("https://procurementplushelp.accenture.com/", "_blank");
    }

    goDashboard = async () => {
        this.router.navigate(['powerbi-dashboard']);
    }

    toRequestMultipleSa(): void {
        this.router.navigateByUrl("/request-multiple-sa");
    }
    toCreateBulkTsmProfiles(): void {
         this.router.navigateByUrl("/create-bulk-tsm-profiles/0");
    }
    toRetriggerSa(): void {
        this.router.navigateByUrl("/sa-retriggering");
    }
    updatePassword = () => {
        let resetPasswordFlowRequest = {
            scopes: ["openid"],
            authority: b2cPolicies.authorities.updatePassword.authority
        };
        this.authService.login(resetPasswordFlowRequest);
    };

    editProfile = () => {
        let editProfileFlowRequest = {
            scopes: ["openid"],
            authority: b2cPolicies.authorities.updatePhoneNumber.authority
        };
        this.authService.login(editProfileFlowRequest);
    };

    downloadReport = async () => {
        if (this.authService.isUserDeactivated()) {
            window.alert("You don't have the permission to perform this action.");
        } else {
            let response = await this.reportService.downloadFile();
        }
    };

    showText(): void {
        this.showTextFlag = true;
    }

    hideText(): void {
        this.showTextFlag = false;
    }

    headerRole(): string {
        return this.authService.getRole();
    }

    isNotificationsRequired(): boolean {
        const currRole = this.headerRole();
        return (currRole === 'supplier' || currRole === 'buyer' || currRole === 'authority');
    }
    toggleNotifications(event: Event) {
        event.preventDefault();
        this.isDropdownOpen = !this.isDropdownOpen;
    }

    toggleProfileDropdown(event: Event) {
        event.preventDefault();
        this.isProfileDropdownOpen = !this.isProfileDropdownOpen;
    }

    toggleLanguageDropdown(event: Event) {
        event.preventDefault();
        this.isLanguageDropdownOpen = !this.isLanguageDropdownOpen;
    }

    toggleDropdown(popover: NgbTooltip, event: Event): void {
        if (event instanceof KeyboardEvent && event.key === 'Tab') {
            return;
        }
        this.isReportTooltipOpen = !this.isReportTooltipOpen;
        if (this.isReportTooltipOpen) {
            popover.open();
        } else {
            popover.close();
        }
        event.preventDefault();
    }

    closeDropdownOnEnter(event: KeyboardEvent, popover: NgbTooltip): void {
        if (event.key === 'Enter') {
            this.isReportTooltipOpen = false;
            popover.close();
        }
    }

    closeDropdownSA(popover: NgbTooltip): void {
        let isAuthority = this.authService.getRole() === 'authority';
        if (!this.authService.isBuyerExpert() && !isAuthority&& !(this.authService.isBI() || this.authService.isScm ||
         this.authService.isSrdp || this.authService.isPrisk)) {
            this.isReportTooltipOpen = false;
            popover.close();
        }
    }
    closeDropdownRA(popover: NgbTooltip): void {
        if (this.authService.isBI() || this.authService.isScm || this.authService.isSrdp || this.authService.isPrisk) {
            this.isReportTooltipOpen = false;
            popover.close();
        }
    }

    closeDropdownBuyerExpert(popover: NgbTooltip): void {
        if (this.authService.isBuyerExpert()) {
            this.isReportTooltipOpen = false;
            popover.close();
        }
    }
    closeDropdownAuthority(popover: NgbTooltip): void {
        this.isReportTooltipOpen = false;
        popover.close();
    }

    switchLanguage(language: string) {
        this.translate.use(language);
        localStorage.setItem("current language", language);
        this.translate.get('supplier').subscribe({
            next: (res: string) => {
                localStorage.setItem(
                    "multiLanguage", JSON.stringify(res)
                );
                const multilanguageJson = JSON.parse(JSON.stringify(res));
                if (multilanguageJson && multilanguageJson.body.header) {
                    this.supplierHeader = multilanguageJson.body.header
                    this.cdRef.markForCheck();
                    this.cdRef.detectChanges();
                }

                this.headerService.eventEmit.emit(HeaderEvents.multiLanguageChanged);
            }
        });
        let title;
        let publicMassage;
        this.translate.get('supplier.questionnire.title').subscribe({
            next: (res: any) => {
                title = res
                localStorage.setItem(
                    "title", JSON.stringify(res)
                )
            }
        });
        this.translate.get('supplier.questionnire.publicMassage').subscribe({
            next: (res: any) => {
                publicMassage = res
                localStorage.setItem(
                    "publicMassage", JSON.stringify(res)
                )
            }
        });
        this.translate.get('supplier.questionnire.Getting Started').subscribe({
            next: (res: string) => {
                localStorage.setItem(
                    "Getting Started", JSON.stringify(Object.assign(res, publicMassage, title))
                )
            }
        });
        this.translate.get('supplier.questionnire.Environment').subscribe({
            next: (res: any) => {
                localStorage.setItem(
                    "Environment", JSON.stringify(Object.assign(res, publicMassage, title))
                )
            }
        });
        this.translate.get('supplier.questionnire.Human Rights & Social Impact').subscribe({
            next: (res: any) => {
                localStorage.setItem(
                    "Human Rights & Social Impact", JSON.stringify(Object.assign(res, publicMassage, title))
                )
            }
        });
        this.translate.get('supplier.questionnire.Supplier Inclusion & Diversity').subscribe({
            next: (res: any) => {
                localStorage.setItem(
                    "Supplier Inclusion & Diversity", JSON.stringify(Object.assign(res, publicMassage, title))
                )
            }
        });
        this.translate.get('supplier.questionnire.Ethics & Sustainability Management').subscribe({
            next: (res: any) => {
                localStorage.setItem(
                    "Ethics & Sustainability Management", JSON.stringify(Object.assign(res, publicMassage, title))
                )
            }
        });
    }

    private deactivatedUserMessage() {
        const modalReference = this.ngbModalService.open(
            ModalComponent,
            {}
        );
        let translateData = {
            title: "No Permissions",
            iconUrl: "assets/icons/info.svg",
            buttonText: "Close",
            content: "<p>You don't have the required permissions. Please contact your System Administrator for help.<p>",
            headerCloseButton: true
        };
        this.translate.get('supplier.popUp.deactivatedUser').subscribe({
            next: (res: string) => {
                let data = JSON.parse(JSON.stringify(res));
                translateData.title = data.title;
                translateData.buttonText = data.buttonText;
                translateData.content = data.content;
            }
        });
        modalReference.componentInstance.modalData = translateData;
        modalReference.result.then(() => {
            if (!this.authService.isSystemAdmin()) {
                this.ngbModalService.open(PopUpPromisionbuyerComponent, {
                    centered: true,
                    size: "lg",
                    scrollable: true,
                    windowClass: "countdown-modal",
                    backdropClass: "modal-custom-backdrop",
                    backdrop: "static",
                    keyboard: false
                });
            }
        })
    }

    private closeNotificationPanel() {
        this.notificationDropdown.close();
    }

    ngOnDestroy(): void {
        this.unsubscribeEvents();
    }

    getNotificationStyling(): string {
        let classname: string;
        const role = this.authService.getRole();
        const lang = localStorage.getItem('current language');
        switch (role) {
            case 'authority': {
                classname = 'dropdown-notification-authority';
                break;
            }
            case 'buyer': {
                classname = 'dropdown-notification-buyer';
                break;
            }
            case 'supplier': {
                classname = 'dropdown-notification-supplier';
                break;
            }
            default: {
                classname = '';
                break;
            }
        }

        switch (lang) {
            case 'spainish':
                classname = classname.concat(' spainish');
                break;
            case 'portuguese':
                classname = classname.concat(' portuguese');
                break;
            case 'french':
                classname = classname.concat(' french');
                break;
            case 'italiano':
                classname = classname.concat(' italiano');
                break;
            case 'chinese':
                classname = classname.concat(' chinese');
                break;
        }

        return classname;
    }

    private unsubscribeEvents() {
        if (this.generalProfileServiceEventSubscription) {
            this.generalProfileServiceEventSubscription.unsubscribe();
        }

        if (this.getCurrentCountryServed) {
            this.getCurrentCountryServed.unsubscribe();
        }

        if (this.notificationEventSubscription) {
            this.notificationEventSubscription.unsubscribe();
        }

        if (this.notificationDropdownSubscription) {
            this.notificationDropdownSubscription.unsubscribe();
        }

        if (this.getCSFromQuestionnaire) {
            this.getCSFromQuestionnaire.unsubscribe();
        }
    }

    async downloadGuideFile() {
        if (this.download) {
            if (this.uploadlist) {
                for (let i = 0; i < this.uploadlist.length; i++) {
                    if (this.uploadlist[i].status === "2") {
                        this.downLoad(this.uploadlist[i])
                    }
                }
            }
        }
    }
    async downLoad(item) {
        const baseUrl = `${environment.tsmBackendServiceURL}/document/download/${item.documentId}`;
        const file = await this.http.get(baseUrl, { responseType: "blob" }).toPromise();
        const link = document.createElement('a')
        let blob = new Blob([file], { type: 'application/pdf' });
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob);
        link.target = '_blank';
        link.setAttribute('download', item.fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }
}
