<div *ngIf="featureFlagIRQ" class="assesment mb-12 shadow rounded-0 position-relative border irq-dashboard">
    <div class="row ms-3">
        <div class="col-12 col-md-8">
            <div class="title-container mt-3">
                <div class="title">Inherent Risk Questionnaire</div>
                <div class="sub_title_name">Identify the risks related to a new engagement with this supplier.</div>
            </div>
        </div>
        <div class="col-4">
            <sh-button class="fl_right me-3 mt-3" *ngIf="statusFlag" [props]="requestIRQButton">Start New Inherent Risk Questionnaire</sh-button>
        </div>
    </div>
    <ng-container *ngIf="isCancelled" class="modal-cancelled">
        <div class="modal-overlay"></div>
        <div class="modal">
          <div class="modal-header">
            <h5 class="modal-title">Cancel IRQ</h5>
          </div>
          <div class="modal-body">
            <div class="modal-body-content">
              <p class="success-cancel">✔ IRQ is cancelled successfully.</p>
            </div>
          </div>
          <div class="modal-footer flex-column">
            <button (click)="closeModal()" class="closeButton">Close</button>
          </div>
        </div>
      </ng-container>
      
      <ng-container *ngIf="isCancelFailed" class="modal-not-cancelled">
        <div class="modal-overlay"></div>
        <div class="modal">
          <div class="modal-header">
            <h5 class="modal-title">Cancel IRQ</h5>
          </div>
          <div class="modal-body">
            <div class="modal-body-content">
              <p class="failed-cancel">❌ IRQ was not cancelled.</p>
            </div>
          </div>
          <div class="modal-footer flex-column">
            <button (click)="closeModal()" class="closeButton">Close</button>
          </div>
        </div>
      </ng-container>
    <div class="irq-table" *ngIf="filterFlag">
        <div class="row mt-3  ms-3 ">
            <div class="col">
                <div class="row">
                    <div class="col-12 display-flex">
                        <label class="select-label" for="marketUnit_bvRisk">Select MU</label>
                        <div class="col-7 ps-0">
                            <tsm-select-input id="marketUnit_bvRisk"
                                [drapDownData]="tempMarketUnit" 
                                [multiple] = "true"  
                                [placeholder]="'Select'"
                                (multipleDateSend)="saveSectionEvent($event, 'marketUnit')">
                            </tsm-select-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col-12 display-flex">
                        <label class="select-label" for="countryLocation">Select Country/ies</label>
                        <div class="col-7 ps-0">
                            <tsm-select-input
                                id="tempLocationRisk"
                                [drapDownData]="tempLocation" 
                                [refresh]="true"
                                [multiple] = "true"  
                                [placeholder]="'Select'"
                                (multipleDateSend)="saveSectionEvent($event, 'countryLocation')">
                            </tsm-select-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col-12 display-flex">
                        <label class="select-label" for="categoryRisk">Category</label>
                        <div class="col-8 ps-0">
                            <tsm-select-input id="categoryRisk"
                                [drapDownData]="tempCategory" 
                                [refresh]="true"
                                [multiple] = "true"  
                                [placeholder]="'Select'"
                                (multipleDateSend)="saveSectionEvent($event, 'category')">
                            </tsm-select-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col-12 display-flex">
                        <label class="select-label" for="subCategoryRisk">Sub category</label>
                        <div class="col-8 ps-0">
                            <tsm-select-input id="subCategoryRisk"
                                [drapDownData]="tempSubCategory" 
                                [refresh]="true"
                                [multiple] = "true"  
                                [placeholder]="'Select'"
                                (multipleDateSend)="saveSectionEvent($event, 'subCategory')">
                            </tsm-select-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col-12 display-flex">
                        <label class="select-label" for="IRQStatus">Status</label>
                        <div class="col-8 ps-0">
                            <tsm-select-input id="IRQStatus"
                                [drapDownData]="tempIRQStatus" 
                                [refresh]="true"
                                [multiple] = "true"  
                                [placeholder]="'Select'"
                                (multipleDateSend)="saveSectionEvent($event, 'IRQStatus')">
                            </tsm-select-input>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="irq-table-scrollable" *ngIf="filterFlag">
            <div class="row mt-3  ms-3 table-header">
            <div class="col-action col-common table-overflow">ACTIONS</div>
            <div class="col-marketunit col-common table-overflow">MARKET UNIT</div>
            <div class="col-countryserved col-common table-overflow">COUNTRIES SERVED</div>
            <div class="col-category col-common table-overflow">CATEGORY</div>
            <div class="col-category col-common table-overflow">SUB-CATEGORY</div>
            <div class="col-date col-common table-overflow">SUBMISSION DATE</div>
            <div class="col-description col-common table-overflow">SHORT DESCRIPTION</div>
            <div class="col-irq-id col-common table-overflow">INHERENT RISK QUESTIONNAIRE ID</div>
            <div class="col-category col-common table-overflow">BI/BDA STATUS</div>
            <div class="col-relnumber col-common table-overflow">COUPA RELATIONSHIP NUMBER</div>
            <div class="col-isriskscore col-common table-overflow">INFORMATION SECURITY INHERENT RISK RATING</div>
            <div class="col-datariskscore col-common table-overflow">DATA PRIVACY INHERENT RISK RATING</div>
            <div class="col-requestor col-common table-overflow">REQUESTOR NAME</div>
        </div>
        <div class="table-overflow">
            <div>
                <div tabindex="0" class="row mt-3  ms-3" *ngFor="let assessment of exhibitAssessmentList">
                    
                    <div class="col-action col-common col-font-common">
                        <div class="text-overflow" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body">
                            <!------Action Start-------->
                            <img src="assets/icons/Options_Icon.svg" alt="icon-info"
                                class="icon-mt fl_right icon-option rotate_h" tooltipClass="manage-user-tooltip-class"
                                triggers="click:blur" [ngbTooltip]="tipActions" placement="bottom-left"
                                container="body" />
                            <ng-template #tipActions>
                                <div class="actions-tooltip">
                                    <p *ngIf="((assessment.isDraft == 'false' || assessment.isDraft == 'notapplicable' || (assessment.isDraft == 'true' && (isRequestor(assessment) || hasWriteAccess(assessment)))) && (hasViewAccess(assessment) || isRequestor(assessment) || hasWriteAccess(assessment))); else elseBlock">Available actions: </p>
                                    <ng-template #elseBlock>
                                        <p>No available action</p>
                                    </ng-template>
                                    <a *ngIf="(assessment.isDraft == 'false' || assessment.isDraft == 'notapplicable') && (hasViewAccess(assessment) || isRequestor(assessment) || hasWriteAccess(assessment))" class="" (click)="triggerViewDetails(assessment)">View details </a>
                                    <a *ngIf="assessment.isDraft == 'true' && (isRequestor(assessment) || hasWriteAccess(assessment))" (click)="triggerDraft(assessment)" class="">Continue </a>
                                    <a *ngIf="assessment.isDraft == 'true' && (isRequestor(assessment))" (click)="openModal(assessment.raId)" class="">Cancel IRQ</a>
                                    <a *ngIf="assessment.isDraft == 'false' && (hasViewAccess(assessment) || isRequestor(assessment) || hasWriteAccess(assessment))" (click)="downloadReport(assessment)" class="">Export data</a>
                                    <a *ngIf="assessment.isDraft == 'false' &&  isRequestor(assessment)" (click)="linkIrq(assessment)" class="">Link IRQ to Onboarding</a>
                                </div>
                            </ng-template>
                            <!------Action End-------->
                        </div>
                    </div>
                    <ng-container *ngIf="isModalVisible">
                        <div class="modal-overlay"></div>
                        <div class="modal">
                          <div class="modal-header">
                            <h5 class="modal-title">Cancel IRQ</h5>
                          </div>
                          <div class="modal-body">
                            <div class="modal-body-content">
                              <p>Are you sure you want to Cancel this IRQ?</p>
                              <div class="form-group">
                                <label for="reason">Cancellation Reason<span class="required">*</span>:</label>
                                <select [(ngModel)]="cancel_reason" id="reason" (change)="reasonError = false">
                                  <option *ngFor="let reason of cancellationReasons" [value]="reason">{{reason}}</option>
                                </select>
                              </div>
                              <div *ngIf="reasonError" class="error-message">Please select a cancellation reason.</div>
                                <br><div class="form-group">
                                <label for="comments">Comments<span class="required">*</span>:</label>
                                <textarea [(ngModel)]="cancel_comment" id="comments" rows="3" (change)="commentError = false" maxlength="500"></textarea>
                              </div>
                              <div *ngIf="commentError" class="error-message">Please enter comments.</div>
                            </div>
                          </div>
                          <div class="modal-footer">
                            <button class="noButton" (click)="closeModal()">No</button>
                            <button class="yesButton" (click)="confirmCancel()">Yes</button>
                          </div>
                        </div>
                      </ng-container>
                    <div class="col-marketunit col-common col-font-common">
                        <div class="text-overflow" placement="bottom-right" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="marketUnit">
                            {{assessment.marketUnit}}
                        </div>
                        <ng-template #marketUnit>
                            <div class="tip-inner-content">
                                {{assessment.marketUnit}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-countryserved col-common col-font-common">
                        <div class="text-overflow" placement="bottom-right" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="countryServed">
                            {{assessment.countryServed}}
                        </div>
                        <ng-template #countryServed>
                            <div class="tip-inner-content">
                                {{assessment.countryServed}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-category col-common col-font-common">
                        <div class="text-overflow" placement="bottom-right" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="category">
                            {{assessment.category}}
                        </div>
                        <ng-template #category>
                            <div class="tip-inner-content">
                                {{assessment.category}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-category col-common col-font-common">
                        <div class="text-overflow" placement="bottom-right" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="subCategory">
                            {{assessment.subCategory}}
                        </div>
                        <ng-template #subCategory>
                            <div class="tip-inner-content">
                                {{assessment.subCategory}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-date col-common col-font-common">
                        <div class="text-overflow" placement="bottom-right" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="submissionDate">
                            {{assessment.completionDate | date:'dd/MM/yyyy'}}
                        </div>
                        <ng-template #submissionDate>
                            <div class="tip-inner-content">
                                {{assessment.completionDate}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-description col-common col-font-common">
                        <div class="text-overflow" placement="bottom-right" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="shortDesc">
                            {{assessment.shortDesc}}
                        </div>
                        <ng-template #shortDesc>
                            <div class="tip-inner-content">
                                {{assessment.shortDesc}}
                            </div>
                        </ng-template>
                    </div>

                    <div class="col-irq-id col-common col-font-common">
                        <div class="text-overflow" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="irqID">
                            {{assessment.raId}}
                        </div>
                        <ng-template #irqID>
                            <div class="tip-inner-content">
                                {{assessment.raId}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-category col-common col-font-common">
                        <div class="text-overflow" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="bibdaStatus">
                            {{assessment.bibdaStatus}}
                        </div>
                        <ng-template #bibdaStatus>
                            <div class="tip-inner-content">
                                {{assessment.bibdaStatus}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-relnumber col-common col-font-common">
                        <div class="text-overflow" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="relationshipNumber">
                            {{assessment.relationshipnumber === null ? 'Pending Review' : assessment.relationshipnumber}}
                        </div>
                        <ng-template #relationshipNumber>
                            <div class="tip-inner-content">
                                {{assessment.relationshipnumber === null ? 'Pending Review' : assessment.relationshipnumber}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-isriskscore col-common col-font-common">
                        <div class="text-overflow" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="infoSecRiskScore">
                            {{assessment.infosecrisk === null ? 'Pending Review' : assessment.infosecrisk}}
                        </div>
                        <ng-template #infoSecRiskScore>
                            <div class="tip-inner-content">
                                {{assessment.infosecrisk === null ? 'Pending Review' : assessment.infosecrisk}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-datariskscore col-common col-font-common">
                        <div class="text-overflow" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="dataPrivacyRiskScore">
                            {{assessment.dataprivacyrisk === null ? 'Pending Review' : assessment.dataprivacyrisk}}
                        </div>
                        <ng-template #dataPrivacyRiskScore>
                            <div class="tip-inner-content">
                                {{assessment.dataprivacyrisk === null ? 'Pending Review' : assessment.dataprivacyrisk}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-requestor col-common col-font-common">
                        <div class="text-overflow" placement="bottom-right" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="requestorName">
                            {{assessment.requestorName}}
                        </div>
                        <ng-template #requestorName>
                            <div class="tip-inner-content">
                                {{assessment.requestorName}}
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-4"></div>
</div>
<div style="width: 100%;height: 100%;position:absolute;top: 0;background-color: gray;opacity: 0.5;z-index: 1000;"
    *ngIf="loading">
    <div class="loadEffect" style="position: fixed;left: 45%;top:30%;z-index: 1000;">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>