import { Injectable } from "@angular/core";
import { ComponentConfig, HttpService } from "@lsl16/sustainability-shared-components";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class MetadataService {
    metaDataUrl: string;
    metaData: any;
    gettingAddSupplierConfigUrl: string;
    gettingAddGeographyServedConfigUrl: string;
    gettingProfileDetailsConfigUrl: string;
    gettingCreateNewUserConfigUrl: string;
    gettingDataDumpFilterConfigUrl: string;
    gettingInviteNewSupplierConfigUrl: string;
    gettingAddSubSupplierConfigUrl:string;
    gettingInviteNewParentConfigUrl: string;
    getSelectCountryServedConfigUrl: string;
    getRiskAssessmentConfigUrl: string;
    getInherentRiskQuestionnaireConfigUrl: string;
    getOnboardingConfigUrl: string;
    getBibdaScoringConfigUrl: string;

    constructor(private httpService: HttpService) {
        this.metaDataUrl = `${environment.tsmBackendServiceURL}/meta-data`;
        this.gettingAddSupplierConfigUrl = `${this.metaDataUrl}/search?category=gettingAddSupplierConfig`;
        this.gettingAddGeographyServedConfigUrl = `${this.metaDataUrl}/search?category=gettingAddGeographyServedConfig`;
        this.gettingProfileDetailsConfigUrl = `${this.metaDataUrl}/search?category=gettingProfileDetailsConfig`;
        this.gettingCreateNewUserConfigUrl = `${this.metaDataUrl}/search?category=gettingCreateNewUserConfig`;
        this.gettingDataDumpFilterConfigUrl = `${this.metaDataUrl}/search?category=gettingDataDumpFilterConfig`;
        this.gettingInviteNewSupplierConfigUrl = `${this.metaDataUrl}/search?category=gettingInviteNewSupplierConfig`;
        this.gettingAddSubSupplierConfigUrl=`${this.metaDataUrl}/search?category=gettingAddSubSupplierConfig`;
        this.gettingInviteNewParentConfigUrl = `${this.metaDataUrl}/search?category=gettingInviteNewParentConfig`;
        this.getSelectCountryServedConfigUrl = `${this.metaDataUrl}/search?category=getSelectCountryServedConfig`;
        this.getRiskAssessmentConfigUrl = `${this.metaDataUrl}/search?category=getRiskAssessmentConfig`;
        this.getInherentRiskQuestionnaireConfigUrl = `${this.metaDataUrl}/search?category=getInherentRiskAssessmentConfig`;
        this.getOnboardingConfigUrl = `${this.metaDataUrl}/search?category=getOnboardingConfig`;
        this.getBibdaScoringConfigUrl = `${this.metaDataUrl}/search?category=bibdaScoringConfig`;
    
      }

    async gettingAddSupplierConfig(): Promise<Array<ComponentConfig>> {
      return await this.httpService.GetPromise(this.gettingAddSupplierConfigUrl);
    }

    async gettingAddGeographyServedConfig(): Promise<Array<ComponentConfig>> {
      return await this.httpService.GetPromise(this.gettingAddGeographyServedConfigUrl);
    }

    async gettingProfileDetailsConfig(): Promise<Array<ComponentConfig>> {
      return await this.httpService.GetPromise(this.gettingProfileDetailsConfigUrl);
    }

    async gettingCreateNewUserConfig(): Promise<Array<ComponentConfig>> {
      return await this.httpService.GetPromise(this.gettingCreateNewUserConfigUrl);
    }

    async gettingDataDumpFilterConfig(): Promise<Array<ComponentConfig>> {
      return await this.httpService.GetPromise(this.gettingDataDumpFilterConfigUrl);
    }

    async gettingInviteNewSupplierConfig () : Promise<Array<ComponentConfig>> {
      return await this.httpService.GetPromise(this.gettingInviteNewSupplierConfigUrl);
    }

    async gettingAddSubSupplierConfig() : Promise<Array<ComponentConfig>> {
      return await this.httpService.GetPromise(this.gettingAddSubSupplierConfigUrl);
    }

    async getSelectCountryServedConfig(): Promise<Array<ComponentConfig>> {
      return await this.httpService.GetPromise(this.getSelectCountryServedConfigUrl);
    }

    async getRiskAssessmentConfig(): Promise<Array<ComponentConfig>> {
      return await this.httpService.GetPromise(this.getRiskAssessmentConfigUrl);
    }

    async getInherentRiskAssessmentConfig(): Promise<Array<ComponentConfig>> {
      return await this.httpService.GetPromise(this.getInherentRiskQuestionnaireConfigUrl);
    }

    async getOnboardingConfig(): Promise<Array<ComponentConfig>> {
      return await this.httpService.GetPromise(this.getOnboardingConfigUrl);
    }
    
    async bibdaScoringConfig(): Promise<Array<ComponentConfig>> {
      return await this.httpService.GetPromise(this.getBibdaScoringConfigUrl);
    }
}
