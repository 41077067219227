import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { DropdownItem } from 'src/app/components/single-dropdown/single-dropdown.component';

@Component({
  selector: 'tsm-compliance-filter',
  templateUrl: './compliance-filter.component.html',
  styleUrls: ['./compliance-filter.component.sass']
})
export class ComplianceFilterComponent implements OnInit {
  @Output() valueChanged = new EventEmitter<boolean>();
  @Input() clearFilter: Subject<boolean>;
  @Output() dropdownError = new EventEmitter<boolean>();

  picklistValues: DropdownItem[] = [{
    internalCode: "yes",
    displayText: "Yes"
  },
  {
    internalCode: "no",
    displayText: "No"
  }]

  labelTranslations = {
    controlLabel: "Compliant",
    inputPlaceholder: "Select compliant state",
    picklistValues: {
      yes: "Yes",
      no: "No"
    }

  }

  constructor() { }

  ngOnInit(): void {
  }

  dropdownChanged(selectedValue: DropdownItem) {
    const complianceFilterEnabled = selectedValue.internalCode === 'yes';
    this.valueChanged.emit(complianceFilterEnabled);
  }
  onDropdownError(hasError: boolean) {
    this.dropdownError.emit(hasError);
  }
}
